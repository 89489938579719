import { Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../state/index';
import { resetState } from '../../state/admin/actions';
import {
  AgentData,
  AgentStatuses,
  AgentVerificationState,
  IFilterFormSubmission,
  NonprofitStatus
} from '../../state/agentVerification';
import {
  agentFilterRequest,
  resetSearchResultData,
  setAgentVerificationId
} from '../../state/agentVerification/actions';
import { IAuthState } from '../../state/auth';
import { IAdditionalData } from '../../state/nonprofit';
import AgentSummary from '../../components/agentVerification/AgentSummary';
import Filter from '../../components/agentVerification/Filter';
import * as agentVerificationActions from '../../state/agentVerification/actions';
import { setAdditionalNonprofitInfo, setNonProfitId } from '../../state/nonprofit/actions';

interface IPropsFromDispatch {
  agentFilterRequest: typeof agentVerificationActions.agentFilterRequest;
  setNonProfitId: typeof setNonProfitId;
  setAdditionalNonprofitInfo: typeof setAdditionalNonprofitInfo;
  setAgentVerificationId: typeof setAgentVerificationId;
  resetState?: typeof resetState;
  resetSearchResultData?: typeof resetSearchResultData;
}

export interface IAgentVerificationStateProps {
  agentVerificationResults: AgentVerificationState;
  isLoading: boolean;
  authDetails: IAuthState;
  verifyAgentSuccess?: boolean;
}
export type AgentVerificationProps = IPropsFromDispatch & IAgentVerificationStateProps & RouteComponentProps;

class AgentVerificationPage extends React.Component<AgentVerificationProps> {
  public componentDidMount() {
    const filterRequest = {
      AgentStatuses: [AgentStatuses.Outreach, AgentStatuses.Pending],
      Countries: [],
      NonprofitName: '',
      NonprofitStatuses: [NonprofitStatus.Approved]
    } as IFilterFormSubmission;
    this.props.agentFilterRequest(filterRequest);
  }
  public render() {
    const { agentVerificationResults, isLoading, authDetails } = this.props;

    return (
      <section id="agent-verification-page">
        <Filter />
        {authDetails.authorizationStatus && isLoading ? (
          <Spinner id="agentVerificationSpinner" size={SpinnerSize.large} label="Loading..." ariaLive="assertive" />
        ) : authDetails.authorizationStatus && !isLoading ? (
          <section id="agentVerificationSummaryDiv">
            <AgentSummary
              agentVerificationResults={agentVerificationResults}
              setNonProfitId={this.props.setNonProfitId}
              setAdditionalNonprofitInfo={this.props.setAdditionalNonprofitInfo}
              setAgentVerificationId={this.props.setAgentVerificationId}
              resetState={this.props.resetState}
              resetSearchResultData={this.props.resetSearchResultData}
              verifyAgentSuccess={this.props.verifyAgentSuccess}
            />
          </section>
        ) : (
          <p></p>
        )}
      </section>
    );
  }
}

const mapStateToProps = ({ auth, agentVerification, admin }: IApplicationState): IAgentVerificationStateProps => ({
  authDetails: auth,
  isLoading: agentVerification.isLoading ?? true,
  agentVerificationResults: agentVerification,
  verifyAgentSuccess: admin.verifyAgentNonprofitState
    ? admin.verifyAgentNonprofitState.setVerifyAgentSuccess
    : undefined
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  agentFilterRequest: (val: IFilterFormSubmission) => dispatch(agentFilterRequest(val)),
  setNonProfitId: (val: string) => dispatch(setNonProfitId(val)),
  setAdditionalNonprofitInfo: (val: IAdditionalData) => dispatch(setAdditionalNonprofitInfo(val)),
  setAgentVerificationId: (val: AgentData) => dispatch(setAgentVerificationId(val)),
  resetState: () => dispatch(resetState()),
  resetSearchResultData: () => dispatch(resetSearchResultData())
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentVerificationPage);
