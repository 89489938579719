import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setNonProfitId } from '../../state/nonprofit/actions';
import { ISearchResult } from '../../state/search';
import { setCurrentNonprofit } from '../../state/search/actions';
import StatusIcon from '../util/StatusIcon';
import './SearchListItem.scss';
import SearchResult from './SearchResult';

interface ISearchProps {
  item: any;
}

interface IPropsFromDispatch {
  setNonProfitId: (val: string) => void;
  updateCurrentNonprofit: (val: ISearchResult) => void;
}

export type SearchListProps = IPropsFromDispatch & ISearchProps;

const SearchListItem: React.FunctionComponent<SearchListProps> = ({ item, updateCurrentNonprofit }) => (
  <section id="searchList-div">
    <div className="ms-Grid-row">
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
        <div className="tile-content">
          <div className="detailsSectionHeader">
            <section id="status">
              {<StatusIcon status={item.status} />}
              <span>&nbsp;</span>
              <Link
                to={`/search/nonprofit/${item.nonprofitId}`}
                onClick={() => {
                  return updateCurrentNonprofit(item);
                }}
                className="boldText"
              >
                {item.nonprofitName || item.techSoupName}{' '}
              </Link>
            </section>
          </div>
          <SearchResult item={item} />
        </div>
      </div>
    </div>
  </section>
);

const mapStateToDispatch = (dispatch: Dispatch) => ({
  setNonProfitId: (val: string) => dispatch(setNonProfitId(val)),
  updateCurrentNonprofit: (val: ISearchResult) => dispatch(setCurrentNonprofit(val))
});

export default connect(null, mapStateToDispatch)(SearchListItem);
