import { OutReachData } from '../agentVerification';

export const enum AdminActionTypes {
  SET_FRAUD_NONPROFIT_REQUEST = '@@admin/SET_FRAUD_NONPROFIT_REQUEST',
  SET_BULK_FRAUD_REQUEST = '@@admin/SET_BULK_FRAUD_REQUEST',
  SET_FRAUD_NONPROFIT_SUCCESS = '@@admin/SET_FRAUD_NONPROFIT_SUCCESS',
  FETCH_FRAUD_NONPROFIT_ERROR = '@@admin/FETCH_FRAUD_NONPROFIT_ERROR',
  SET_FAILED_VALUE_TO_STATE = '@@admin/SET_FAILED_VALUE_TO_STATE',
  SET_SUCCESS_VALUE_TO_STATE = '@@admin/SET_SUCCESS_VALUE_TO_STATE',
  RESET_STATE = '@@admin/RESET_STATE',

  SET_FRAUD_REASONCODE_REQUEST = '@@admin/SET_FRAUD_REASONCODE_REQUEST',
  SET_FRAUD_REASONCODE_SUCCESS = '@@admin/SET_FRAUD_REASONCODE_SUCCESS',
  FETCH_FRAUD_REASONCODE_ERROR = '@@admin/FETCH_FRAUD_REASONCODE_ERROR',

  LINK_COMMERCIAL_REQUEST = '@@admin/LINK_COMMERCIAL_REQUEST',
  LINK_COMMERCIAL_SUCCESS = '@@admin/LINK_COMMERCIAL_SUCCESS',
  FETCH_LINK_COMMERCIAL_ERROR = '@@admin/FETCH_LINK_COMMERCIAL_ERROR',

  OUTREACH_REQUEST = '@@admin/OUTREACH_REQUEST',
  OUTREACH_SUCCESS = '@@admin/OUTREACH_SUCCESS',
  FETCH_OUTREACH_ERROR = '@@admin/FETCH_OUTREACH_ERROR',

  REPROCESS_REQUEST = '@@admin/REPROCESS_REQUEST',
  REPROCESS_SUCCESS = '@@admin/REPROCESS_SUCCESS',
  FETCH_REPROCESS_ERROR = '@@admin/FETCH_REPROCESS_ERROR',

  VERIFY_AGENT_REQUEST = '@@admin/VERIFY_AGENT_REQUEST',
  VERIFY_AGENT_SUCCESS = '@@admin/VERIFY_AGENT_SUCCESS',
  FETCH_VERIFY_AGENT_ERROR = '@@admin/FETCH_VERIFY_AGENT_ERROR',

  AZURE_REGISTRATION_LINK_REQUEST = '@@admin/AZURE_REGISTRATION_LINK_REQUEST',
  AZURE_REGISTRATION_LINK_SUCCESS = '@@admin/AZURE_REGISTRATION_LINK_SUCCESS',
  AZURE_REGISTRATION_LINK_ERROR = '@@admin/AZURE_REGISTRATION_LINK_ERROR',

  ADD_COMMENTS_REQUEST = '@@admin/ADD_COMMENTS_REQUEST',
  ADD_COMMENTS_SUCCESS = '@@admin/ADD_COMMENTS_SUCCESS',
  ADD_COMMENTS_ERROR = '@@admin/ADD_COMMENTS_ERROR',

  SEND_BULK_REQUEST = '@@admin/SEND_BULK_REQUEST',
  SEND_BULK_SUCCESS = '@@admin/SEND_BULK_SUCCESS',
  SEND_BULK_ERROR = '@@admin/SEND_BULK_ERROR',

  GET_DOWNLOADABLE_URL_REQUEST = '@@admin/GET_DOWNLOADABLE_URL_REQUEST'
}

export interface IAdminState {
  setFraudTransactionLoader?: boolean;
  fraudByNonprofit?: IFraudNonprofit;
  setFraudNonprofitLoader?: boolean;
  VerifyAgentLoading: boolean;
  verifyAgentNonprofitState: IVerifyAgentState | undefined;
  commercialState: ICommercialState | undefined;
  commercialLoading: boolean;
  reprocessLoading: boolean;
  reprocessState: IReprocessState | undefined;
  bulkReprocessRequestInProgress: boolean;
  bulkReprocessState: IBulkReprocessState | undefined;
  outreachNonprofitState: IOutreachState | undefined;
  outreachLoading: boolean;
  azureRegistrationLinkingState: IAzureRegistrationLinkingState | undefined;
  failedValues: string[] | undefined;
  successValues: string[] | undefined;
  addCommentsInProgress: boolean;
  addCommentsState: IAddCommentsState | undefined;
}

export interface IFraudNonprofit {
  setNonprofitFraudSuccess?: boolean;
  failureMessage: string | undefined;
}

export interface IVerifyAgentState {
  setVerifyAgentSuccess: boolean;
  failureMessage: string | undefined;
}

export interface ICommercialState {
  isCommmercialLinkingSuccess: boolean;
  failureMessage: string;
}

export interface ICommercialFormSubmission {
  nonprofitId: string;
  tenantId: string;
  domain: string;
}

export interface ICommercialTenant {
  tenantId: string;
  domain: string;
}

export interface IReprocessState {
  isReprocessSuccessful: boolean;
  failureMessage: string;
  reprocessAction: string;
  reprocessActionDescription: string;
}

export interface IBulkReprocessState {
  isReprocessSuccessful: boolean;
  failureMessage: string | null;
  response: IBulkReprocessResponse | null;
}

export interface IAzureRegistrationLinkingState {
  isLinkingInProgress: boolean;
  result: boolean | undefined;
  failureMessage: string | undefined;
}

export interface IAzureRegistrationLinkingFormSubmission {
  nonprofitId: string;
  registrationId: string;
  comments: string;
}

export interface ISetFraudFormSubmission {
  nonprofitId: string;
  reasonCode: string;
  comments: string;
}

export interface IReprocessFormSubmission {
  nonprofitId: string;
  comments: string;
}

export interface IOutreachState {
  isOutreachSuccessful: boolean;
  failureMessage: string | undefined;
  outReachData: OutReachData | undefined;
  agentVerificationId: string | undefined;
}

export interface IOutreachFormSubmission {
  agentVerificationId: string;
  nonprofitId: string;
  processName: string;
  comments: string;
}

export interface IVerifyAgentFormSubmission {
  agentVerificationId: string;
  nonprofitId: string;
  processName: string;
  comments: string;
}

export interface ICommentsFormSubmission {
  nonprofitId: string;
  comments: string;
}

export interface IAddCommentsState {
  addCommentsSuccess: boolean;
  failureMessage: string | undefined;
}

export interface IReprocessPayload {
  nonprofitIds: string[];
}

export interface IBulkReprocessResponse {
  batchId: string;
  successfulInitiationCount: number;
  failedNonprofitIds: object;
}
export interface IReprocessResponse {
  reprocessAction: string;
  reprocessActionDescription: string;
}
