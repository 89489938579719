import { all, apply, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { IApiResponse } from '../../common/model/ApiResponse';
import searchService from '../../dataServices/SearchService';
import { fetchError, searchRequest, searchSuccess } from './actions';
import { ISearchResult, SearchActionTypes } from './types';

export function* handleSearchRequest(action: ActionType<typeof searchRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<ISearchResult[]> = yield apply(searchService, searchService.getSearchResults, [
      formValues
    ]);
    yield put(searchSuccess(response.value));
  } catch (err: any) {
    yield put(fetchError(err.toString()));
  }
}
function* watchSearchRequest() {
  yield takeEvery(SearchActionTypes.SEARCH_REQUEST, handleSearchRequest);
}

function* searchSaga() {
  yield all([fork(watchSearchRequest)]);
}

export default searchSaga;
