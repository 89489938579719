import * as React from 'react';
import { Route } from 'react-router-dom';
import {
  AdminAuthorized,
  AuthRequiredBoundary,
  SearchAuthorized,
  UnauthRequiredBoundary
} from '../../components/boundaries/Boundary';
import AdminSearchPage from '../../pages/admin/search/SearchPage';
import AgentVerificationPage from '../../pages/agentVerification/AgentVerificationPage';
import { ErrorPage } from '../../pages/error/ErrorPage';
import Details from '../../pages/nonprofitDetails/Details';
import SearchPage from '../../pages/search/SearchPage';
import SignInPage from '../../pages/signin/SignInPage';
import WhoPage from '../../pages/signin/Who';

const Routes: React.FunctionComponent = () => (
  <>
    <Route
      exact
      path="/"
      render={(props) => (
        <AuthRequiredBoundary redirect="/signin">
          <SearchAuthorized redirect="/error">
            <SearchPage {...props} />
          </SearchAuthorized>
        </AuthRequiredBoundary>
      )}
    />

    <Route
      exact
      path="/search"
      render={(props) => (
        <AuthRequiredBoundary redirect="/signin">
          <SearchAuthorized redirect="/error">
            <SearchPage {...props} />
          </SearchAuthorized>
        </AuthRequiredBoundary>
      )}
    />

    <Route
      exact
      path="/agentverification"
      render={(props) => (
        <AuthRequiredBoundary redirect="/signin">
          <SearchAuthorized redirect="/error">
            <AgentVerificationPage {...props} />
          </SearchAuthorized>
        </AuthRequiredBoundary>
      )}
    />

    <Route
      exact
      path="/search/query=:queryval"
      render={(props) => (
        <AuthRequiredBoundary redirect="/signin">
          <SearchAuthorized redirect="/error">
            <SearchPage {...props} />
          </SearchAuthorized>
        </AuthRequiredBoundary>
      )}
    />

    <Route
      exact
      path="/signin"
      render={() => (
        <UnauthRequiredBoundary redirect="/">
          <SignInPage />
        </UnauthRequiredBoundary>
      )}
    />

    <Route
      exact
      path="/search/nonprofit/:nonprofitId"
      render={(props) => (
        <AuthRequiredBoundary redirect="/signin">
          <SearchAuthorized redirect="/error">
            <Details {...props} />
          </SearchAuthorized>
        </AuthRequiredBoundary>
      )}
    />

    <Route
      exact
      path="/who"
      render={() => (
        <AuthRequiredBoundary redirect="/signin">
          <WhoPage />
        </AuthRequiredBoundary>
      )}
    />

    <Route
      exact
      path="/admin/search"
      render={(props) => (
        <AuthRequiredBoundary redirect="/signin">
          <AdminAuthorized redirect="/error">
            <AdminSearchPage {...props} />
          </AdminAuthorized>
        </AuthRequiredBoundary>
      )}
    />

    <Route
      exact
      path="/admin/search/query=:queryval"
      render={(props) => (
        <AuthRequiredBoundary redirect="/signin">
          <AdminAuthorized redirect="/error">
            <AdminSearchPage {...props} />
          </AdminAuthorized>
        </AuthRequiredBoundary>
      )}
    />

    <Route exact path="/error" render={() => <ErrorPage />} />
  </>
);

export default Routes;
