export const enum AgentVerificationActionTypes {
  AGENTVERIFICATION_REQUEST = '@@agentVerification/AGENTVERIFICATION_REQUEST',
  AGENTVERIFICATION_SUCCESS = '@@agentVerification/AGENTVERIFICATION_SUCCESS',
  AGENTVERIFICATION_ERROR = '@@agentVerification/AGENTVERIFICATION_ERROR',
  SET_AGENTVERIFICATION_INFO = '@@agentVerification/SET_AGENTVERIFICATION_INFO',
  SET_OUTREACH_STATUS = '@@agentVerification/SET_OUTREACH_STATUS',
  SET_VERIFYAGENT_STATUS = '@@agentVerification/SET_VERIFYAGENT_STATUS',
  RESET_SEARCH_RESULT_DATA = '@@agentVerification/RESET_SEARCH_RESULT_DATA',
  RESET_AGENT_DATA = '@@agentVerification/RESET_AGENT_DATA',
  AGENTFILTER_REQUEST = '@@agentVerification/AGENTFILTER_REQUEST',
  AGENTFILTER_SUCCESS = '@@agentVerification/AGENTFILTER_SUCCESS',
  FETCH_AGENTFILTER_ERROR = '@@agentVerification/FETCH_AGENTFILTER_ERROR'
}

export interface AgentVerificationState {
  results: AgentVerificationResponse[] | undefined;
  currentNonProfit: AgentVerificationResponse | undefined;
  isLoading?: boolean;
  agentVerificationInfo: AgentData | undefined;
  newSearchResultsDataAdded: boolean;
}

export interface AgentVerificationResponse {
  nonprofitData: NonProfitData;
  agentData: AgentData;
  outreachData: OutReachData[];
}

export interface NonProfitData {
  nonprofitId: string;
  nonprofitName: string;
  website: string;
  status: string;
  address: string;
  city: string;
  country: string;
  effectiveDate: string;
}

export interface AgentData {
  agentVerificationId: string;
  agentEmail: string;
  agentStatus: string;
}

export interface OutReachData {
  outreachUrl: string;
  outreachId: string;
}

export const enum AgentStatuses {
  Pending = 'Pending',
  Outreach = 'Outreach',
  Approved = 'Approved',
  Fraud = 'Fraud'
}
export interface IFilterFormSubmission {
  AgentStatuses: string[];
  Countries: string[];
  NonprofitName: string;
  NonprofitStatuses: string[];
}

export const enum NonprofitStatus {
  InProgress = 'InProgress',
  Approved = 'Approved',
  Declined = 'Declined',
  Closed = 'Closed',
  Fraud = 'Fraud',
  Unknown = 'Unknown',
  InActive = 'InActive',
  Deleted = 'Deleted'
}
