import { logService } from 'npo-common';

export function loadFromSessionStorage<T>(key: string): T | undefined {
  try {
    const serialized = sessionStorage.getItem(key);
    if (serialized === null) {
      return undefined;
    }
    return JSON.parse(serialized) as T;
  } catch (err: any) {
    logService.logError('Failed to load data from session storage', err, { console: true });
    return undefined;
  }
}

export function saveToSessionStorage<T>(key: string, state: T): void {
  try {
    const serialized = JSON.stringify(state);
    sessionStorage.setItem(key, serialized);
  } catch (err: any) {
    logService.logError('Failed to save to session storage', err, { console: true });
  }
}

export function getSessionId(): string {
  const sessionID = loadFromSessionStorage<string>('sessionId');
  return sessionID ? sessionID : '';
}
