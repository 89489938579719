import { Reducer } from 'redux';
import { INonprofitState, NonprofitActionTypes } from './types';

// Type-safe initialState!
const initialState: INonprofitState = {
  additionalNonprofitInfo: undefined,
  historyResults: undefined,
  agentErrorResults: undefined,
  isHistoryResultsLoading: undefined,
  isNonprofitDetailLoading: undefined,
  isNonprofitSearchResultsLoading: undefined,
  isAgentErrorLoading: false,
  nonprofitDetail: undefined,
  nonprofitId: '',
  nonprofitSearchResults: undefined
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<INonprofitState> = (state = initialState, action) => {
  switch (action.type) {
    case NonprofitActionTypes.HISTORY_REQUEST: {
      return { ...state, isHistoryResultsLoading: true };
    }
    case NonprofitActionTypes.HISTORY_SUCCESS: {
      return { ...state, historyResults: action.payload, isHistoryResultsLoading: false };
    }
    case NonprofitActionTypes.AGENT_ERROR_SUCCESS: {
      return { ...state, agentErrorResults: action.payload, isAgentErrorLoading: false };
    }
    case NonprofitActionTypes.NONPROFIT_REQUEST: {
      return { ...state, isNonprofitDetailLoading: true };
    }
    case NonprofitActionTypes.NONPROFIT_SUCCESS: {
      return { ...state, nonprofitDetail: action.payload, isNonprofitDetailLoading: false };
    }
    case NonprofitActionTypes.NONPROFITRESULTS_REQUEST: {
      return { ...state, isNonprofitSearchResultsLoading: true };
    }
    case NonprofitActionTypes.NONPROFITRESULTS_SUCCESS: {
      return { ...state, nonprofitSearchResults: action.payload, isNonprofitSearchResultsLoading: false };
    }
    case NonprofitActionTypes.SET_NONPROFITID: {
      return { ...state, nonprofitId: action.payload };
    }
    case NonprofitActionTypes.SET_ADDITIONALNONPROFITINFO: {
      return { ...state, additionalNonprofitInfo: action.payload };
    }
    case NonprofitActionTypes.FETCH_ERROR: {
      return { ...state, historyResults: undefined, isHistoryResultsLoading: false };
    }
    case NonprofitActionTypes.FETCH_NONPROFIT_ERROR: {
      return { ...state, nonprofitDetail: undefined, isNonprofitDetailLoading: false };
    }
    case NonprofitActionTypes.FETCH_NONPROFITSUMMARY_ERROR: {
      return { ...state, nonprofitSearchResults: undefined, isNonprofitSearchResultsLoading: false };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.ts`
export { reducer as nonprofitReducer };
