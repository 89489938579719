import { Dropdown, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import localeService from '../../dataServices/LocaleService';
import { IApplicationState } from '../../state/index';
import {
  agentFilterRequest,
  AgentStatuses,
  IFilterFormSubmission,
  NonprofitStatus
} from '../../state/agentVerification';
import { CountryData } from '../../state/shared';
import './Filter.scss';

interface IPropsFromDispatch {
  agentFilterRequest: typeof agentFilterRequest;
}

interface IPropsFromState {
  countryDataList: CountryData[];
}

let nonprofitName = '';
let selectedStatusOptions: string[] = [];
let selectedCountryOptions: string[] = [];
export const Filter: React.FunctionComponent<IPropsFromState & IPropsFromDispatch> = (props) => {
  const getAgentStatuses = () => {
    const localeContent = localeService.getLocaleContent();
    const agentStatuses = localeContent.AgentStatuses;
    return agentStatuses;
  };
  const setFilter = () => {
    const formvalues = {
      AgentStatuses: selectedStatusOptions,
      Countries: selectedCountryOptions,
      NonprofitName: nonprofitName,
      NonprofitStatuses: [NonprofitStatus.Approved]
    } as IFilterFormSubmission;
    if (selectedStatusOptions.length === 0) {
      formvalues.AgentStatuses = [AgentStatuses.Outreach, AgentStatuses.Pending];
    }
    props.agentFilterRequest(formvalues);
  };
  const onDropdownSatusChange = async (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption<any> | undefined
  ): Promise<void> => {
    if (item === undefined) {
      return;
    }

    if (item.selected) {
      await selectedStatusOptions.push(item.key as string);
    } else {
      (await selectedStatusOptions.indexOf(item.key as string)) !== -1 &&
        selectedStatusOptions.splice(selectedStatusOptions.indexOf(item.key as string), 1);
    }
  };
  const onDropdownCountryChange = async (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption<any> | undefined
  ): Promise<void> => {
    if (item === undefined) {
      return;
    }

    if (item.selected) {
      await selectedCountryOptions.push(item.key as string);
    } else {
      (await selectedCountryOptions.indexOf(item.key as string)) !== -1 &&
        selectedCountryOptions.splice(selectedCountryOptions.indexOf(item.key as string), 1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    nonprofitName = event.target.value;
  };
  const filter = (
    <div className="ms-Grid-row filterRow">
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 ms-xl-2">
        <TextField label="Filter by name:" className="controlStyles" onBlur={handleChange} />
      </div>
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 ms-xl-2">
        <Dropdown
          label="Filter by Agent Status"
          defaultValue=""
          id="agentStatus"
          multiSelect={true}
          options={getAgentStatuses()}
          className="controlStyles"
          onChange={onDropdownSatusChange}
        />
      </div>
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 ms-xl-2">
        <Dropdown
          label="Filter by Country"
          defaultValue=""
          id="country"
          multiSelect={true}
          options={props.countryDataList}
          className="controlStyles"
          onChange={onDropdownCountryChange}
        />
      </div>
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 ms-xl-2">
        <PrimaryButton secondaryText="Search" text="Search" onClick={setFilter} style={{ marginTop: '30px' }} />
      </div>
    </div>
  );
  return filter;
};

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
  agentFilterRequest: (value: IFilterFormSubmission) => dispatch(agentFilterRequest(value))
});
const mapStateToProps = ({ sharedState }: IApplicationState): IPropsFromState => ({
  countryDataList: sharedState.countryDataList ?? []
});
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
