import { action } from "typesafe-actions";
import {
  AdminActionTypes,
  IAzureRegistrationLinkingFormSubmission,
  IBulkReprocessResponse,
  ICommentsFormSubmission,
  ICommercialFormSubmission,
  ICommercialState,
  IFraudNonprofit,
  IOutreachFormSubmission,
  IReprocessFormSubmission,
  IReprocessPayload,
  IReprocessState,
  ISetFraudFormSubmission,
  IVerifyAgentFormSubmission
} from "./types";

export const setFraudNonprofitRequest = (values: string) =>
  action(AdminActionTypes.SET_FRAUD_NONPROFIT_REQUEST, values);
export const setBulkFraudRequest = (values: string[]) => action(AdminActionTypes.SET_BULK_FRAUD_REQUEST, values);
export const setFraudNonprofitSuccess = (data: IFraudNonprofit) =>
  action(AdminActionTypes.SET_FRAUD_NONPROFIT_SUCCESS, data);
export const fetchFraudNonprofitError = (data: IFraudNonprofit) =>
  action(AdminActionTypes.FETCH_FRAUD_NONPROFIT_ERROR, data);
export const setFailedValuesToState = (values: string[]) => action(AdminActionTypes.SET_FAILED_VALUE_TO_STATE, values);
export const setSuccessValuesToState = (values: string[]) =>
  action(AdminActionTypes.SET_SUCCESS_VALUE_TO_STATE, values);

export const setFraudReasonCodeRequest = (values: ISetFraudFormSubmission) =>
  action(AdminActionTypes.SET_FRAUD_REASONCODE_REQUEST, values);
export const setFraudReasonCodeSuccess = (data: IFraudNonprofit) =>
  action(AdminActionTypes.SET_FRAUD_REASONCODE_SUCCESS, data);
export const fetchFraudReasonCodeError = (data: IFraudNonprofit) =>
  action(AdminActionTypes.FETCH_FRAUD_REASONCODE_ERROR, data);

export const resetState = () => action(AdminActionTypes.RESET_STATE);

export const linkCommercialRequest = (values: ICommercialFormSubmission) =>
  action(AdminActionTypes.LINK_COMMERCIAL_REQUEST, values);
export const linkCommercialSuccess = (value: ICommercialState) =>
  action(AdminActionTypes.LINK_COMMERCIAL_SUCCESS, value);
export const fetchLinkCommercialError = (message: string) =>
  action(AdminActionTypes.FETCH_LINK_COMMERCIAL_ERROR, message);

export const verifyAgentRequest = (values: IVerifyAgentFormSubmission) =>
  action(AdminActionTypes.VERIFY_AGENT_REQUEST, values);
export const verifyAgentSuccess = () =>
  action(AdminActionTypes.VERIFY_AGENT_SUCCESS);
export const fetchverifyAgentError = (message: string) =>
  action(AdminActionTypes.FETCH_VERIFY_AGENT_ERROR, message);

export const outreachRequest = (values: IOutreachFormSubmission) =>
  action(AdminActionTypes.OUTREACH_REQUEST, values);
export const outreachSuccess = () =>
  action(AdminActionTypes.OUTREACH_SUCCESS);
export const fetchoutreachError = (message: string) =>
  action(AdminActionTypes.FETCH_OUTREACH_ERROR, message);

export const reprocessRequest = (values: IReprocessFormSubmission) => action(AdminActionTypes.REPROCESS_REQUEST, values);
export const reprocessSuccess = (value: IReprocessState) => action(AdminActionTypes.REPROCESS_SUCCESS, value);
export const fetchReprocessError = (message: string) => action(AdminActionTypes.FETCH_REPROCESS_ERROR, message);

export const azureRegistrationLinkRequest = (values: IAzureRegistrationLinkingFormSubmission) =>
  action(AdminActionTypes.AZURE_REGISTRATION_LINK_REQUEST, values);
export const azureRegistrationLinkSuccess = () => action(AdminActionTypes.AZURE_REGISTRATION_LINK_SUCCESS);
export const azureRegistrationLinkError = (message: string) =>
  action(AdminActionTypes.AZURE_REGISTRATION_LINK_ERROR, message);

export const addCommentsRequest = (values: ICommentsFormSubmission) =>
  action(AdminActionTypes.ADD_COMMENTS_REQUEST, values);
export const addCommentsSuccess = () =>
  action(AdminActionTypes.ADD_COMMENTS_SUCCESS);
export const addCommentsError = (message: string) =>
  action(AdminActionTypes.ADD_COMMENTS_ERROR, message);

export const sendBulkReprocessRequest = (values: IReprocessPayload) =>
  action(AdminActionTypes.SEND_BULK_REQUEST, values);
export const sendBulkReprocessSuccess = (values: IBulkReprocessResponse) =>
  action(AdminActionTypes.SEND_BULK_SUCCESS, values);
export const sendBulkReprocessError = (message: string) =>
  action(AdminActionTypes.SEND_BULK_ERROR, message);

export const getDownloadableUrlRequest = (artifactName: string) =>
  action(AdminActionTypes.GET_DOWNLOADABLE_URL_REQUEST, artifactName);