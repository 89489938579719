import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../state/index';
import { IAdminState, IOutreachFormSubmission, outreachRequest } from '../../../state/admin';
import './Outreach.scss';

interface IPropsFromDispatch {
  outreachRequest: (val: IOutreachFormSubmission) => void;
}
export interface IOutreachProps {
  adminState: IAdminState;
  nonprofitStatus: string;
  nonprofitId: string;
  agentVerificationId: string;
}

export type IOutreachPopupProps = IPropsFromDispatch & IOutreachProps;
export const Outreach: React.FunctionComponent<IOutreachPopupProps> = (props) => {
  const [hideDialog, setHideDialog] = React.useState(true);

  const showDialog = () => setHideDialog(false);
  const closeDialog = () => setHideDialog(true);
  let comments = '';
  const setOutreach = () => {
    const formvalues = {
      agentVerificationId: props.agentVerificationId,
      nonprofitId: props.nonprofitId,
      comments: comments,
      processName: 'Support tool'
    } as IOutreachFormSubmission;
    props.outreachRequest(formvalues);
    closeDialog();
  };

  const cancelAction = () => closeDialog();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    comments = event.target.value;
  };
  const outReachButton = (
    <PrimaryButton id="outreachAgentButton" secondaryText="Agent Outreach" onClick={showDialog} text="Agent Outreach" />
  );
  const dialogBox = (
    <Dialog
      hidden={hideDialog}
      onDismiss={closeDialog}
      dialogContentProps={{
        title: 'Outreach',
        type: DialogType.largeHeader
      }}
      modalProps={{
        containerClassName: 'ms-dialogMainOverride outreachDialog',
        isBlocking: false
      }}
    >
      <DialogContent className="dialogContent">
        <p>Comment(Optional)</p>
        <textarea
          placeholder="Comments"
          onBlur={handleChange}
          maxLength={500}
          style={{ width: '100%', height: '80px', outlineColor: 'rgb(0, 120, 212)' }}
        ></textarea>
      </DialogContent>
      <DialogFooter>
        <PrimaryButton id="outreachAgentSubmit" onClick={setOutreach} text="Submit" />
        <DefaultButton id="outreachAgentCancel" onClick={cancelAction} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );

  const spinner = <Spinner id="searchSpinner" size={SpinnerSize.medium} />;

  const success = (
    <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
      {' '}
      Success!
    </MessageBar>
  );

  const error = (
    <MessageBar
      actions={<div>{outReachButton}</div>}
      messageBarType={MessageBarType.error}
      isMultiline={true}
      dismissButtonAriaLabel="Close"
    >
      {props.adminState.outreachNonprofitState && props.adminState.outreachNonprofitState.failureMessage} Please try
      again.
    </MessageBar>
  );
  const outReach = (
    <div id="outreachPopup">
      {props.adminState.outreachLoading
        ? spinner
        : props.adminState.outreachNonprofitState &&
          !props.adminState.outreachLoading &&
          props.adminState.outreachNonprofitState.isOutreachSuccessful
        ? success
        : props.adminState.outreachNonprofitState &&
          !props.adminState.outreachLoading &&
          !props.adminState.outreachNonprofitState.isOutreachSuccessful
        ? error
        : outReachButton}
      {dialogBox}
    </div>
  );
  return outReach;
};

const mapStateToProps = ({ admin, nonprofit, agentVerification }: IApplicationState) => ({
  adminState: admin,
  nonprofitStatus: nonprofit.additionalNonprofitInfo ? nonprofit.additionalNonprofitInfo.status : '',
  nonprofitId: nonprofit.nonprofitId,
  agentVerificationId: agentVerification.agentVerificationInfo
    ? agentVerification.agentVerificationInfo.agentVerificationId
    : ''
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  outreachRequest: (value: IOutreachFormSubmission) => dispatch(outreachRequest(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Outreach);
