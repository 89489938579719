import { all, apply, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { IApiResponse } from '../../common/model/ApiResponse';
import agentVerificationService from '../../dataServices/AgentVerificationService';
import {
  agentFilterRequest,
  agentFilterSuccess,
  agentVerificationError,
  agentVerificationRequest,
  agentVerificationSuccess,
  fetchagentFilterError
} from './actions';
import { AgentVerificationActionTypes, AgentVerificationResponse } from './types';

export function* handleAgentVerificationRequest(action: ActionType<typeof agentVerificationRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<AgentVerificationResponse[]> = yield apply(
      agentVerificationService,
      agentVerificationService.getAgentVerificationResults,
      [formValues]
    );
    if (response.status === 200) {
      yield put(agentVerificationSuccess(response.value));
    } else {
      yield put(agentVerificationError());
    }
  } catch (err) {
    yield put(agentVerificationError());
  }
}
export function* handleAgentFilterRequest(action: ActionType<typeof agentFilterRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<AgentVerificationResponse[]> = yield apply(
      agentVerificationService,
      agentVerificationService.getAgentVerificationRequestsByFilter,
      [formValues]
    );
    if (response.status === 200) {
      yield put(agentFilterSuccess(response.value));
    } else {
      yield put(fetchagentFilterError());
    }
  } catch (err) {
    yield put(fetchagentFilterError());
  }
}
function* watchAgentVerificationRequest() {
  yield takeEvery(AgentVerificationActionTypes.AGENTVERIFICATION_REQUEST, handleAgentVerificationRequest);
}
function* watchAgentFilterRequest() {
  yield takeEvery(AgentVerificationActionTypes.AGENTFILTER_REQUEST, handleAgentFilterRequest);
}

function* agentVerificationSaga() {
  yield all([fork(watchAgentVerificationRequest)]);
  yield all([fork(watchAgentFilterRequest)]);
}

export default agentVerificationSaga;
