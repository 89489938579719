import { Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../state/index';
import { INonprofitState, NonprofitDocumentEnum } from '../../../state/nonprofit';
import { RenderSortedDocuments } from '../../renderDocuments/RenderSortedDocuments';
import { StatusIcon } from '../../util/StatusIcon';
import '../Details.scss';

interface IHistoryProps {
  searchResults?: INonprofitState;
  ignoreHeader?: boolean;
}

const History: React.FunctionComponent<IHistoryProps> = ({ searchResults, ignoreHeader }) => (
  <section id="history">
    {searchResults === undefined || searchResults.isHistoryResultsLoading === true ? (
      <Spinner id="searchSpinner" size={SpinnerSize.large} label="Loading History..." ariaLive="assertive" />
    ) : (
      <section id="detailsSection">
        {ignoreHeader ? (
          <div></div>
        ) : (
          <div className="detailsSectionHeader">
            <section id="status">
              <StatusIcon status="History" />
              <span className="boldText">History</span>
            </section>
          </div>
        )}

        <div className="sectionDetails">
          <RenderSortedDocuments documentEnum={NonprofitDocumentEnum.HISTORY} searchResults={searchResults} />
        </div>
      </section>
    )}
  </section>
);
const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  searchResults: nonprofit
});

export default connect(mapStateToProps)(History);
