import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  Dropdown,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import localeService from '../../../dataServices/LocaleService';
import { IApplicationState } from '../../../state/index';
import { IAdminState, ISetFraudFormSubmission } from '../../../state/admin';
import { setFraudReasonCodeRequest } from '../../../state/admin/actions';
import { SuccessAction } from '../SuccessAction';
import './SetFraud.scss';

interface IPropsFromDispatch {
  setFraudReasonCodeRequest: (val: ISetFraudFormSubmission) => void;
}

export interface IFraudProps {
  hideDialog?: boolean;
  adminState: IAdminState;
  nonprofitStatus: string;
  nonprofitId: string;
}

export type ISetFraudProps = IPropsFromDispatch & IFraudProps;
let selecteddrpOption: string;
export class SetFraud extends React.Component<
  ISetFraudProps,
  {
    hideDialog: boolean;
    commentsContent: string;
  }
> {
  constructor(props: ISetFraudProps) {
    super(props);
    this.state = {
      hideDialog: true,
      commentsContent: ''
    };
  }
  handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ commentsContent: event.target.value });
  }

  public render() {
    return (
      <div id="setFraudPopup">
        {this.props.adminState.setFraudNonprofitLoader ? (
          <Spinner id="searchSpinner" size={SpinnerSize.medium} />
        ) : this.props.adminState.fraudByNonprofit &&
          this.props.adminState.fraudByNonprofit.setNonprofitFraudSuccess ? (
          <SuccessAction />
        ) : this.props.adminState.fraudByNonprofit &&
          !this.props.adminState.fraudByNonprofit.setNonprofitFraudSuccess ? (
          <MessageBar
            actions={
              <div>
                <PrimaryButton secondaryText="Set Fraud" onClick={this.showDialog} text="Set Fraud" />
              </div>
            }
            messageBarType={MessageBarType.error}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
          >
            {this.props.adminState.fraudByNonprofit.failureMessage} Please try again.
          </MessageBar>
        ) : null}
        {this.props.nonprofitStatus !== 'Fraud' ? (
          <section id="fraudAction">
            {this.props.adminState.setFraudNonprofitLoader ||
            (this.props.adminState.fraudByNonprofit &&
              this.props.adminState.fraudByNonprofit.setNonprofitFraudSuccess !== undefined) ? null : (
              <PrimaryButton secondaryText="Set Fraud" onClick={this.showDialog} text="Set Fraud" />
            )}
            <Dialog
              hidden={this.state.hideDialog}
              onDismiss={this.closeDialog}
              dialogContentProps={{
                title: 'Mark as Fraud',
                subText:
                  'You are about to set the nonprofit with the id "' +
                  this.props.nonprofitId +
                  '" as Fraud. Do you really want to continue?',
                type: DialogType.largeHeader
              }}
              modalProps={{
                containerClassName: 'ms-dialogMainOverride fraudDialog',
                isBlocking: false
              }}
            >
              <DialogContent className="dialogContent">
                <p>Reason</p>
                <Dropdown
                  defaultValue=""
                  required={true}
                  id="fraudReasonCodes"
                  onChange={(e, selectedOption) => {
                    selecteddrpOption = selectedOption ? selectedOption.text.replace(/ /g, '') : '';
                  }}
                  options={this.getReasonCodes()}
                />
                <br></br>
                <textarea
                  id="comments"
                  onBlur={this.handleChange.bind(this)}
                  placeholder="Comments"
                  maxLength={500}
                  style={{ width: '100%', height: '80px', outlineColor: 'rgb(0, 120, 212)' }}
                ></textarea>
              </DialogContent>
              <DialogFooter>
                <PrimaryButton onClick={this.setFraud} text="Submit" />
                <DefaultButton onClick={this.cancelAction} text="Cancel" />
              </DialogFooter>
            </Dialog>
          </section>
        ) : (
          <PrimaryButton secondaryText="Set Fraud" onClick={this.showDialog} text="Set Fraud" disabled={true} />
        )}
      </div>
    );
  }
  private getReasonCodes() {
    const localeContent = localeService.getLocaleContent();
    const countryCodes = localeContent.FraudReasonCodes;
    return countryCodes;
  }
  private showDialog = (): void => {
    this.setState({ hideDialog: false });
  };

  private closeDialog = (): void => {
    this.setState({ hideDialog: true });
  };

  private setFraud = (): void => {
    const formvalues = {
      nonprofitId: this.props.nonprofitId,
      reasonCode: selecteddrpOption,
      comments: this.state.commentsContent
    } as ISetFraudFormSubmission;
    this.props.setFraudReasonCodeRequest(formvalues);
    this.closeDialog();
  };

  private cancelAction = (): void => {
    this.closeDialog();
  };
}

const mapStateToProps = ({ admin, nonprofit }: IApplicationState) => ({
  adminState: admin,
  nonprofitStatus: nonprofit.additionalNonprofitInfo ? nonprofit.additionalNonprofitInfo.status : '',
  nonprofitId: nonprofit.nonprofitId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFraudReasonCodeRequest: (value: ISetFraudFormSubmission) => dispatch(setFraudReasonCodeRequest(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetFraud);
