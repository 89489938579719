import { initializeIcons } from '@fluentui/react';
import { createBrowserHistory } from 'history';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './state/store';
import './index.scss';
import { appFeatures, appSettings } from './common';
import { telemetryService } from 'npo-common';

initializeIcons();
appSettings.initialize();
appFeatures.initialize();
telemetryService.setContext({
  Environment: appSettings.environmentStr,
  Scs: appSettings.scs,
  ServiceName: appSettings.serviceName,
  ServiceRegion: appSettings.serviceRegion,
  ServiceVersion: appSettings.serviceVersion,
  ServiceId: appSettings.serviceId,
  ServiceType: appSettings.serviceType
});


const history = createBrowserHistory();

const disableReactDevTools = (): void => {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    }
  }
};

// disable the react dev tools is production.
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
