import { all, apply, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { IApiResponse } from '../../common/model/ApiResponse';
import AdminService from '../../dataServices/AdminService';
import { setAgentVerificationStatus, setOutreachStatus } from '../agentVerification';
import {
  addCommentsError,
  addCommentsRequest,
  addCommentsSuccess,
  azureRegistrationLinkError,
  azureRegistrationLinkRequest,
  azureRegistrationLinkSuccess,
  fetchFraudNonprofitError,
  fetchFraudReasonCodeError,
  fetchLinkCommercialError,
  fetchoutreachError,
  fetchReprocessError,
  fetchverifyAgentError,
  getDownloadableUrlRequest,
  linkCommercialRequest,
  linkCommercialSuccess,
  outreachRequest,
  outreachSuccess,
  reprocessRequest,
  reprocessSuccess,
  sendBulkReprocessError,
  sendBulkReprocessRequest,
  sendBulkReprocessSuccess,
  setBulkFraudRequest,
  setFailedValuesToState,
  setFraudNonprofitRequest,
  setFraudNonprofitSuccess,
  setFraudReasonCodeRequest,
  setFraudReasonCodeSuccess,
  setSuccessValuesToState,
  verifyAgentRequest,
  verifyAgentSuccess
} from './actions';
import {
  AdminActionTypes,
  IAddCommentsState,
  IBulkReprocessResponse,
  IOutreachState,
  IReprocessResponse,
  IVerifyAgentState
} from './types';

export function* handleSetFraudNonprofitRequest(action: ActionType<typeof setFraudNonprofitRequest>) {
  let failedValues = [];
  try {
    const formValues = action.payload;
    const response: IApiResponse<boolean> = yield apply(AdminService, AdminService.setFraudByNonprofitId, [formValues]);
    if (response.status === 200) {
      yield put(
        setFraudNonprofitSuccess({
          setNonprofitFraudSuccess: response.status === 200,
          failureMessage: undefined
        })
      );
    } else {
      failedValues.push(formValues);
      yield put(
        fetchFraudNonprofitError({
          setNonprofitFraudSuccess: false,
          failureMessage: response.error ? response.error['message'] : undefined
        })
      );
    }
  } catch (err: any) {
    yield put(fetchFraudNonprofitError(err));
  } finally {
    yield put(setFailedValuesToState(failedValues));
  }
}

export function* handleSetFraudReasonCodeRequest(action: ActionType<typeof setFraudReasonCodeRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<boolean> = yield apply(AdminService, AdminService.SetFraudByNonprofitIdV2, [
      formValues
    ]);
    if (response.status === 200) {
      yield put(
        setFraudReasonCodeSuccess({
          setNonprofitFraudSuccess: true,
          failureMessage: undefined
        })
      );
    } else {
      yield put(
        fetchFraudReasonCodeError({
          setNonprofitFraudSuccess: false,
          failureMessage: response.error ? response.error['message'] : undefined
        })
      );
    }
  } catch (err: any) {
    yield put(
      fetchFraudReasonCodeError({
        setNonprofitFraudSuccess: false,
        failureMessage: err
      })
    );
  }
}

export function* handleVerifyAgentRequest(action: ActionType<typeof verifyAgentRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<IVerifyAgentState> = yield apply(AdminService, AdminService.verifyAgentByNonprofitId, [
      formValues
    ]);
    if (response.status === 200) {
      yield put(verifyAgentSuccess());
      yield put(setAgentVerificationStatus(formValues.agentVerificationId));
    } else {
      yield put(fetchverifyAgentError(response.error ? response.error['message'] : ''));
    }
  } catch (err: any) {
    yield put(fetchverifyAgentError(err));
  }
}

export function* handleBulkNonprofitRequests(action: ActionType<typeof setBulkFraudRequest>) {
  const listOfFailedIds = [];
  const listOfNonprofitsFromForm = action.payload;
  const listOfSuccessIds = [];
  for (let i = 0; i < listOfNonprofitsFromForm.length; i++) {
    try {
      const response: IApiResponse<boolean> = yield apply(AdminService, AdminService.setFraudByNonprofitId, [
        listOfNonprofitsFromForm[i]
      ]);
      if (response.status === 200) {
        listOfSuccessIds.push(listOfNonprofitsFromForm[i]);
      } else {
        listOfFailedIds.push(listOfNonprofitsFromForm[i]);
      }
    } catch {
      listOfFailedIds.push(listOfNonprofitsFromForm[i]);
    }
  }
  if (listOfFailedIds.length > 0) {
    yield put(setFailedValuesToState(listOfFailedIds));
  }
  if (listOfSuccessIds.length > 0) {
    yield put(setSuccessValuesToState(listOfSuccessIds));
  }
}

export function* handleOutreachRequest(action: ActionType<typeof outreachRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<IOutreachState> = yield apply(AdminService, AdminService.outreachByNonprofitId, [
      formValues
    ]);
    if (response.status === 200) {
      if (response.value) {
        response.value.agentVerificationId = formValues.agentVerificationId;
      }
      yield put(outreachSuccess());
      if (response.value) {
        yield put(setOutreachStatus(response.value));
      }
    } else {
      if (response.error) {
        yield put(fetchoutreachError(response.error.toString()));
      }
    }
  } catch (err: any) {
    yield put(fetchoutreachError(err.toString()));
  }
}

export function* handleLinkCommercialTenantRequest(action: ActionType<typeof linkCommercialRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<boolean> = yield apply(AdminService, AdminService.linkCommercialTenant, [formValues]);
    if (response.status === 200) {
      yield put(linkCommercialSuccess({ isCommmercialLinkingSuccess: response.status === 200, failureMessage: '' }));
    } else {
      if (response.error) {
        yield put(fetchLinkCommercialError(response.error['message']));
      }
    }
  } catch (err: any) {
    yield put(fetchLinkCommercialError(err));
  }
}

export function* handleReprocessTransactionRequest(action: ActionType<typeof reprocessRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<IReprocessResponse> = yield apply(AdminService, AdminService.ReprocessTransaction, [
      formValues
    ]);
    if (response.status === 200) {
      yield put(
        reprocessSuccess({
          isReprocessSuccessful: response.status === 200,
          failureMessage: '',
          reprocessActionDescription: response.value ? response.value.reprocessActionDescription : '',
          reprocessAction: response.value ? response.value.reprocessAction : ''
        })
      );
    } else {
      yield put(fetchReprocessError(JSON.stringify(response)));
    }
  } catch (err: any) {
    yield put(fetchReprocessError(err));
  }
}

export function* handleAzureRegistrationLinkingRequest(action: ActionType<typeof azureRegistrationLinkRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<{}> = yield apply(AdminService, AdminService.AzureRegistrationLinking, [formValues]);

    if (response.status === 200) {
      yield put(azureRegistrationLinkSuccess());
    } else {
      if (response.error) {
        yield put(azureRegistrationLinkError(response.error.toString()));
      }
    }
  } catch (err: any) {
    yield put(azureRegistrationLinkError(err));
  }
}

export function* handleGetDownloadableUrlRequest(action: ActionType<typeof getDownloadableUrlRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<string> = yield apply(AdminService, AdminService.GetDownloadableUrl, [formValues]);
    if (response.status === 200) {
      if (response.value) {
        window.open(response.value['downloadableUrl']);
      }
    } else {
      alert('Download failed! Error: ' + response.error.toString());
    }
  } catch (err: any) {
    alert('Download failed! Error: ' + err.toString());
  }
}

export function* handleAddCommentsRequest(action: ActionType<typeof addCommentsRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<IAddCommentsState> = yield apply(AdminService, AdminService.addCommentsByNonprofitId, [
      formValues
    ]);
    if (response.status === 200) {
      yield put(addCommentsSuccess());
    } else {
      yield put(addCommentsError(JSON.stringify(response.error)));
    }
  } catch (err: any) {
    yield put(addCommentsError(err.toString()));
  }
}

export function* handleSendBulkReprocessRequest(action: ActionType<typeof sendBulkReprocessRequest>) {
  try {
    const formValues = action.payload;
    const response: IApiResponse<IBulkReprocessResponse> = yield apply(AdminService, AdminService.sendBulkReprocess, [
      formValues
    ]);
    if (response.status === 200) {
      yield put(sendBulkReprocessSuccess(response.value));
    } else {
      yield put(sendBulkReprocessError(JSON.stringify(response.value)));
    }
  } catch (err: any) {
    yield put(sendBulkReprocessError(err.toString()));
  }
}

function* watchSetFraudNonprofitRequest() {
  yield takeEvery(AdminActionTypes.SET_FRAUD_NONPROFIT_REQUEST, handleSetFraudNonprofitRequest);
}

function* watchSetFraudReasonCodeRequest() {
  yield takeEvery(AdminActionTypes.SET_FRAUD_REASONCODE_REQUEST, handleSetFraudReasonCodeRequest);
}

function* watchBulkFraudNonprofitRequest() {
  yield takeEvery(AdminActionTypes.SET_BULK_FRAUD_REQUEST, handleBulkNonprofitRequests);
}

function* watchLinkCommercialTenantRequest() {
  yield takeEvery(AdminActionTypes.LINK_COMMERCIAL_REQUEST, handleLinkCommercialTenantRequest);
}

function* watchReprocessTransactionRequest() {
  yield takeEvery(AdminActionTypes.REPROCESS_REQUEST, handleReprocessTransactionRequest);
}

function* watchOutreachRequest() {
  yield takeEvery(AdminActionTypes.OUTREACH_REQUEST, handleOutreachRequest);
}

function* watchAzureRegistrationLinkingRequest() {
  yield takeEvery(AdminActionTypes.AZURE_REGISTRATION_LINK_REQUEST, handleAzureRegistrationLinkingRequest);
}

function* watchVerifyAgentRequest() {
  yield takeEvery(AdminActionTypes.VERIFY_AGENT_REQUEST, handleVerifyAgentRequest);
}

function* watchhandleGetDownloadableUrlRequest() {
  yield takeEvery(AdminActionTypes.GET_DOWNLOADABLE_URL_REQUEST, handleGetDownloadableUrlRequest);
}

function* watchAddCommentsRequest() {
  yield takeEvery(AdminActionTypes.ADD_COMMENTS_REQUEST, handleAddCommentsRequest);
}

function* watchSendBulkReprocessRequest() {
  yield takeEvery(AdminActionTypes.SEND_BULK_REQUEST, handleSendBulkReprocessRequest);
}

function* adminSaga() {
  yield all([
    fork(watchSetFraudNonprofitRequest),
    fork(watchBulkFraudNonprofitRequest),
    fork(watchLinkCommercialTenantRequest),
    fork(watchReprocessTransactionRequest),
    fork(watchOutreachRequest),
    fork(watchAzureRegistrationLinkingRequest),
    fork(watchSetFraudReasonCodeRequest),
    fork(watchVerifyAgentRequest),
    fork(watchhandleGetDownloadableUrlRequest),
    fork(watchAddCommentsRequest),
    fork(watchSendBulkReprocessRequest)
  ]);
}

export default adminSaga;
