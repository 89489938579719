import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RoleById } from '../../common/utilities/evaluateUserAuthorization';
import { IApplicationState } from '../../state/index';
import { IAuthorizationStatus } from '../../state/auth';
import * as authActions from '../../state/auth/actions';
import { initiateLogout } from '../../state/auth/actions';
import Text from '../text/Text';
import './UserPanel.scss';

interface IPropsFromState {
  userName: string;
  emailAddress: string;
  authStatus: IAuthorizationStatus | null;
}

interface IPropsFromDispatch {
  initiateLogout: typeof authActions.initiateLogout;
}

export type IUserPanelProps = IPropsFromState & IPropsFromDispatch;

interface IUserPanelState {
  showing: boolean;
  fullMenu: boolean;
}

export class UserPanel extends React.Component<IUserPanelProps, IUserPanelState> {
  constructor(props: IUserPanelProps) {
    super(props);
    this.state = { showing: false, fullMenu: false };
  }

  public componentDidMount() {
    document.addEventListener('mouseup', this.hidePanelOnMouseUp);
  }

  public componentWillUnmount() {
    document.removeEventListener('mouseup', this.hidePanelOnMouseUp);
  }

  public render() {
    const { showing, fullMenu } = this.state;
    let displayClass = showing ? 'show' : 'hide';
    if (fullMenu) {
      displayClass += ' dismiss';
    }

    return (
      <div id="user-panel">
        <button id="user-panel-button" onClick={showing ? this.hidePanel : this.showPanel} title="Your Account" />
        <aside className={displayClass}>
          <div>
            <div>
              <div>
                <br />
              </div>
              <div>
                <div>{this.props.userName}</div>
                <div>{this.props.emailAddress}</div>
              </div>
            </div>
            <ul>
              <li>
                {this.props.authStatus !== null && renderButtonBasedOnRole(this.props.authStatus.whoIs, 'AdminSearch')}
              </li>
              <li>
                {this.props.authStatus !== null &&
                  renderButtonBasedOnRole(this.props.authStatus.whoIs, 'AgentVerification')}
              </li>
              <li>
                <button className="link-button" onKeyDown={this.handleKeyDown} onClick={this.props.initiateLogout}>
                  <Text category="UserPanel" id="SignOut" />
                </button>
              </li>
              <li>
                <button className="link-button" onClick={this.hidePanel}>
                  <Text category="UserPanel" id="DismissDialog" />
                </button>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    );
  }

  private showPanel = () => {
    this.setState({ showing: true, fullMenu: false });
  };

  private hidePanel = () => {
    this.setState({ showing: false, fullMenu: false });
  };

  private handleKeyDown = (evt: { key: string }) => {
    if (evt.key === 'Tab') {
      this.setState({ fullMenu: true });
    }
  };

  private hidePanelOnMouseUp = () => {
    this.hidePanel();
  };
}

function renderButtonBasedOnRole(whoIs: RoleById, menuName: string) {
  if (whoIs.valueOf().toLowerCase() === 'admin' || whoIs.valueOf().toLowerCase() === 'businessadmin') {
    if (menuName === 'AdminSearch') {
      return <a href="/admin/search">Admin Search</a>;
    } else if (menuName === 'AgentVerification') {
      return <a href="/agentverification">Agent Verification</a>;
    }
  }
  return null;
}

const mapStateToProps = ({ auth }: IApplicationState): IPropsFromState => ({
  emailAddress: !auth.user ? '' : auth.user.username,
  userName: !auth.user ? '' : auth.user.name ?? '',
  authStatus: auth.authorizationStatus ?? null
});

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
  initiateLogout: () => dispatch(initiateLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
