import { Icon, MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../state/index';
import { IAdminState } from '../../../state/admin';
import '../../details/Details.scss';
import './Result.scss';

export interface IFraudProps {
  adminState: IAdminState;
}
class Result extends React.Component<IFraudProps> {
  public render() {
    return (
      <section id="result-section">
        {
          <MessageBar
            actions={
              <div>
                <MessageBarButton onClick={this.refreshPage}>Refresh</MessageBarButton>
              </div>
            }
            messageBarType={MessageBarType.info}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
          >
            {this.props.adminState.successValues !== undefined && this.props.adminState.successValues.length > 0 && (
              <section id="success-region">
                <p>
                  {' '}
                  <p className="success">
                    <Icon iconName="CheckMark" />
                  </p>
                  The following {this.props.adminState.successValues.length} record(s) marked as <b>successfully</b> as
                  Fraud
                </p>
                {this.props.adminState.successValues.map((node) => {
                  return <p>{node}</p>;
                })}
              </section>
            )}
            {this.props.adminState.failedValues !== undefined && this.props.adminState.failedValues.length > 0 && (
              <section id="failure-region">
                <p>
                  <p className="failure">
                    <Icon iconName="Cancel" />
                  </p>
                  The following {this.props.adminState.failedValues.length} record(s) <b>failed</b> to be marked as
                  Fraud
                </p>
                {this.props.adminState.failedValues.map((node) => {
                  return <p>{node}</p>;
                })}
              </section>
            )}
          </MessageBar>
        }
      </section>
    );
  }

  private refreshPage = (): void => {
    window.location.reload();
  };
}

const mapStateToProps = ({ admin }: IApplicationState) => ({
  adminState: admin
});
export default connect(mapStateToProps)(Result);
