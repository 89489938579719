import { TextField } from '@fluentui/react';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

export class AzureRegistrationFields extends React.Component<{}> {
  public render(): JSX.Element {
    return (
      <div>
        <Field
          name="nonprofitId"
          id="nonprofitId"
          type="text"
          label={'Nonprofit Id'}
          component={this.renderTextBox}
          disabled
        />
        <Field
          name="registrationId"
          id="registrationId"
          type="text"
          label={'Registration Id'}
          component={this.renderTextBox}
          required
        />
        <Field name="comments" id="comments" type="textarea" label={'Comments'} component={this.renderTextArea} />
      </div>
    );
  }

  private renderTextBox = ({ input, ...props }: FieldRenderProps<string, any>) => (
    <div>
      <TextField {...input} id={props.id} label={props.label} disabled={props.disabled} required={props.required} />
    </div>
  );
  private renderTextArea = ({ input, ...props }: FieldRenderProps<string, any>) => (
    <div>
      <label style={{ fontSize: '14px', fontWeight: 600, padding: '5px 0px', display: 'block' }}>{props.label}</label>
      <textarea
        {...input}
        id={props.id}
        aria-label={props.label}
        placeholder={props.label}
        maxLength={500}
        style={{ width: '100%', height: '80px', outlineColor: 'rgb(0, 120, 212)' }}
      ></textarea>
    </div>
  );
}

export default AzureRegistrationFields;
