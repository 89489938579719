import { Reducer } from 'redux';
import { ISearchState, SearchActionTypes } from './types';

// Type-safe initialState!
const initialState: ISearchState = {
  currentNonProfit: undefined,
  isLoading: undefined,
  results: [],
  selectedNonProfitIds: []
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<ISearchState> = (state = initialState, action) => {
  switch (action.type) {
    case SearchActionTypes.SEARCH_REQUEST: {
      return { ...state, results: undefined, isLoading: true };
    }
    case SearchActionTypes.SEARCH_SUCCESS: {
      return { ...state, results: action.payload, isLoading: false };
    }
    case SearchActionTypes.SET_CURRENT_NONPROFIT: {
      return { ...state, currentNonProfit: action.payload };
    }
    case SearchActionTypes.FETCH_ERROR: {
      return { ...state, isLoading: undefined };
    }
    case SearchActionTypes.SET_SELECTED_NONPROFIT: {
      return { ...state, selectedNonProfitIds: action.payload };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.ts`
export { reducer as searchReducer };
