import { FocusZone, FocusZoneDirection, IColumn, mergeStyleSets, Toggle } from '@fluentui/react';
import * as React from 'react';
import { ISearchState } from '../../state/search';
import RenderResultGrid from './RenderResultGrid';
import RenderResultList from './RenderResultList';
import './SearchSummary.scss';

interface ISearchProps {
  searchResults?: ISearchState;
  queryText: string;
}
const headerStyle = mergeStyleSets({
  boldHeader: {
    fontWeight: 'bold'
  }
});

enum keys {
  name = 'name',
  status = 'status',
  email = 'agentEmail',
  country = 'country',
  nonprofitId = 'nonprofitId',
  tenantId = 'tenantId',
  date = 'date',
  address = 'address',
  domain = 'domain',
  transactionId = 'transactionId'
}

const generatedColumns: IColumn[] = [
  {
    key: keys.name,
    name: 'Name',
    fieldName: 'nonprofitName',
    minWidth: 200,
    maxWidth: 440,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true }
  },
  {
    key: keys.status,
    name: 'Status',
    fieldName: 'status',
    minWidth: 70,
    maxWidth: 100,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true, isGroupable: true, isGrouped: false }
  },
  {
    key: keys.email,
    name: 'Agent Email',
    fieldName: 'agentEmail',
    minWidth: 180,
    maxWidth: 230,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true }
  },
  {
    key: keys.address,
    name: 'Address',
    fieldName: 'nonprofitAddressStateRegion',
    minWidth: 200,
    maxWidth: 250,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: false }
  },
  {
    key: keys.country,
    name: 'Country',
    fieldName: 'nonprofitAddressCountryCode',
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true, isGroupable: true, isGrouped: false }
  },
  {
    key: keys.date,
    name: 'Effective Date',
    fieldName: 'effectiveDateTime',
    minWidth: 110,
    maxWidth: 175,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true }
  }
];

export class SearchSummary extends React.Component<ISearchProps, { isGridEnabled?: boolean }> {
  constructor(props: ISearchProps) {
    super(props);

    this.state = {
      isGridEnabled: true
    };
  }

  public render() {
    const { searchResults, queryText } = this.props;

    return (
      <section id="searchResults">
        {searchResults?.results && searchResults.results.length > 0 ? (
          <FocusZone direction={FocusZoneDirection.vertical}>
            <div className="ms-Grid-row" id="toggle-control">
              <Toggle
                label="Grid View"
                defaultChecked={true}
                inlineLabel
                onText="On"
                offText="Off"
                onChange={this.onChange.bind(this)}
              />
            </div>
            <div className="ms-Grid-row" id="summary-line">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <p>
                  {searchResults.results.length} search results for "{queryText}"
                </p>
              </div>
            </div>
            {this.state.isGridEnabled ? (
              <RenderResultGrid searchResults={searchResults.results} columnsHeaders={generatedColumns} />
            ) : (
              <RenderResultList searchResults={searchResults.results} />
            )}
          </FocusZone>
        ) : searchResults && searchResults.isLoading === false ? (
          <p>There are no records that match your search criteria.</p>
        ) : (
          <p></p>
        )}
      </section>
    );
  }

  private onChange(ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined): void {
    this.setState({ isGridEnabled: checked ? true : false });
  }
}

export default SearchSummary;
