import { ProgressIndicator, Shimmer, ShimmerElementType, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../state/index';
import { IAuthState } from '../../state/auth';
import { ISearchFormSubmission, ISearchState } from '../../state/search';
import SearchSummary from '../../components/search/SearchSummary';
import * as searchActions from '../../state/search/actions';
import { searchRequest } from '../../state/search/actions';
import SearchForm from './SearchForm';
import './SearchPage.scss';

interface IPropsFromDispatch {
  searchRequest: typeof searchActions.searchRequest;
}

export interface ISearchStateProps {
  searchResults: ISearchState;
  isLoading: boolean;
  authDetails: IAuthState;
  match: any;
}
export type SearchProps = IPropsFromDispatch & ISearchStateProps & RouteComponentProps;

class SearchPage extends React.Component<SearchProps> {
  private value!: ISearchFormSubmission;

  public componentDidUpdate(nextProps: ISearchStateProps) {
    const { match } = this.props;

    const queryValue = match.params['queryval'];
    if (
      queryValue !== undefined &&
      nextProps.authDetails.authorizationStatus !== this.props.authDetails.authorizationStatus
    ) {
      this.value = { query: queryValue };
      this.submitForm(this.value);
    }
  }

  public render() {
    const { searchResults, isLoading, match, authDetails } = this.props;

    const queryValue = match.params['queryval'];
    return (
      <section id="search-page">
        {authDetails.authorizationStatus === null ? (
          <section id="shimmer">
            <ProgressIndicator barHeight={4} className="progress-indicator" />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
              style={{ padding: '5px 20px 12px 20px' }}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 32 }]}
              style={{ padding: '0px 0px 0px 20px' }}
              width="88px"
            />
          </section>
        ) : (
          <SearchForm
            initialValues={{ query: queryValue }}
            onSubmit={(values: ISearchFormSubmission) => this.submitForm(values)}
          />
        )}
        {authDetails.authorizationStatus !== null && isLoading === true ? (
          <Spinner id="searchSpinner" size={SpinnerSize.large} label="Searching..." ariaLive="assertive" />
        ) : authDetails.authorizationStatus !== null && isLoading === false ? (
          <section id="searchSummaryDiv">
            <SearchSummary queryText={queryValue} searchResults={searchResults} />
          </section>
        ) : (
          <p></p>
        )}
      </section>
    );
  }

  private submitForm(values: ISearchFormSubmission) {
    this.props.history.push('/search/query=' + values.query);
    this.props.searchRequest(values);
  }
}

const mapStateToProps = ({ search, auth }: IApplicationState) => ({
  authDetails: auth,
  isLoading: search.isLoading ?? false,
  searchResults: search
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  searchRequest: (formValues: ISearchFormSubmission) => dispatch(searchRequest(formValues))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
