import { IApiResponse } from '../common/model/ApiResponse';
import { FeatureFlags } from '../common/utilities/FeatureFlags';
import { httpRequest } from '../common/utilities/HttpRequest';
import { FeatureFlagsState } from '../state/feature-flags/types';
import authenticationService from './AuthenticationService';

export const encodeGetParams = (p: { [key: string]: any }): string =>
  Object.entries(p)
    .map((kv) => kv.map(encodeURIComponent).join('='))
    .join('&');

class FeatureFlagsService {
  private DEFAULT_FLAGS = FeatureFlags;
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async GetFeatureFlags(): Promise<IApiResponse<FeatureFlagsState>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(
      `${this.API_URL}/featureflags?${encodeGetParams({ names: Object.keys(this.DEFAULT_FLAGS) })}`,
      token ?? undefined
    );
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }
}

const featureFlagsService = new FeatureFlagsService();
export default featureFlagsService;
