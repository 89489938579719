import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RoleById } from '../../common/utilities/evaluateUserAuthorization';
import { IApplicationState } from '../../state/index';

export interface IBoundaryProps {
  isCheckSatisfied: boolean;
  redirect?: string;
  children?: React.ReactNode;
}

export class Boundary extends React.Component<IBoundaryProps> {
  public render() {
    const { isCheckSatisfied, redirect } = this.props;

    if (!isCheckSatisfied) {
      return redirect ? <Redirect to={redirect} push={true} /> : null;
    }

    return this.props.children;
  }
}

interface IBoundaryOwnProps {
  redirect?: string;
}

const authRequiredMapStateToProps = ({ auth }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: auth.isAuthenticated,
  redirect: ownProps.redirect
});

const unauthRequiredMapStateToProps = ({ auth }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: !auth.isAuthenticated,
  redirect: ownProps.redirect
});

const searchAuthorizedMapStateToProps = ({ auth }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: auth.authorizationStatus ? auth.authorizationStatus.whoIs !== RoleById._0 : true,
  redirect: ownProps.redirect
});

const isAdminOrBusinessAdminMapStateToProps = ({ auth }: IApplicationState, ownProps: IBoundaryOwnProps) => ({
  isCheckSatisfied: auth.authorizationStatus
    ? auth.authorizationStatus.whoIs === RoleById._3 || auth.authorizationStatus.whoIs === RoleById._2
    : true,
  redirect: ownProps.redirect
});

export const AuthRequiredBoundary = connect(authRequiredMapStateToProps)(Boundary);
export const UnauthRequiredBoundary = connect(unauthRequiredMapStateToProps)(Boundary);
export const SearchAuthorized = connect(searchAuthorizedMapStateToProps)(Boundary);
export const AdminAuthorized = connect(isAdminOrBusinessAdminMapStateToProps)(Boundary);
