import { MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IAdminState, ICommentsFormSubmission, addCommentsRequest } from '../../../state/admin';
import { IApplicationState } from '../../../state/index';
import { SuccessAction } from '../SuccessAction';

interface IPropsFromDispatch {
  addCommentsRequest: (val: ICommentsFormSubmission) => void;
}

interface IPropsFromState {
  adminState: IAdminState;
  nonprofitId: string;
}

export type IAddCommentsProps = IPropsFromDispatch & IPropsFromState;

export const AddComments: React.FunctionComponent<IAddCommentsProps> = (props: IAddCommentsProps) => {
  const [comments, setComments] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setComments(event.target.value);
  };

  const addComments = (): void => {
    const formvalues = {
      nonprofitId: props.nonprofitId,
      comments: comments
    } as ICommentsFormSubmission;
    props.addCommentsRequest(formvalues);
  };

  const primaryButton = (
    <PrimaryButton id="Add Comments" onClick={addComments} text="Add Comments" disabled={comments.length === 0} />
  );

  const renderSection = (
    <div id="addCommentsComponent">
      <p>
        To add comments, enter text and click <b>Add Comments</b> button below.
      </p>
      <textarea
        placeholder="Comments"
        onChange={handleChange}
        maxLength={500}
        style={{ width: '100%', height: '80px', outlineColor: 'rgb(0, 120, 212)' }}
      ></textarea>

      {props.adminState.addCommentsInProgress ? (
        <Spinner id="searchSpinner" size={SpinnerSize.medium} />
      ) : props.adminState.addCommentsState && props.adminState.addCommentsState.addCommentsSuccess ? (
        <SuccessAction />
      ) : props.adminState.addCommentsState && !props.adminState.addCommentsState.addCommentsSuccess ? (
        <MessageBar
          id="failureMessage"
          actions={<div>{primaryButton}</div>}
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel="Close"
        >
          {props.adminState.addCommentsState.failureMessage} Please try again.
        </MessageBar>
      ) : (
        primaryButton
      )}
    </div>
  );

  return renderSection;
};

const mapStateToProps = ({ admin, nonprofit }: IApplicationState): IPropsFromState => ({
  adminState: admin,
  nonprofitId: nonprofit.nonprofitId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addCommentsRequest: (val: ICommentsFormSubmission) => dispatch(addCommentsRequest(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddComments);
