import { appSettings } from '../common';
import { IApiResponse } from '../common/model/ApiResponse';
import { httpRequest } from '../common/utilities/HttpRequest';
import {
  IAgentErrorSummaryResult,
  IHistoryResult,
  INonprofitDetail,
  INonProfitSearchResult
} from '../state/nonprofit';
import { ISearchFormSubmission, ISearchResult } from '../state/search';
import authenticationService from './AuthenticationService';
import meoLocaleService from './MeoLocaleService';

export class SearchService {
  private API_URL = appSettings.internalApiBaseUrl;

  public async getSearchResults(formValues: ISearchFormSubmission): Promise<IApiResponse<ISearchResult[]>> {
    const query = formValues.query !== undefined ? formValues.query : '';
    const token = await authenticationService.getToken();
    const response = await httpRequest(`${this.API_URL}/search/nonprofit?query=${query}`, token ?? undefined);
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }

  public async getHistoryResults(formValues: string): Promise<IApiResponse<IHistoryResult>> {
    const token = await authenticationService.getToken();
    const query = formValues !== undefined ? formValues : '';
    const response = await httpRequest(`${this.API_URL}/history/${query}`, token ?? undefined);
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }

  public async getAgentErrorResults(formValues: string): Promise<IApiResponse<IAgentErrorSummaryResult>> {
    const token = await authenticationService.getToken();
    const query = formValues !== undefined ? formValues : '';
    const response = await httpRequest(`${this.API_URL}/history/agenterrors/${query}`, token ?? undefined);
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }

  public async getNonprofitResults(formValues: string): Promise<IApiResponse<INonprofitDetail>> {
    const token = await authenticationService.getToken();
    const query = formValues !== undefined ? formValues : '';
    const response = await httpRequest(`${this.API_URL}/nonprofit/${query}`, token ?? undefined);
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }

  public async getNonprofitSummaryResults(formValues: string): Promise<IApiResponse<INonProfitSearchResult>> {
    const token = await authenticationService.getToken();
    const query = formValues !== undefined ? formValues : '';
    const response = await httpRequest(`${this.API_URL}/nonprofitsearch/${query}`, token ?? undefined);
    const meoLocales = await meoLocaleService.getMeoLocales();

    if (response.ok) {
      const searchResult: INonProfitSearchResult = await response.json();

      // replace display values for meo locale preferences
      for (const nonprofit of searchResult.NonprofitDocument) {
        const cultureCode = nonprofit.emailLanguagePreference ?? meoLocaleService.DefaultLocale;
        const locale = meoLocales?.find(x => x.cultureCode === cultureCode);

        // render the matched locale, or the cultureCode of any supported locales
        nonprofit.emailLanguagePreference = locale?.language ?? nonprofit.emailLanguagePreference;
      }

      return { status: response.status, value: searchResult };
    } else {
      throw new Error(response.statusText);
    }
  }
}

const searchService = new SearchService();
export default searchService;
