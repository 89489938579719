import {
  IAzureRegistrationLinkingFormSubmission,
  IBulkReprocessResponse,
  ICommentsFormSubmission,
  ICommercialFormSubmission,
  IOutreachFormSubmission,
  IReprocessFormSubmission,
  IReprocessPayload,
  ISetFraudFormSubmission,
  IVerifyAgentFormSubmission
} from '../state/admin';
import { IApiResponse } from '../common/model/ApiResponse';
import { httpRequest } from '../common/utilities/HttpRequest';
import authenticationService from './AuthenticationService';
import { callApi, customJsonStringify } from './SharedServiceFunctions';

export class AdminService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;
  private API_URL_V2 = process.env.REACT_APP_INTERNAL_API_BASEURL_V2;
  private API_URL_1 = process.env.REACT_APP_AGENT_VERIFICATION_API_BASEURL;

  public async setFraudByNonprofitId(nonprofitId: string): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(`${this.API_URL}/admin/nonprofit/${nonprofitId}/setfraud`, undefined, 'PUT');
    return response;
  }

  public async verifyAgentByNonprofitId(input: IVerifyAgentFormSubmission): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(
      `${this.API_URL_1}/agentverificationrequests/approve`,
      JSON.stringify({
        agentVerificationId: input.agentVerificationId,
        nonprofitId: input.nonprofitId,
        processName: input.processName,
        comments: input.comments
      }),
      'PUT'
    );
    return response;
  }

  public async SetFraudByNonprofitIdV2(input: ISetFraudFormSubmission): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(
      `${this.API_URL_V2}/admin/nonprofit/${input.nonprofitId}/setfraud`,
      JSON.stringify({
        reasonCode: input.reasonCode,
        comments: input.comments
      }),
      'PUT'
    );
    return response;
  }

  public async outreachByNonprofitId(input: IOutreachFormSubmission): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(
      `${this.API_URL_1}/agentverificationrequests/outreach`,
      JSON.stringify({
        agentVerificationId: input.agentVerificationId,
        nonprofitId: input.nonprofitId,
        processName: input.processName,
        comments: input.comments
      }),
      'PUT'
    );
    return response;
  }

  public async linkCommercialTenant(commercialInput: ICommercialFormSubmission): Promise<IApiResponse<{}>> {
    const commercialTenantValues = { tenantId: commercialInput.tenantId, domain: commercialInput.domain };
    const response = await callApi<{}>(
      `${this.API_URL}/admin/nonprofit/${commercialInput.nonprofitId}/setcommercial`,
      customJsonStringify(commercialTenantValues),
      'PUT'
    );
    return response;
  }

  public async ReprocessTransaction(input: IReprocessFormSubmission): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(
      `${this.API_URL}/admin/nonprofit/${input.nonprofitId}/reprocess`,
      JSON.stringify({
        nonprofitId: input.nonprofitId,
        comments: input.comments
      }),
      'PUT'
    );
    return response;
  }

  public async AzureRegistrationLinking(payload: IAzureRegistrationLinkingFormSubmission): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(
      `${this.API_URL}/admin/nonprofit/${payload.nonprofitId}/associate/azure/`,
      JSON.stringify({
        nonprofitId: payload.nonprofitId,
        registrationId: payload.registrationId,
        comments: payload.comments
      }),
      'PATCH'
    );
    return response;
  }

  public async GetDownloadableUrl(artifactName: string): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(
      `${this.API_URL}/admin/artifacts/downloadableurl?artifactName=${artifactName}`,
      undefined,
      'GET'
    );
    return response;
  }

  public async addCommentsByNonprofitId(payload: ICommentsFormSubmission): Promise<IApiResponse<{}>> {
    const response = await callApi<{}>(
      `${this.API_URL}/admin/nonprofit/${payload.nonprofitId}/comments`,
      JSON.stringify({
        comments: payload.comments
      }),
      'POST'
    );
    return response;
  }

  public async sendBulkReprocess(payload: IReprocessPayload): Promise<IApiResponse<IBulkReprocessResponse>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(
      `${this.API_URL}/admin/reprocess`,
      token ?? undefined,
      JSON.stringify(payload),
      'PUT'
    );

    if (response.status) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }
}

const adminService = new AdminService();
export default adminService;
