import {
  CheckboxVisibility,
  ColumnActionsMode,
  ContextualMenu,
  ContextualMenuItemType,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  DirectionalHint,
  getTheme,
  IColumn,
  IContextualMenuProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IGroup,
  mergeStyleSets,
  Selection,
  SelectionMode
} from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { ISearchResult, ISearchResultAccumulator } from '../../../state/search';
import * as searchActions from '../../../state/search/actions';
import { setSelectedNonprofitIds } from '../../../state/search/actions';
import '../../search/RenderResultGrid.scss';
import CopyToClipboard from '../../util/CopyToClipboard';
import StatusIcon from '../../util/StatusIcon';
import { formatCityRegion } from '../../util/Utilities';

const theme = getTheme();

export interface ISearchStateProps {
  searchResults?: ISearchResult[];
}

interface IGridProps {
  contextualMenuProps?: IContextualMenuProps;
  groups?: IGroup[];
  currentGroupedColumn?: string;
  selectionDetails: string;
}

interface IPropsFromDispatch {
  setNonprofitIdsToState?: typeof searchActions.setSelectedNonprofitIds;
}

export type SearchListProps = IPropsFromDispatch & ISearchStateProps;

const headerStyle = mergeStyleSets({
  boldHeader: {
    fontWeight: 'bold'
  }
});
enum keys {
  checkbox = 'checkbox',
  name = 'name',
  status = 'status',
  email = 'agentEmail',
  country = 'country',
  nonprofitId = 'nonprofitId',
  tenantId = 'tenantId',
  date = 'date',
  address = 'address',
  domain = 'domain',
  transactionId = 'transactionId',
  phone = 'phone',
  orgemail = 'email',
  website = 'website'
}

let sortedItems: ISearchResult[];
let tableColumns: IColumn[] = getTableColumns();

function getTableColumns(): IColumn[] {
  const generatedColumns: IColumn[] = [
    {
      key: keys.nonprofitId,
      name: 'NonprofitId',
      fieldName: 'NonprofitId',
      minWidth: 300,
      maxWidth: 350,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true }
    },
    {
      key: keys.name,
      name: 'Name',
      fieldName: 'nonprofitName',
      minWidth: 200,
      maxWidth: 440,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true }
    },
    {
      key: keys.tenantId,
      name: 'TenantId',
      fieldName: 'TenantId',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true }
    },
    {
      key: keys.status,
      name: 'Status',
      fieldName: 'status',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true, isGroupable: true, isGrouped: false }
    },
    {
      key: keys.address,
      name: 'Address',
      fieldName: 'nonprofitAddressStateRegion',
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: false }
    },
    {
      key: keys.country,
      name: 'Country',
      fieldName: 'nonprofitAddressCountryCode',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true, isGroupable: true, isGrouped: false }
    },
    {
      key: keys.email,
      name: 'Agent Email',
      fieldName: 'agentEmail',
      minWidth: 120,
      maxWidth: 230,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true }
    },
    {
      key: keys.phone,
      name: 'Phone',
      fieldName: 'Phone',
      minWidth: 100,
      maxWidth: 230,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true }
    },
    {
      key: keys.domain,
      name: 'Domain',
      fieldName: 'Domain',
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true }
    },
    {
      key: keys.orgemail,
      name: 'Org Email',
      fieldName: 'Email',
      minWidth: 160,
      maxWidth: 300,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true }
    },
    {
      key: keys.website,
      name: 'Website',
      fieldName: 'Website',
      minWidth: 180,
      maxWidth: 300,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true }
    },
    {
      key: keys.transactionId,
      name: 'TransactionId',
      fieldName: 'TransactionId',
      minWidth: 250,
      maxWidth: 400,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      headerClassName: headerStyle.boldHeader,
      data: { isSortable: true }
    }
  ];

  return generatedColumns;
}

export class RenderResultGrid extends React.Component<SearchListProps, IGridProps> {
  private _selection: Selection;
  public static sort(currColumn: IColumn, items: ISearchResult[], isSortedDescending?: boolean) {
    // This will put all nulls at the end of the list depending on sorting
    const replacement = isSortedDescending ? '' : '~';
    // This is getting the property from the object itself.
    // This means that for fields like address or tenantId that do NOT
    // have a direct map to a particular field, they are not able to be sorted
    // This was the easiest way to sort the most columns in a clean fashion
    // However, in order for the currently unsortable columns to be sorted,
    // there must be a change to this.
    const key = currColumn.fieldName! as keyof ISearchResult;

    if (currColumn.data.isSortable) {
      // Sort the items
      items.sort((a, b) =>
        // Don't allow nulls or empty strings, Convert to same case for sorting, use replacement character for null
        (a[key] != null && (a[key] || '').toString().length > 0
          ? (a[key] || '').toString().toLowerCase()
          : replacement) <
        // descending sort
        (b[key] != null && (b[key] || '').toString().length > 0 ? (b[key] || '').toString().toLowerCase() : replacement)
          ? 1
          : -1
      );

      // if the list is currently ascending, reverse it
      if (!isSortedDescending) {
        items.reverse();
      }
    }
  }

  constructor(props: SearchListProps | Readonly<SearchListProps>) {
    super(props);
    const { searchResults } = this.props;
    sortedItems = searchResults ?? [];
    this._selection = new Selection({
      onSelectionChanged: () => this.setState({ selectionDetails: this.getSelectionDetails() })
    });

    this.state = {
      contextualMenuProps: undefined,
      groups: undefined,
      currentGroupedColumn: undefined,
      selectionDetails: this.getSelectionDetails()
    };

    tableColumns = getTableColumns();
  }

  public render() {
    const { contextualMenuProps, groups, selectionDetails } = this.state;

    return (
      <div>
        <div>{selectionDetails}</div>
        <section id="details-list-grid">
          <DetailsList
            selection={this._selection}
            items={sortedItems}
            setKey="set"
            columns={tableColumns}
            ariaLabelForSelectAllCheckbox="Select all checkbox"
            onRenderItemColumn={this.renderItemColumn}
            onColumnHeaderClick={this.onColumnClick}
            onRenderRow={this.onRenderRow}
            selectionMode={SelectionMode.multiple}
            layoutMode={DetailsListLayoutMode.justified}
            compact={true}
            groups={groups}
            checkButtonAriaLabel="Checkbox"
            checkboxVisibility={CheckboxVisibility.always}
            selectionPreservedOnEmptyClick={true}
          />
          {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
        </section>
      </div>
    );
  }

  private getSelectionDetails(): string {
    const selectionItems = this._selection.getSelection() as ISearchResult[];
    let selectedIds = selectionItems.map((a) => a.nonprofitId);
    this.updateState(selectedIds);
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return 'No items selected';
      default:
        return `${selectionCount} items selected`;
    }
  }

  private updateState = (selectedIds: any): void => {
    if (this.props.setNonprofitIdsToState) {
      this.props.setNonprofitIdsToState(selectedIds);
    }
  };

  private onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element => {
    if (props === undefined) {
      return <></>;
    }

    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props.itemIndex % 2 !== 0) {
      // Every other row renders with a different background color
      customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
    }

    return <DetailsRow {...props} styles={customStyles} />;
  };

  private renderItemColumn(
    item: ISearchResult,
    index: number | undefined,
    column: IColumn | undefined
  ): React.ReactNode {
    if (column === undefined) {
      return <></>;
    }

    switch (column.key) {
      case keys.nonprofitId:
        return (
          <section id="nonprofitid">
            <p className="value-content">
              <Link to={`/search/nonprofit/${item.nonprofitId}`}>{item.nonprofitId} </Link>
              <CopyToClipboard textToCopy={item.nonprofitId} />
            </p>
          </section>
        );

      case keys.name:
        return (
          <section id="status">
            <StatusIcon status={item.status} />
            <p className="value-content">
              <Link to={`/search/nonprofit/${item.nonprofitId}`}>{item.nonprofitName || item.techSoupName} </Link>
              <CopyToClipboard textToCopy={item.nonprofitName || item.techSoupName} />
            </p>
          </section>
        );

      case keys.email:
        return item.agentEmail ? (
          <p className="value-content">
            {item.agentEmail} <CopyToClipboard textToCopy={item.agentEmail} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.status:
        return item.status ? (
          <p className="value-content">
            {item.status} <CopyToClipboard textToCopy={item.status} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.address:
        return formatCityRegion(item).length > 0 ? (
          <p className="value-content">
            {formatCityRegion(item)} <CopyToClipboard textToCopy={formatCityRegion(item)} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.country:
        return item.nonprofitAddressCountryCode ? (
          <p className="value-content">
            {item.nonprofitAddressCountryCode} <CopyToClipboard textToCopy={item.nonprofitAddressCountryCode} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.phone:
        return item.phone ? (
          <p className="value-content">
            {item.phone} <CopyToClipboard textToCopy={item.phone} />
          </p>
        ) : (
          <p />
        );

      case keys.orgemail:
        return item.email ? (
          <p className="value-content">
            {item.email} <CopyToClipboard textToCopy={item.email} />
          </p>
        ) : (
          <p />
        );

      case keys.website:
        return item.website ? (
          <p className="value-content">
            {item.website} <CopyToClipboard textToCopy={item.website} />
          </p>
        ) : (
          <p />
        );

      case keys.tenantId:
        return item.officeProfiles.map((node) => {
          return String(node.tenantId);
        }) ? (
          <p className="value-content">
            {item.officeProfiles.map((node) => {
              return String(node.tenantId);
            })}
            <CopyToClipboard
              textToCopy={item.officeProfiles
                .map((node) => {
                  return String(node.tenantId);
                })
                .toString()}
            />{' '}
          </p>
        ) : (
          <p />
        );

      case keys.domain:
        return item.officeProfiles.map((node) => {
          return String(node.domain);
        }) ? (
          <p className="value-content">
            {item.officeProfiles.map((node) => {
              return String(node.domain);
            })}
            <CopyToClipboard
              textToCopy={item.officeProfiles
                .map((node) => {
                  return String(node.domain);
                })
                .toString()}
            />
          </p>
        ) : (
          <p />
        );

      case keys.transactionId:
        return item.transactions.map((node) => {
          return String(node.transactionId);
        }) ? (
          <p className="value-content">
            {item.transactions.map((node) => {
              return String(node.transactionId);
            })}
            <CopyToClipboard
              textToCopy={item.transactions
                .map((node) => {
                  return String(node.transactionId);
                })
                .toString()}
            />
          </p>
        ) : (
          <p />
        );

      default:
        return null;
    }
  }

  private onColumnClick = (event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void => {
    if (event === undefined || column === undefined) {
      return;
    }

    if (column.columnActionsMode !== ColumnActionsMode.disabled && column.data.isSortable) {
      this.setState({
        contextualMenuProps: this.getContextualMenuProps(event, column)
      });
    }
  };

  private sortAndForceUpdate(column: IColumn, isSortedDescending: boolean) {
    // Ungroup and sort
    this.clearGroups();

    const newColumns: IColumn[] = tableColumns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

    // don't sort the unsortable columns
    if (column.data.isSortable) {
      newColumns.forEach((newCol: IColumn) => {
        if (newCol.key === currColumn.key) {
          currColumn.isSortedDescending = isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = false;
        }
      });
      // update the columns
      tableColumns = newColumns;
    }

    RenderResultGrid.sort(column, sortedItems, isSortedDescending);
    this.forceUpdate();
  }

  private getContextualMenuProps(ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps {
    const items = [
      {
        key: 'sort-header',
        itemType: ContextualMenuItemType.Header,
        text: 'Sort',
        itemProps: {
          lang: 'en-us'
        }
      },
      {
        key: 'aToZ',
        name: column.key === keys.date ? 'Oldest to New' : 'A to Z',
        iconProps: { iconName: 'SortUp' },
        canCheck: true,
        checked: column.isSorted && !column.isSortedDescending,
        onClick: () =>
          (column.data.isGrouped || !(column.isSorted && !column.isSortedDescending)) &&
          this.sortAndForceUpdate(column, false)
      },
      {
        key: 'zToA',
        name: column.key === keys.date ? 'Newest to oldest' : 'Z to A',
        iconProps: { iconName: 'SortDown' },
        canCheck: true,
        checked: column.isSorted && column.isSortedDescending,
        onClick: () =>
          (column.data.isGrouped || !(column.isSorted && column.isSortedDescending)) &&
          this.sortAndForceUpdate(column, true)
      }
    ];

    if (this.isGroupable(column)) {
      items.push({
        key: 'group-header',
        itemType: ContextualMenuItemType.Header,
        text: 'Group',
        itemProps: {
          lang: 'en-us'
        }
      });

      items.push({
        key: 'groupBy',
        name: 'By ' + column.name,
        iconProps: { iconName: 'GroupedDescending' },
        canCheck: true,
        checked: column.data.isGrouped,
        onClick: () => this.groupBy(column)
      });
    }

    return {
      items,
      target: ev.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 0,
      isBeakVisible: true,
      beakWidth: 10,
      onDismiss: this.onContextualMenuDismissed
    };
  }

  private isGroupable(column: IColumn) {
    return column.data.isGroupable;
  }

  private onContextualMenuDismissed = (): void => {
    this.setState({
      contextualMenuProps: undefined
    });
  };

  private clearGroups() {
    const currentGroupedColumn = this.state.currentGroupedColumn;
    if (currentGroupedColumn !== undefined) {
      const currentCol = tableColumns.filter((currCol) => currentGroupedColumn === currCol.key)[0];
      currentCol.data.isGrouped = false;
    }

    this.setState({ currentGroupedColumn: undefined, groups: undefined });
  }

  private groupBy(column: IColumn) {
    if (column.data.isGrouped) {
      this.clearGroups();
      return;
    }

    // sort(which also ungroups - if it is grouped), and then group
    this.sortAndForceUpdate(column, false);

    const groups = sortedItems.reduce((currentGroups: ISearchResultAccumulator[], currentItem, index) => {
      const previousGroup = currentGroups[currentGroups.length - 1];
      const fieldValue = column.fieldName ? currentItem[column.fieldName as keyof ISearchResult] : null;

      if (!previousGroup || previousGroup.value !== fieldValue) {
        currentGroups.push({
          key: 'group' + fieldValue + index,
          name: `${fieldValue === null ? 'Unknown' : fieldValue}`,
          value: fieldValue,
          startIndex: index,
          level: 0,
          count: 0
        });
      }

      if (previousGroup) {
        previousGroup.count = index - previousGroup.startIndex;
      }

      return currentGroups;
    }, []);

    // Update last group count
    const lastGroupItem = groups[groups.length - 1];

    if (lastGroupItem) {
      lastGroupItem.count = sortedItems.length - lastGroupItem.startIndex;
    }

    this.setState({
      groups,
      currentGroupedColumn: column.key
    });

    column.data.isGrouped = true;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setNonprofitIdsToState: (val: any) => dispatch(setSelectedNonprofitIds(val))
});

export default connect(null, mapDispatchToProps)(RenderResultGrid);
