import { Action, AnyAction, combineReducers, Dispatch, Reducer } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { adminReducer, IAdminState } from './admin';
import adminSaga from './admin/sagas';
import { agentVerificationReducer, AgentVerificationState } from './agentVerification';
import agentVerificationSaga from './agentVerification/sagas';
import { authReducer } from './auth/reducer';
import authSaga from './auth/sagas';
import { IAuthState } from './auth/types';
import { featureFlagsReducer, FeatureFlagsState } from './feature-flags';
import featureFlagsSaga from './feature-flags/sagas';
import { INonprofitState, nonprofitReducer } from './nonprofit';
import nonprofitSaga from './nonprofit/sagas';
import { searchReducer } from './search';
import searchSaga from './search/sagas';
import { ISearchState } from './search/types';
import { sharedReducer } from './shared/reducer';
import sharedSaga from './shared/sagas';
import { SharedState } from './shared/types';

// The top-level application state object
export interface IApplicationState {
  auth: IAuthState;
  form?: any;
  search: ISearchState;
  nonprofit: INonprofitState;
  admin: IAdminState;
  flags: FeatureFlagsState;
  agentVerification: AgentVerificationState;
  sharedState: SharedState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface IConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export function createRootReducer(): Reducer<IApplicationState, AnyAction> {
  return combineReducers<IApplicationState>({
    admin: adminReducer,
    auth: authReducer,
    nonprofit: nonprofitReducer,
    search: searchReducer,
    flags: featureFlagsReducer,
    agentVerification: agentVerificationReducer,
    sharedState: sharedReducer
  });
}

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([
    fork(adminSaga),
    fork(authSaga),
    fork(searchSaga),
    fork(nonprofitSaga),
    fork(featureFlagsSaga),
    fork(agentVerificationSaga),
    fork(sharedSaga)
  ]);
}
