import { IApiResponse } from '../common/model/ApiResponse';
import { httpRequest } from '../common/utilities/HttpRequest';
import authenticationService from './AuthenticationService';

export class ConfigService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async getCountryResults(): Promise<IApiResponse<{}>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(`${this.API_URL}/resource/countrycodes/list/en-us`, token ?? undefined);
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }
}

const configService = new ConfigService();
export default configService;
