import { Icon } from '@fluentui/react';
import * as React from "react";
import './StatusIcon.scss';

interface IStatusIconProps {
    status: string;
}

export class StatusIcon extends React.Component<IStatusIconProps> {
    public render() {
        const { status } = this.props;

        return (
            {
                "": <p className="error"> <Icon iconName="StatusCircleQuestionMark" /> </p>,
                "AdminAction": <p className="success"><Icon iconName="Admin" /></p>,
                "Approved": <p className="success"> <Icon iconName="DocumentApproval" /> </p>,
                "Closed": <p className="closed"> <Icon iconName="Timeline" /> </p>,
                "Declined": <p className="error"> <Icon iconName="ErrorBadge" /> </p>,
                "Document": <p><Icon iconName="Document" ></Icon></p>,
                "Escalations": <p> <Icon iconName="DoubleChevronUp8" /> </p>,
                "Fraud": <p className="error"> <Icon iconName="AlertSolid" /> </p>,
                "History": <p><Icon iconName="History" ></Icon></p>,
                "InProgress": <p className="inprogress"> <Icon iconName="TimeEntry" /> </p>,
                "Unknown": <p className="error"> <Icon iconName="StatusCircleQuestionMark" /></p>
            }[status] || <p className="error"> <Icon iconName="StatusCircleQuestionMark" /></p>
        );
    }
}

export default StatusIcon;