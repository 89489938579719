import * as msal from '@azure/msal-browser';
import { RoleById } from '../../common/utilities/evaluateUserAuthorization';

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export const enum AuthActionTypes {
  SET_AUTH_STATUS = '@@auth/SET_AUTH_STATUS',
  INITIATE_LOGIN = '@@auth/INITIATE_LOGIN',
  PROCESS_LOGIN_CALLBACK = '@@auth/PROCESS_LOGIN_CALLBACK',
  INITIATE_LOGOUT = '@@auth/INITIATE_LOGOUT',
  SET_AUTHORIZATION_DETAIL = '@@auth/SET_AUTHORIZATION_DETAIL'
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface IAuthState {
  readonly isAuthenticated: boolean;
  readonly user: msal.AccountInfo | null;
  readonly authorizationStatus: IAuthorizationStatus | null | undefined;
}

export interface IAuthorizationStatus {
  readonly whoIs: RoleById;
}
