import * as React from "react";
import Documents from '../details/documents/Documents';
import Escalations from '../details/escalations/Escalations';
import History from '../details/history/History';
import NonprofitDetail from '../details/nonprofit/NonprofitDetail';

const AdminViewBig: React.FunctionComponent = () => (
    <section id="adminViewBig">
        <div className="ms-Grid-col ms-md6 ms-lg3 ms-xl3">
            <NonprofitDetail />
        </div>
        <div className="ms-Grid-col ms-md6 ms-lg3 ms-xl3"><div className="tile-content"><Escalations /></div></div>
        <div className="ms-Grid-col ms-md6 ms-lg3 ms-xl3"><div className="tile-content"><Documents /></div></div>
        <div className="ms-Grid-col ms-md6 ms-lg3 ms-xl3"><div className="tile-content"><History /></div></div>
    </section >
);

export default AdminViewBig;