import { IApiResponse } from '../common/model/ApiResponse';
import { httpRequest } from '../common/utilities/HttpRequest';
import authenticationService from './AuthenticationService';

export class AuthorizationService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async checkAuthorization(): Promise<IApiResponse<boolean>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(`${this.API_URL}/auth/searchtool`, token ?? undefined);
    return { status: response.status, value: response.status === 200 };
  }

  public async isAdmin(): Promise<IApiResponse<boolean>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(`${this.API_URL}/auth/admin`, token ?? undefined);
    return { status: response.status, value: response.status === 200 };
  }

  public async isBusinessAdmin(): Promise<IApiResponse<boolean>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(`${this.API_URL}/auth/businessadmin`, token ?? undefined);
    return { status: response.status, value: response.status === 200 };
  }
}

const authorizationService = new AuthorizationService();
export default authorizationService;
