import { List } from '@fluentui/react';
import * as React from 'react';
import { ISearchResult } from '../../state/search';
import SearchListItem from './SearchListItem';

interface ISearchProps {
  searchResults?: ISearchResult[];
}

export class RenderResultList extends React.Component<ISearchProps> {
  public render() {
    const { searchResults } = this.props;
    return <List items={searchResults} onRenderCell={this._onRenderCell} />;
  }

  private _onRenderCell(item: ISearchResult | undefined): React.ReactNode {
    return <SearchListItem item={item} />;
  }
}

export default RenderResultList;
