import { all, apply, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { IApiResponse } from '../../common/model/ApiResponse';
import searchService from '../../dataServices/SearchService';
import {
  agentErrorSuccess,
  fetchError,
  fetchNonprofitError,
  fetchNonprofitSummaryError,
  historyRequest,
  historySuccess,
  nonprofitRequest,
  nonprofitSearchRequest,
  nonprofitSearchSuccess,
  nonprofitSuccess,
  nonprofitSummaryRequest
} from './actions';
import {
  IAgentErrorSummaryResult,
  IHistoryResult,
  INonprofitDetail,
  INonProfitSearchResult,
  NonprofitActionTypes
} from './types';

export function* handleNonprofitSearchRequest(action: ActionType<typeof nonprofitSearchRequest>) {
  try {
    const formValues = action.payload;
    yield put(historyRequest());
    yield put(nonprofitSummaryRequest());
    yield put(nonprofitRequest());
    yield fetchNonprofitResponse(formValues);
    yield fetchHistoryResponse(formValues);
    yield fetchAgentErrorResponse(formValues);
  } catch (err: any) {
    yield put(fetchError(err.toString()));
  }
}

function* fetchHistoryResponse(formValues: string) {
  try {
    const historyResponse: IApiResponse<IHistoryResult> = yield apply(searchService, searchService.getHistoryResults, [
      formValues
    ]);
    if (historyResponse.status === 200) {
      yield put(historySuccess(historyResponse.value));
      yield fetchNonprofitSummaryResponse(formValues);
    }
  } catch (err: any) {
    yield put(fetchError(err.toString()));
  }
}

function* fetchAgentErrorResponse(formValues: string) {
  try {
    const historyResponse: IApiResponse<IAgentErrorSummaryResult> = yield apply(
      searchService,
      searchService.getAgentErrorResults,
      [formValues]
    );
    if (historyResponse.status === 200) {
      yield put(agentErrorSuccess(historyResponse.value));
      yield fetchNonprofitSummaryResponse(formValues);
    }
  } catch (err: any) {
    yield put(fetchError(err.toString()));
  }
}

function* fetchNonprofitResponse(formValues: string) {
  try {
    const nonProfitResponse: IApiResponse<INonprofitDetail> = yield apply(
      searchService,
      searchService.getNonprofitResults,
      [formValues]
    );
    if (nonProfitResponse.status === 200) {
      yield put(nonprofitSuccess(nonProfitResponse.value));
    }
  } catch (err: any) {
    yield put(fetchNonprofitError(err.toString()));
  }
}

function* fetchNonprofitSummaryResponse(formValues: string) {
  try {
    const nonProfitSummaryResponse: IApiResponse<INonProfitSearchResult> = yield apply(
      searchService,
      searchService.getNonprofitSummaryResults,
      [formValues]
    );
    if (nonProfitSummaryResponse.status === 200) {
      yield put(nonprofitSearchSuccess(nonProfitSummaryResponse.value));
    }
  } catch (err: any) {
    yield put(fetchNonprofitSummaryError(err.toString()));
  }
}

function* watchNonprofitSearchRequest() {
  yield takeEvery(NonprofitActionTypes.NONPROFITSEARCH_REQUEST, handleNonprofitSearchRequest);
}

function* nonprofitSaga() {
  yield all([fork(watchNonprofitSearchRequest)]);
}

export default nonprofitSaga;
