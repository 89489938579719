import { action } from "typesafe-actions";
import {
  IAdditionalData,
  IAgentErrorSummaryResult,
  IHistoryResult,
  INonprofitDetail,
  INonProfitSearchResult,
  NonprofitActionTypes
} from "./types";

export const nonprofitSearchRequest = (values: string) => action(NonprofitActionTypes.NONPROFITSEARCH_REQUEST, values);

export const historyRequest = () => action(NonprofitActionTypes.HISTORY_REQUEST);
export const nonprofitSummaryRequest = () => action(NonprofitActionTypes.NONPROFITRESULTS_REQUEST);
export const nonprofitRequest = () => action(NonprofitActionTypes.NONPROFIT_REQUEST);

export const historySuccess = (data: IHistoryResult) => action(NonprofitActionTypes.HISTORY_SUCCESS, data);
export const agentErrorSuccess = (data: IAgentErrorSummaryResult) =>
  action(NonprofitActionTypes.AGENT_ERROR_SUCCESS, data);

export const nonprofitSuccess = (data: INonprofitDetail) => action(NonprofitActionTypes.NONPROFIT_SUCCESS, data);
export const nonprofitSearchSuccess = (data: INonProfitSearchResult) =>
  action(NonprofitActionTypes.NONPROFITRESULTS_SUCCESS, data);

export const setNonProfitId = (data: string) => action(NonprofitActionTypes.SET_NONPROFITID, data);

export const setAdditionalNonprofitInfo = (data: IAdditionalData) =>
  action(NonprofitActionTypes.SET_ADDITIONALNONPROFITINFO, data);

export const resetState = () => action(NonprofitActionTypes.RESET_STATE);

export const fetchError = (message: string) => action(NonprofitActionTypes.FETCH_ERROR, message);

export const fetchNonprofitError = (message: string) => action(NonprofitActionTypes.FETCH_NONPROFIT_ERROR, message);

export const fetchNonprofitSummaryError = (message: string) =>
  action(NonprofitActionTypes.FETCH_NONPROFITSUMMARY_ERROR, message);
