import { Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../state/index';
import { INonprofitState, NonprofitDocumentEnum } from '../../../state/nonprofit';
import { RenderSortedDocuments } from '../../renderDocuments/RenderSortedDocuments';
import StatusIcon from '../../util/StatusIcon';
import '../Details.scss';

interface IEscalationProps {
  searchResults?: INonprofitState;
  ignoreHeader?: boolean;
}

export class Escalations extends React.Component<IEscalationProps> {
  public render() {
    return (
      <section id="escalations">
        {this.props.searchResults === undefined ||
        this.props.searchResults.isNonprofitSearchResultsLoading === undefined ||
        this.props.searchResults.isNonprofitSearchResultsLoading === true ? (
          <Spinner id="searchSpinner" size={SpinnerSize.large} label="Loading Escalations..." ariaLive="assertive" />
        ) : (
          <section id="detailsSection">
            {this.props.ignoreHeader ? (
              <div></div>
            ) : (
              <div className="detailsSectionHeader">
                <section id="status">
                  <StatusIcon status="Escalations" />
                  <span className="boldText">Escalations</span>
                </section>
              </div>
            )}
            <div className="sectionDetails">
              {this.props.searchResults.isNonprofitSearchResultsLoading === false ? (
                <RenderSortedDocuments
                  documentEnum={NonprofitDocumentEnum.ESCALATIONS}
                  nonprofitSearchResults={this.props.searchResults.nonprofitSearchResults}
                />
              ) : (
                <p></p>
              )}
            </div>
          </section>
        )}
      </section>
    );
  }
}

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  searchResults: nonprofit
});

export default connect(mapStateToProps)(Escalations);
