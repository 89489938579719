import { DefaultButton } from '@fluentui/react';
import { Field, Form } from 'react-final-form';
import './SearchForm.scss';

interface ISearchFormProps {
  initialValues: {
    query: string;
  };
  onSubmit(event: any): void;
}

const required = (value: string) => (value ? undefined : 'Required');

const SearchForm = (props: ISearchFormProps) => {
  const { initialValues, onSubmit } = props;
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          id="search-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <label htmlFor="query" />
          <Field
            placeholder='Please enter a comma separated list of IDs or Domains with each item in double quotes. For example: "NonprofitId1", "NonprofitId2", "NonprofitId3"'
            id="query"
            name="query"
            component="input"
            type="text"
            className="admininputfield"
            validate={required}
          />
          <div id="submitBtn">
            <DefaultButton type="submit">Search</DefaultButton>
          </div>
        </form>
      )}
    />
  );
};

export default SearchForm;
