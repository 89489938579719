declare global {
  interface Window { AppFeatures: any; }
}

window.AppFeatures = window.AppFeatures || {};

export class AppFeatures {
  public tenantManagement: boolean = this.parseFeatureFlag(process.env.REACT_APP_FEATURES_TENANT_MANAGEMENT);

  private parseFeatureFlag(value : string | undefined) {
    let val = value !== undefined ? value.toLowerCase() : 'false';

    switch(val) {
      case "true":
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  initialize() {
    window.AppFeatures.log = () => {
      console.debug('AppFeatures', this);
      console.debug('process.env', process.env);
    };
  }
}

// export singleton instance
export const appFeatures = new AppFeatures();
