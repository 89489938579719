import { Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { RoleById } from '../../common/utilities/evaluateUserAuthorization';
import { IApplicationState } from '../../state/index';
import { IAuthorizationStatus } from '../../state/auth';
import { INonprofitState } from '../../state/nonprofit';
import AdminView from '../view/AdminView';
import BusinessAdminView from '../view/BusinessAdminView';
import SupportView from '../view/SupportView';
import './ItemDetail.scss';

interface ISearchProps {
  searchResults?: INonprofitState;
  authStatus: IAuthorizationStatus | undefined;
}

const ItemDetail: React.FunctionComponent<ISearchProps> = ({ searchResults, authStatus }) =>
  authStatus !== null && authStatus !== undefined ? (
    <section id="npSummary-div">
      <div className="ms-Grid" dir="ltr">
        {searchResults !== undefined && searchResults.nonprofitDetail !== undefined ? (
          renderBasedOnRole(authStatus.whoIs)
        ) : searchResults !== undefined && searchResults.isNonprofitDetailLoading !== true ? (
          <p>The nonprofit doesn't exist</p>
        ) : null}
      </div>
    </section>
  ) : (
    <Spinner id="searchSpinner" size={SpinnerSize.large} label="Loading Nonprofit details..." ariaLive="assertive" />
  );

function renderBasedOnRole(whoIs: RoleById) {
  if (whoIs.valueOf() === 'Admin') {
    return <AdminView />;
  }
  if (whoIs.valueOf() === 'BusinessAdmin') {
    return <BusinessAdminView />;
  }
  if (whoIs.valueOf() === 'Support') {
    return <SupportView />;
  }

  return null;
}

const mapStateToProps = ({ nonprofit, auth }: IApplicationState): ISearchProps => ({
  authStatus: auth.authorizationStatus ?? undefined,
  searchResults: nonprofit
});

export default connect(mapStateToProps)(ItemDetail);
