import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { initiateLogin } from '../../state/auth';
import Text from '../text/Text';
import './SignInCTA.scss';

interface IPropsFromDispatch {
  initiateLogin: typeof initiateLogin;
}

export type ISignInCTAProps = IPropsFromDispatch;

export class SignInCTA extends React.Component<ISignInCTAProps> {
  public render() {
    return (
      <section id="sign-in-cta">
        <button
          className="primary-button md"
          tabIndex={0}
          onKeyDown={this.handleKeyDown}
          onClick={this.props.initiateLogin}
        >
          <Text category="UserPanel" id="SignIn" />
        </button>
      </section>
    );
  }

  private handleKeyDown = (evt: { key: string }) => {
    if (evt.key === 'Enter') {
      this.props.initiateLogin();
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogin: () => dispatch(initiateLogin())
});

export default connect(null, mapDispatchToProps)(SignInCTA);
