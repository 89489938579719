import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import authenticationService from '../../dataServices/AuthenticationService';
import { IApplicationState } from '../../state/index';
import { IAuthState, initiateLogout } from '../../state/auth';

export interface IAuthAutoRenewOwnProps {
  authState?: IAuthState;
}

export interface IPropsFromDispatch {
  initiateLogout: typeof initiateLogout;
}

export type IAuthAutoRenewProps = IPropsFromDispatch & IAuthAutoRenewOwnProps;

export class AuthAutoRenew extends React.Component<IAuthAutoRenewProps> {
  private intervalId?: NodeJS.Timeout;
  private errorLogged: boolean = false;

  public componentDidMount() {
    const { authState } = this.props;
    if (authState && authState.isAuthenticated) {
      this.intervalId = setInterval(this.tryRefreshToken, 10 * 1000);
    }
  }

  public componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public render() {
    return null;
  }

  private tryRefreshToken = () => {
    const currentToken = authenticationService.cachedToken;
    authenticationService.getToken().then((token) => {
      console.debug('AuthAutoRenew: token acquired');
      if (token && currentToken !== token) {
        console.debug('AuthAutoRenew: obtained new token.', {
          ExpiresInMinutes: authenticationService.getTokenExpirationInMinutes(token)
        });
      }

      if (!token || authenticationService.getTokenExpirationInMinutes(token) < 0) {
        console.debug('AuthService:refreshToken:failed to refresh token, attempting to initiate logout.');
        this.props.initiateLogout();

        if (!this.errorLogged) {
          console.debug('AuthService:refreshToken:failed to refresh token, initiating logout.');
          this.errorLogged = true;
        }
      }
    });
  };
}

const mapStateToProps = ({ auth }: IApplicationState) => ({
  authState: auth
});

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
  initiateLogout: () => dispatch(initiateLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthAutoRenew);
