import {
  DefaultButton,
  IconButton,
  MessageBar,
  MessageBarType,
  Modal,
  Overlay,
  PrimaryButton,
  Spinner
} from '@fluentui/react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import localeService from '../../../dataServices/LocaleService';
import { IApplicationState } from '../../../state/index';
import { IAdminState, IReprocessPayload, resetState, sendBulkReprocessRequest } from '../../../state/admin';
import FileInput from '../../common/FileInput';
import Text from '../../text/Text';
import { IsValidGUID } from '../../util/Utilities';
import './ReprocessModal.scss';

interface IPropsFromParent {
  isOpen: boolean;
  onDismiss: () => void;
}

interface IPropsFromDispatch {
  resetState: () => void;
  sendBulkReprocessRequest: (val: IReprocessPayload) => void;
}

interface IPropsFromState {
  adminState: IAdminState;
}

export type IReprocessModalProps = IPropsFromParent & IPropsFromDispatch & IPropsFromState;

function ReprocessModal(props: IReprocessModalProps) {
  const [validationMessage, setValidationMessage] = useState('');
  const [nonprofitIds, setNonprofitIds] = useState<string[]>([]);

  const saveButtonEnabled = () => {
    if (nonprofitIds.length > 0 && !props.adminState.bulkReprocessRequestInProgress && validationMessage === '') {
      return true;
    }

    return false;
  };

  const cancelButtonEnabled = () => {
    return !props.adminState.bulkReprocessRequestInProgress;
  };

  const renderSuccessBar = (showSuccess: boolean) => {
    if (showSuccess && props.adminState.bulkReprocessState && props.adminState.bulkReprocessState.response) {
      const { batchId, successfulInitiationCount, failedNonprofitIds } = props.adminState.bulkReprocessState.response;
      const failedNonprofitArray: JSX.Element[] = [];

      if (typeof failedNonprofitIds === 'object') {
        Object.keys(failedNonprofitIds).map((key, index) =>
          failedNonprofitArray.push(
            <p key={index}>
              {`${key}: ${failedNonprofitIds[key as keyof Object]}`}
              <br />
            </p>
          )
        );
      }

      return (
        <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
          <b>{localeService.getText('ReprocessModal', 'ReprocessSuccess')}</b>
          <br />
          <b>{`${localeService.getText('ReprocessModal', 'BatchId')}`}</b> {`${batchId}`}
          <br />
          <b>{`${localeService.getText('ReprocessModal', 'SuccessfulInitiationCount')}`}</b>{' '}
          {`${successfulInitiationCount}`}
          <br />
          <b>{localeService.getText('ReprocessModal', 'FailedNonprofitIds')}</b>
          <br />
          {failedNonprofitArray}
        </MessageBar>
      );
    } else {
      return null;
    }
  };

  const renderError = (errorMessage: string) => {
    if (errorMessage !== null) {
      return (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {`${localeService.getText('ReprocessModal', 'ReprocessError')}: ${errorMessage}`}
        </MessageBar>
      );
    } else {
      return null;
    }
  };

  const onClickUpload = () => {
    props.sendBulkReprocessRequest({
      nonprofitIds: nonprofitIds
    });
  };

  const onFileUpload = (file: any) => {
    const supportedTypes = ['.txt', '.csv', '.tsv'];
    const typeRegex = new RegExp('([a-zA-Z0-9s_\\.-:])+(' + supportedTypes.join('|') + ')$');

    setValidationMessage('');
    setNonprofitIds([]);

    props.resetState();

    if (!typeRegex.test(file.name.toLocaleLowerCase())) {
      setValidationMessage(localeService.getText('ReprocessModal', 'TypeValidationMessage') ?? '');
    } else {
      file.text().then((text: string) => {
        const lines = text.split(/\r?\n/);

        lines.every((line) => {
          if (!IsValidGUID(line)) {
            setValidationMessage(`${localeService.getText('ReprocessModal', 'GUIDValidationMessage')} ${line}`);
            return false;
          }
          return true;
        });

        setNonprofitIds(lines);
      });
    }
  };

  const hideModal = () => {
    setValidationMessage('');
    props.resetState();
    props.onDismiss();
  };

  const hideInputs =
    props.adminState.bulkReprocessState &&
    (props.adminState.bulkReprocessState.isReprocessSuccessful ||
      props.adminState.bulkReprocessState.failureMessage !== null);

  return (
    <Modal isOpen={props.isOpen} onDismiss={hideModal} isBlocking={true}>
      <div className="reprocess-modal">
        <div className="header">
          <h1>
            <Text category="ReprocessModal" id="Title" />
          </h1>
          <IconButton iconProps={{ iconName: 'Cancel' }} ariaLabel="Close popup modal" onClick={hideModal} />
        </div>
        {props.adminState.bulkReprocessState &&
          renderSuccessBar(props.adminState.bulkReprocessState.isReprocessSuccessful)}
        {props.adminState.bulkReprocessState
          && props.adminState.bulkReprocessState.failureMessage
          && renderError(props.adminState.bulkReprocessState.failureMessage ?? '')}
        {validationMessage !== '' && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setValidationMessage('')}
            dismissButtonAriaLabel="Close"
          >
            {validationMessage}
          </MessageBar>
        )}
        {props.adminState.bulkReprocessRequestInProgress && (
          <Overlay className="overlay">
            <Spinner />
          </Overlay>
        )}
        {!hideInputs && (
          <>
            <p>
              <Text category="ReprocessModal" id="Description" />
            </p>
            <p>
              <FileInput onFileUpload={(file) => onFileUpload(file)} />
            </p>
            <p>
              <Text category="ReprocessModal" id="Footer" />
            </p>
            <div className="actions">
              <PrimaryButton
                text={localeService.getText('ReprocessModal', 'Continue') ?? undefined}
                onClick={onClickUpload}
                disabled={!saveButtonEnabled()}
              />
              <DefaultButton
                text={localeService.getText('ReprocessModal', 'Cancel') ?? undefined}
                onClick={hideModal}
                disabled={!cancelButtonEnabled()}
              />
            </div>
          </>
        )}
        {hideInputs && (
          <>
            <br />
            <PrimaryButton text={localeService.getText('ReprocessModal', 'Close') ?? undefined} onClick={hideModal} />
          </>
        )}
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ admin }: IApplicationState) => ({
  adminState: admin
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetState: () => dispatch(resetState()),
  sendBulkReprocessRequest: (value: IReprocessPayload) => dispatch(sendBulkReprocessRequest(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReprocessModal);
