import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { IApplicationState } from '../../state/index';
import { NonprofitDocumentEnum } from '../../state/nonprofit';
import RenderDocument from './RenderDocument';
import './RenderDocuments.scss';

interface ISearchProps {
  documents: any[];
  documentEnum?: NonprofitDocumentEnum;
}

const renderingDocumentsModal = (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.4)'
    }}
  >
    <div
      style={{
        backgroundColor: '#fefefe',
        margin: '15% auto',
        padding: '20px',
        border: '1px solid #888',
        width: '50%'
      }}
    >
      <h4>Rendering Documents...</h4>
    </div>
  </div>
);

export class RenderDocuments extends React.Component<ISearchProps> {
  private numberToSlice = 50;

  public state = {
    documentsToRender: this.props.documents.slice(0, this.numberToSlice)
  };

  private nextSliceOfDocs = () => {
    setTimeout(() => {
      const docsLength = this.state.documentsToRender.length;
      this.setState({
        documentsToRender: this.state.documentsToRender.concat(
          this.props.documents.slice(docsLength, docsLength + this.numberToSlice)
        )
      });
    }, 0);
  };

  public render() {
    return (
      <section id="renderedDocs">
        <InfiniteScroll
          style={{ overflow: 'hidden' }}
          dataLength={this.state.documentsToRender.length} //This is important field to render the next data
          next={this.nextSliceOfDocs}
          hasMore={this.props.documents.length > this.state.documentsToRender.length}
          loader={renderingDocumentsModal}
        >
          {this.state.documentsToRender.map((doc, i) => (
            <RenderDocument key={i} doc={doc} />
          ))}
        </InfiniteScroll>
      </section>
    );
  }
}

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  searchResults: nonprofit
});

export default connect(mapStateToProps)(RenderDocuments);
