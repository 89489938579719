import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as authActions from '../../state/auth/actions';
import { initiateLogin } from '../../state/auth/actions';
import './SignInPage.scss';

interface IPropsFromDispatch {
  initiateLogin: typeof authActions.initiateLogin;
}

export type SignInProps = IPropsFromDispatch;

export class SignInPage extends React.Component<SignInProps> {
  constructor(props: IPropsFromDispatch) {
    super(props);
    this.props.initiateLogin();
  }

  public render() {
    return null;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initiateLogin: () => dispatch(initiateLogin())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
