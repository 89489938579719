import { Icon } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getDownloadableUrlRequest } from '../../state/admin';
import { validURL } from '../util/CheckValidURL';
import CopyToClipboard from '../util/CopyToClipboard';
import RenderList from '../util/RenderList';
import './KeyValueText.scss';

interface IKeyValueTextProps {
  keyText: string;
  valueText?: string;
  boldText?: boolean;
  listText?: any;
  fieldName?: string;
  isHyperLink?: boolean;
}
interface IPropsFromDispatch {
  getDownloadableUrlRequest: (val: string) => void;
}
export type IKeyValueProps = IPropsFromDispatch & IKeyValueTextProps;
export class KeyValueText extends React.Component<IKeyValueProps> {
  public getDownloadableUrl() {
    var filename = this.props.valueText ? this.props.valueText.split('/').pop() : '';
    this.props.getDownloadableUrlRequest(filename ?? '');
  }
  public render() {
    return (
      <section id="key-value-text">
        <div className="ms-Grid-row">
          {this.props.keyText.length > 0 && (
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4">
              <p className="boldText">{this.props.keyText}</p>
            </div>
          )}
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8">
            <div className="key-value-tile-content">
              {this.props.listText ? (
                <RenderList fieldName={this.props.fieldName ?? ''} listText={this.props.listText} />
              ) : this.props.isHyperLink === true ? (
                <p className="boldText">
                  <a
                    aria-label="Search nonprofit"
                    className="valueHyperLink"
                    href={`/search/nonprofit/${this.props.valueText}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.valueText}{' '}
                  </a>{' '}
                  {this.props.valueText ? <CopyToClipboard textToCopy={this.props.valueText} /> : null}
                </p>
              ) : this.props.boldText === true ? (
                <p className="boldText">
                  {this.props.valueText} <CopyToClipboard textToCopy={this.props.valueText ?? ''} />
                </p>
              ) : (
                <p>
                  {this.props.valueText}{' '}
                  {this.props.valueText ? <CopyToClipboard textToCopy={this.props.valueText} /> : null}
                  {this.props.keyText === 'artifactUri' && validURL(this.props.valueText ?? '') ? (
                    <button className="link-button" onClick={() => this.getDownloadableUrl()}>
                      <Icon id="downloadIcon" iconName="Download" />
                    </button>
                  ) : null}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getDownloadableUrlRequest: (artifactName: string) => dispatch(getDownloadableUrlRequest(artifactName))
});
export default connect(null, mapDispatchToProps)(KeyValueText);
