import { Pivot, PivotItem } from '@fluentui/react';
import * as React from 'react';
import Documents from '../details/documents/Documents';
import Escalations from '../details/escalations/Escalations';
import History from '../details/history/History';
import NonprofitDetail from '../details/nonprofit/NonprofitDetail';

const enum PivotLinkFormat {
  tabs = 'tabs'
}

const enum PivotLinkSize {
  large = 'large'
}

const AdminViewMedium: React.FunctionComponent = () => (
  <section id="AdminViewMedium">
    <div className="ms-Grid-col ms-md6 ms-lg3 ms-xl4">
      <NonprofitDetail />
    </div>
    <div className="ms-Grid-col ms-md6 ms-lg3 ms-xl4">
      <Pivot linkFormat={PivotLinkFormat.tabs} linkSize={PivotLinkSize.large}>
        <PivotItem headerText="Document" itemIcon="Document">
          <div className="tile-content">
            <Documents ignoreHeader={true} />
          </div>
        </PivotItem>
        <PivotItem headerText="Escalations" itemIcon="DoubleChevronUp8">
          <div className="tile-content">
            <Escalations ignoreHeader={true} />
          </div>
        </PivotItem>
      </Pivot>
    </div>
    <div className="ms-Grid-col ms-md6 ms-lg3 ms-xl4">
      <div className="tile-content">
        <History />
      </div>
    </div>
  </section>
);

export default AdminViewMedium;
