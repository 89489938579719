export enum RoleById {
  _0 = 'UnAuthorized',
  _1 = 'Support',
  _2 = 'BusinessAdmin',
  _3 = 'Admin'
}

export function evaluateUserAuthorization(
  isAdminResponse: {
    status: number;
  },
  isBusinessAdminResponse: {
    status: number;
  },
  isSupportResponse: {
    status: number;
  }
): RoleById {
  if (isSupportResponse.status === 200 && isAdminResponse.status === 200) {
    return RoleById._3;
  } else if (isSupportResponse.status === 200 && isBusinessAdminResponse.status === 200) {
    return RoleById._2;
  } else if (isSupportResponse.status === 200) {
    return RoleById._1;
  }

  return RoleById._0;
}
