
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './index';
import { adminReducer } from './admin';
import { agentVerificationReducer } from './agentVerification';
import { authReducer } from './auth';
import { featureFlagsReducer } from './feature-flags';
import { nonprofitReducer } from './nonprofit';
import { searchReducer } from './search';
import { sharedReducer } from './shared';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    admin: adminReducer,
    auth: authReducer,
    nonprofit: nonprofitReducer,
    search: searchReducer,
    flags: featureFlagsReducer,
    agentVerification: agentVerificationReducer,
    sharedState: sharedReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(sagaMiddleware),
  preloadedState: {}
});
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
