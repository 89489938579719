export default function doTextSubstitution(
  text: string,
  substitutionData: any,
  beginTag: string = '{',
  endTag: string = '}'
): string {
  while (substitutionData) {
    const start = text.indexOf(beginTag);

    if (start === -1) {
      break;
    }

    const end = text.indexOf(endTag, start);

    if (end === -1) {
      break;
    }

    const fieldName = text.substr(start + beginTag.length, end - start - beginTag.length);
    const val = substitutionData[fieldName];

    text = text.substr(0, start) + (val ? val : '') + text.substr(end + endTag.length);

    return doTextSubstitution(text, substitutionData);
  }

  return text;
}
