import * as React from 'react';
import { AgentStatuses } from '../../state/agentVerification/types';

export class AgentVerificationUtil extends React.Component {
  public static isUnverifiedAgentStatus(status: string, nonprofitStatus: string): boolean {
    return (
      (status === AgentStatuses.Outreach.toString() || status === AgentStatuses.Pending.toString()) &&
      !this.isNonprofitFraud(nonprofitStatus)
    );
  }

  public static isNonprofitFraud(status: string) {
    return status === 'Fraud';
  }
}

export default AgentVerificationUtil;
