import * as React from 'react';
import CopyToClipboard from './CopyToClipboard';
import './StatusIcon.scss';
import { uuidv4 } from './uuidv4';

interface IRenderListProps {
  fieldName: string;
  listText: any;
}

interface IDoc {
  tenantId: string;
  transactionId: string;
  Date: string;
  domain: string;
  Id: string;
}

export class RenderList extends React.Component<IRenderListProps> {
  public render() {
    const { fieldName } = this.props;

    return {
      domain: this.props.listText.map((doc: IDoc) => (
        <p key={uuidv4()}>
          {doc.domain} <CopyToClipboard textToCopy={doc.domain} />
        </p>
      )),
      domainFromDocuments: this.props.listText.map((doc: IDoc) => (
        <p key={uuidv4()}>
          {doc.Id} <CopyToClipboard textToCopy={doc.Id} />
        </p>
      )),
      tenantId: this.props.listText.map((doc: IDoc) => (
        <p key={uuidv4()}>
          {doc.tenantId} <CopyToClipboard textToCopy={doc.tenantId} />
        </p>
      )),
      tenantIdFromDocuments: this.props.listText.map((doc: IDoc) => (
        <p key={uuidv4()}>
          {doc.Id} <CopyToClipboard textToCopy={doc.Id} />
        </p>
      )),
      transactionId: this.props.listText.map((doc: IDoc) => (
        <p key={uuidv4()}>
          {doc.transactionId} <CopyToClipboard textToCopy={doc.transactionId} />
        </p>
      )),
      transactionIdsFromDocuments: sortedTransactions(this.props.listText).map((doc: IDoc) => (
        <p key={uuidv4()}>
          {doc.Id}
          <CopyToClipboard textToCopy={doc.Id} /> <br />
          <i>{doc.Date}</i>
        </p>
      ))
    }[fieldName];
  }
}

// custom comparator to sort the transactions by date. The latest transaction will be on top.
export function sortedTransactions(list: any): any {
  list.sort((a: any, b: any) => {
    if (a.Date > b.Date) {
      return -1;
    } else if (a.Date < b.Date) {
      return 1;
    }

    return 0;
  });

  return list;
}

export default RenderList;
