import * as React from 'react';
import { callApi } from '../../dataServices/SharedServiceFunctions';

export class WhoPage extends React.Component<{}, { [key: string]: any }> {
  constructor(props: {} | any) {
    super(props);
    this.state = { data: undefined, error: undefined };
  }

  public componentDidMount() {
    const whoUrl = process.env.REACT_APP_INTERNAL_API_BASEURL + '/auth/who';
    (async () => {
      const result = await callApi<{} | any>(whoUrl);
      this.setState({
        data: result.status === 200 ? result.value : null,
        error: result.status !== 200 ? result.error : null
      });
    })();
  }

  public render() {
    return (
      <section id="who-page">
        <pre>{this.state.data !== null && JSON.stringify(this.state.data, null, 2)}</pre>
        <pre>{this.state.data === null && JSON.stringify(this.state.error, null, 2)}</pre>
      </section>
    );
  }
}

export default WhoPage;
