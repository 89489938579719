import { Reducer } from 'redux';
import { AdminActionTypes, IAdminState } from './types';

// Type-safe initialState!
const initialState: IAdminState = {
  commercialLoading: false,
  commercialState: undefined,
  fraudByNonprofit: undefined,
  reprocessLoading: false,
  reprocessState: undefined,
  bulkReprocessRequestInProgress: false,
  bulkReprocessState: undefined,
  setFraudNonprofitLoader: undefined,
  setFraudTransactionLoader: undefined,
  azureRegistrationLinkingState: undefined,
  failedValues: undefined,
  successValues: undefined,
  VerifyAgentLoading: false,
  verifyAgentNonprofitState: undefined,
  outreachLoading: false,
  outreachNonprofitState: undefined,
  addCommentsInProgress: false,
  addCommentsState: undefined
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<IAdminState> = (state = initialState, action) => {
  switch (action.type) {
    case AdminActionTypes.SET_FRAUD_NONPROFIT_REQUEST: {
      return { ...state, setFraudNonprofitLoader: true };
    }
    case AdminActionTypes.SET_BULK_FRAUD_REQUEST: {
      return { ...state, setFraudNonprofitLoader: true };
    }
    case AdminActionTypes.SET_FRAUD_NONPROFIT_SUCCESS: {
      return { ...state, fraudByNonprofit: action.payload, setFraudNonprofitLoader: false };
    }
    case AdminActionTypes.FETCH_FRAUD_NONPROFIT_ERROR: {
      return {
        ...state,
        fraudByNonprofit: action.payload,
        setFraudNonprofitLoader: false
      };
    }
    case AdminActionTypes.SET_FRAUD_REASONCODE_REQUEST: {
      return { ...state, setFraudNonprofitLoader: true };
    }
    case AdminActionTypes.SET_FRAUD_REASONCODE_SUCCESS: {
      return { ...state, fraudByNonprofit: action.payload, setFraudNonprofitLoader: false };
    }
    case AdminActionTypes.FETCH_FRAUD_REASONCODE_ERROR: {
      return {
        ...state,
        fraudByNonprofit: action.payload,
        setFraudNonprofitLoader: false
      };
    }

    case AdminActionTypes.VERIFY_AGENT_REQUEST: {
      return { ...state, VerifyAgentLoading: true };
    }
    case AdminActionTypes.VERIFY_AGENT_SUCCESS: {
      return {
        ...state,
        verifyAgentNonprofitState: { setVerifyAgentSuccess: true, failureMessage: undefined },
        VerifyAgentLoading: false
      };
    }
    case AdminActionTypes.FETCH_VERIFY_AGENT_ERROR: {
      return {
        ...state,
        verifyAgentNonprofitState: { setVerifyAgentSuccess: false, failureMessage: action.payload },
        VerifyAgentLoading: false
      };
    }
    case AdminActionTypes.SET_FAILED_VALUE_TO_STATE: {
      return {
        ...state,
        failedValues: action.payload,
        setFraudNonprofitLoader: false,
        fraudByNonprofit: {
          setNonprofitFraudSuccess: true,
          failureMessage: undefined
        }
      };
    }
    case AdminActionTypes.SET_SUCCESS_VALUE_TO_STATE: {
      return {
        ...state,
        successValues: action.payload,
        setFraudNonprofitLoader: false,
        fraudByNonprofit: {
          setNonprofitFraudSuccess: true,
          failureMessage: undefined
        }
      };
    }
    case AdminActionTypes.LINK_COMMERCIAL_REQUEST: {
      return { ...state, commercialLoading: true };
    }
    case AdminActionTypes.LINK_COMMERCIAL_SUCCESS: {
      return { ...state, commercialState: action.payload, commercialLoading: false };
    }
    case AdminActionTypes.FETCH_LINK_COMMERCIAL_ERROR: {
      return {
        ...state,
        commercialState: { isCommmercialLinkingSuccess: false, failureMessage: action.payload },
        commercialLoading: false
      };
    }
    case AdminActionTypes.REPROCESS_REQUEST: {
      return { ...state, reprocessLoading: true };
    }
    case AdminActionTypes.REPROCESS_SUCCESS: {
      return { ...state, reprocessState: action.payload, reprocessLoading: false };
    }
    case AdminActionTypes.SEND_BULK_REQUEST: {
      return {
        ...state,
        bulkReprocessState: {
          isReprocessSuccessful: false,
          failureMessage: null,
          response: null
        },
        bulkReprocessRequestInProgress: true
      };
    }
    case AdminActionTypes.SEND_BULK_SUCCESS: {
      return {
        ...state,
        bulkReprocessState: {
          isReprocessSuccessful: true,
          failureMessage: null,
          response: action.payload
        },
        bulkReprocessRequestInProgress: false
      };
    }
    case AdminActionTypes.SEND_BULK_ERROR: {
      return {
        ...state,
        bulkReprocessState: {
          isReprocessSuccessful: false,
          failureMessage: action.payload,
          response: null
        },
        bulkReprocessRequestInProgress: false
      };
    }
    case AdminActionTypes.FETCH_REPROCESS_ERROR: {
      return {
        ...state,
        reprocessState: { isReprocessSuccessful: false, failureMessage: action.payload },
        reprocessLoading: false
      };
    }

    case AdminActionTypes.OUTREACH_REQUEST: {
      return { ...state, outreachLoading: true };
    }
    case AdminActionTypes.OUTREACH_SUCCESS: {
      return {
        ...state,
        outreachNonprofitState: {
          isOutreachSuccessful: true,
          failureMessage: undefined,
          outReachData: undefined,
          agentVerificationId: undefined
        },
        outreachLoading: false
      };
    }
    case AdminActionTypes.FETCH_OUTREACH_ERROR: {
      return {
        ...state,
        outreachNonprofitState: {
          isOutreachSuccessful: false,
          failureMessage: action.payload,
          outReachData: undefined,
          agentVerificationId: undefined
        },
        outreachLoading: false
      };
    }

    case AdminActionTypes.AZURE_REGISTRATION_LINK_REQUEST: {
      return {
        ...state,
        azureRegistrationLinkingState: { isLinkingInProgress: true, result: undefined, failureMessage: undefined }
      };
    }
    case AdminActionTypes.AZURE_REGISTRATION_LINK_SUCCESS: {
      return {
        ...state,
        azureRegistrationLinkingState: { isLinkingInProgress: false, result: true, failureMessage: undefined }
      };
    }
    case AdminActionTypes.AZURE_REGISTRATION_LINK_ERROR: {
      return {
        ...state,
        azureRegistrationLinkingState: { isLinkingInProgress: false, result: false, failureMessage: action.payload }
      };
    }
    case AdminActionTypes.ADD_COMMENTS_REQUEST: {
      return { ...state, addCommentsInProgress: true };
    }
    case AdminActionTypes.ADD_COMMENTS_SUCCESS: {
      return {
        ...state,
        addCommentsState: { addCommentsSuccess: true, failureMessage: undefined },
        addCommentsInProgress: false
      };
    }
    case AdminActionTypes.ADD_COMMENTS_ERROR: {
      return {
        ...state,
        addCommentsState: { addCommentsSuccess: false, failureMessage: action.payload },
        addCommentsInProgress: false
      };
    }
    case AdminActionTypes.RESET_STATE: {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.ts`
export { reducer as adminReducer };
