import * as msal from '@azure/msal-browser';
import * as jwt from 'jsonwebtoken';
import moment from 'moment';
import { logService } from 'npo-common';

export class AuthenticationService {
  private msalInstance: msal.PublicClientApplication;
  private msalToken!: string;
  private msalAccount!: msal.AccountInfo | null;

  private msalLoginRequest: msal.RedirectRequest = {
    scopes: [process.env.REACT_APP_AAD_CLIENT_ID + '/.default']
  };

  private msalSilentRequest: msal.SilentRequest = {
    scopes: [process.env.REACT_APP_AAD_CLIENT_ID + '/.default'],
    forceRefresh: false
  };

  constructor() {
    const msalConfig: msal.Configuration = {
      auth: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID ?? '',
        redirectUri: window.location.origin,
        authority: `${process.env.REACT_APP_AAD_LOGIN_INSTANCE}common`,
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    };

    this.msalInstance = new msal.PublicClientApplication(msalConfig);

    var accounts = this.msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      // TODO: Add account selection logic but just pick the first account for now.
      this.msalAccount = accounts[0];
    }
  }

  public login(): void {
    this.msalInstance.loginRedirect(this.msalLoginRequest);
  }

  public logout(): void {
    let account: msal.AccountInfo | undefined;
    if (this.msalAccount) {
      account = this.msalAccount;
    }

    const logoutRequest: msal.EndSessionRequest = { account };

    // Unfortunately, as per the issue filed here (https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2024)
    // MSAL requires user interaction (account selection) before clearing the session. This is not ideal, but they have closed the issue
    // and have no plans to address this.
    this.msalInstance.logoutRedirect(logoutRequest);
  }

  public handleLoginPromise(): Promise<boolean> {
    // If there is a cached login, use it
    if (this.msalAccount) {
      return Promise.resolve(true);
    }

    return this.msalInstance.handleRedirectPromise().then((tokenResponse: msal.AuthenticationResult | null) => {
      if (tokenResponse !== null) {
        this.msalToken = tokenResponse.accessToken;
        this.msalAccount = tokenResponse.account;
        return true;
      }
      return false;
    });
  }

  public isAuthenticated(): Promise<boolean> {
    return this.getToken().then((tokenResponse) => {
      return tokenResponse !== null;
    });
  }

  public getTokenExpirationInMinutes(token: string): number {
    if (!token) {
      return moment.duration(0).minutes();
    }
    const decodedToken = jwt.decode(token) as { [key: string]: any };
    if (!decodedToken.exp) {
      return moment.duration(0).minutes();
    }
    const expirationDate = new Date(decodedToken.exp * 1000);
    return moment.duration(moment(expirationDate).diff(new Date())).minutes();
  }

  public get accountInfo(): msal.AccountInfo | null {
    return this.msalAccount;
  }

  // This is only used for the initial auth reducer state. Use getToken() to acquire new tokens for every API call.
  public get cachedToken(): string {
    return this.msalToken;
  }

  public async getToken(): Promise<string | null> {
    try {
      if (this.msalAccount) {
        this.msalSilentRequest.account = this.msalAccount;
      }

      const response = await this.msalInstance.acquireTokenSilent(this.msalSilentRequest);
      this.msalToken = response.accessToken;
      this.msalAccount = response.account;

      return response.accessToken;
    } catch (e) {
      if (e instanceof msal.InteractionRequiredAuthError) {
        logService.logError(e.message, e);
        this.msalInstance.acquireTokenRedirect(this.msalLoginRequest);
      }

      return null;
    }
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
