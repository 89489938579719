import { Icon } from '@fluentui/react';
import copy from 'copy-to-clipboard';
import * as React from 'react';

interface ICopyProps {
  textToCopy: string;
}

export class CopyToClipboard extends React.Component<ICopyProps> {
  public copyTextToClipboard(text: string) {
    copy(text);
  }

  public render() {
    const { textToCopy } = this.props;
    if (textToCopy === '') {
      return <></>;
    }

    return (
      <button className="link-button" tabIndex={-1} onClick={() => this.copyTextToClipboard(textToCopy)}>
        <Icon id="copyIcon" iconName="Copy" />
      </button>
    );
  }
}

export default CopyToClipboard;
