export const enum SharedActionTypes {
  GETCOUNTRIES_REQUEST = '@@agentVerification/GETCOUNTRIES_REQUEST',
  GETCOUNTRIES_SUCCESS = '@@agentVerification/GETCOUNTRIES_SUCCESS',
  GETCOUNTRIES_ERROR = '@@agentVerification/GETCOUNTRIES_ERROR',
  SET_SESSION_ID = '@@shared/SET_SESSION_ID'
}
export interface SharedState {
  sessionId: string | undefined;
  countryDataList: CountryData[] | undefined;
}

export interface CountryState {
  countryDataList: CountryData[];
}
export interface CountryData {
  key: string;
  text: string;
}
