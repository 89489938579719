import { Reducer } from 'redux';
import { AgentStatuses, AgentVerificationActionTypes, AgentVerificationState } from './types';

const initialState: AgentVerificationState = {
  currentNonProfit: undefined,
  isLoading: undefined,
  results: [],
  agentVerificationInfo: undefined,
  newSearchResultsDataAdded: false
};

const reducer: Reducer<AgentVerificationState> = (state = initialState, action) => {
  switch (action.type) {
    case AgentVerificationActionTypes.AGENTVERIFICATION_REQUEST: {
      return { ...state, results: undefined, isLoading: true };
    }
    case AgentVerificationActionTypes.AGENTVERIFICATION_SUCCESS: {
      return { ...state, results: action.payload, isLoading: false };
    }
    case AgentVerificationActionTypes.AGENTVERIFICATION_ERROR: {
      return { ...state, isLoading: false };
    }
    case AgentVerificationActionTypes.SET_AGENTVERIFICATION_INFO: {
      return { ...state, agentVerificationInfo: action.payload };
    }
    case AgentVerificationActionTypes.SET_OUTREACH_STATUS: {
      const filteredResult = state.results
        ? state.results.find(
            (result: { agentData: { agentVerificationId: any } }) =>
              result.agentData.agentVerificationId === action.payload.agentVerificationId
          )
        : state.results;
      state.results !== null &&
        state.results !== undefined &&
        filteredResult !== undefined &&
        (filteredResult.agentData.agentStatus = AgentStatuses.Outreach) &&
        filteredResult.outreachData.push({
          outreachId: action.payload.outreachId,
          outreachUrl: action.payload.outreachUrl
        });
      return { ...state, newSearchResultsDataAdded: true };
    }
    case AgentVerificationActionTypes.SET_VERIFYAGENT_STATUS: {
      return {
        ...state,
        newSearchResultsDataAdded: true,
        results: state.results
          ? state.results.filter(
              (result: { agentData: { agentVerificationId: any } }) =>
                result.agentData.agentVerificationId !== action.payload
            )
          : state.results
      };
    }
    case AgentVerificationActionTypes.RESET_SEARCH_RESULT_DATA: {
      return { ...state, newSearchResultsDataAdded: false };
    }
    case AgentVerificationActionTypes.RESET_AGENT_DATA: {
      return { ...state, agentVerificationInfo: undefined };
    }
    case AgentVerificationActionTypes.AGENTFILTER_REQUEST: {
      return { ...state, isLoading: true };
    }
    case AgentVerificationActionTypes.AGENTFILTER_SUCCESS: {
      return {
        ...state,
        results: action.payload,
        isLoading: false,
        newSearchResultsDataAdded: true
      };
    }
    case AgentVerificationActionTypes.FETCH_AGENTFILTER_ERROR: {
      return {
        ...state,
        results: [],
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as agentVerificationReducer };
