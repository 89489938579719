import { CommandBarButton } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setNonProfitId } from '../../state/nonprofit/actions';
import { ISearchFormSubmission, searchRequest } from '../../state/search';
import ItemDetail from '../../components/search/ItemDetail';
import * as nonprofitActions from '../../state/nonprofit/actions';
import { nonprofitSearchRequest } from '../../state/nonprofit/actions';
import SearchForm from '../search/SearchForm';
import * as searchActions from '../../state/search/actions';
import './Details.scss';

interface IPropsFromDispatch {
  nonprofitSearchRequest: typeof nonprofitActions.nonprofitSearchRequest;
  setNonProfitId: (val: string) => void;
  searchRequest: typeof searchActions.searchRequest;
}

export type SearchProps = IPropsFromDispatch & RouteComponentProps & { match: any };

class Details extends React.Component<SearchProps> {
  public componentDidMount() {
    const { match } = this.props;

    const nonprofitId = match.params['nonprofitId'];
    this.props.setNonProfitId(nonprofitId);
    this.props.nonprofitSearchRequest(nonprofitId);
  }

  public render() {
    return (
      <section id="np-page">
        <SearchForm
          onSubmit={(values: ISearchFormSubmission) => this.submitForm(values)}
          initialValues={{
            query: ''
          }}
        />
        {this.checkHistory() === true ? (
          <div className="backToResult">
            <div style={{ display: 'flex', alignItems: 'stretch', height: '33px' }}>
              <CommandBarButton
                onClick={() => this.props.history.goBack()}
                iconProps={{ iconName: 'CaretLeft8' }}
                text="Back to Search"
              />
            </div>
          </div>
        ) : null}
        <ItemDetail />
      </section>
    );
  }

  private submitForm(values: ISearchFormSubmission) {
    this.props.history.push('/search/query=' + values.query);
    this.props.searchRequest(values);
  }

  private checkHistory() {
    if (window.history.length > 1) {
      return true;
    }
    return false;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  nonprofitSearchRequest: (value: string) => dispatch(nonprofitSearchRequest(value)),
  setNonProfitId: (val: string) => dispatch(setNonProfitId(val)),
  searchRequest: (formValues: ISearchFormSubmission) => dispatch(searchRequest(formValues))
});

export default connect(null, mapDispatchToProps)(Details);
