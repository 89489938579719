export class LocaleService {
  private currentLocale: string;

  constructor() {
    this.currentLocale = 'en-us';
  }

  public getLocaleContent(locale?: string): any {
    locale = locale || this.currentLocale;
    const content = require('../assets/content/' + locale + '.json');
    return content;
  }

  public getCategoryContent(category: string, locale?: string): { [key: string]: any } {
    const content = this.getLocaleContent(locale);

    const categoryContent = content[category];
    return categoryContent;
  }

  public getText(category: string, id: string, locale?: string): string | null {
    const categoryContent = this.getCategoryContent(category, locale);
    if (categoryContent === undefined) {
      return null;
    }

    return categoryContent[id];
  }
}

const localeService = new LocaleService();
export default localeService;
