import { DefaultButton, Dialog, DialogFooter, DialogType, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../state/index';
import { IAdminState } from '../../../state/admin';
import { resetState, setBulkFraudRequest } from '../../../state/admin/actions';
import Summary from '../search/Summary';

interface IPropsFromDispatch {
  setBulkFraudRequest: (val: string[]) => void;
  resetState: () => void;
}

export interface IFraudProps {
  hideDialog?: boolean;
  adminState: IAdminState;
  nonProfitIds: string[];
  showPanel?: boolean;
}

export type ISetFraudProps = IPropsFromDispatch & IFraudProps;

class SetBulkFraud extends React.Component<ISetFraudProps, { hideDialog: boolean; showPanel: boolean }> {
  constructor(props: ISetFraudProps) {
    super(props);
    this.state = {
      hideDialog: true,
      showPanel: true
    };
  }

  public render() {
    return (
      <div>
        <section id="fraudAction">
          {this.props.adminState.setFraudNonprofitLoader ||
          (this.props.adminState.fraudByNonprofit &&
            this.props.adminState.fraudByNonprofit.setNonprofitFraudSuccess !== undefined) ? (
            <Panel
              isOpen={this.state.showPanel}
              type={PanelType.medium}
              isLightDismiss={true}
              headerText="Admin Panel"
              isBlocking={true}
              hasCloseButton={false}
            >
              <Summary />
            </Panel>
          ) : null}
          <PrimaryButton secondaryText="Set Fraud" onClick={this.showDialog} text="Set Fraud" />

          <Dialog
            hidden={this.state.hideDialog}
            onDismiss={this.closeDialog}
            dialogContentProps={{
              subText: `You are about to set the selected nonprofit id's as Fraud. Do you really want to continue?`,
              title: 'Confirm your action!',
              type: DialogType.largeHeader
            }}
            modalProps={{
              containerClassName: 'ms-dialogMainOverride',
              isBlocking: false
            }}
          >
            <DialogFooter>
              <PrimaryButton onClick={this.setFraud} text="Yes" />
              <DefaultButton onClick={this.cancelAction} text="No" />
            </DialogFooter>
          </Dialog>
        </section>
      </div>
    );
  }

  private showDialog = (): void => {
    this.setState({ hideDialog: false });
  };

  private closeDialog = (): void => {
    this.setState({ hideDialog: true });
  };

  private setFraud = (): void => {
    this.props.resetState();
    this.props.setBulkFraudRequest(this.props.nonProfitIds);
    this.closeDialog();
  };

  private cancelAction = (): void => {
    this.closeDialog();
  };

  public setShowPanel = (showPanel: boolean): (() => void) => {
    return (): void => {
      this.setState({ showPanel });
    };
  };
}

const mapStateToProps = ({ admin, search }: IApplicationState) => ({
  adminState: admin,
  nonProfitIds: search.selectedNonProfitIds
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetState: () => dispatch(resetState()),
  setBulkFraudRequest: (values: string[]) => dispatch(setBulkFraudRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetBulkFraud);
