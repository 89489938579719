import * as React from "react";
import SupportViewBig from './SupportViewBig';
import SupportViewSmall from './SupportViewSmall';

const SupportView: React.FunctionComponent = () => (
    <section id="supportView">
        <div id="smallDisplay" className="ms-hiddenXxlUp">
            <SupportViewSmall />
        </div>
        <div id="bigDisplay" className="ms-hiddenXlDown">
            <SupportViewBig />
        </div>
    </section >
);

export default SupportView;