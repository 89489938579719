import { CommandBarButton, Panel, PanelType } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { resetAgentDataState } from '../../state/agentVerification/actions';
import AdminActions from '../admin/actions/AdminActions';
import './AdminView.scss';
import AdminViewBig from './AdminViewBig';
import AdminViewMedium from './AdminViewMedium';
import AdminViewSmall from './AdminViewSmall';

export interface IPropsFromDispatch {
  resetAgentDataState: typeof resetAgentDataState;
}

export class AdminView extends React.Component<
  IPropsFromDispatch,
  {
    showPanel: boolean;
  }
> {
  constructor(props: IPropsFromDispatch) {
    super(props);
    this.state = { showPanel: false };
  }

  public render(): JSX.Element {
    return (
      <section id="adminView">
        <div className="ms-Grid" id="admin-panel-button">
          <div style={{ display: 'flex', alignItems: 'stretch', height: '33px' }}>
            <CommandBarButton
              id="command-button"
              iconProps={{ iconName: 'Admin' }}
              secondaryText="Opens the Admin Panel"
              onClick={this.setShowPanel(true)}
              text="Open Admin Panel"
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div id="smallDisplay" className="ms-Grid-col ms-sm12 ms-hiddenXxlUp">
            <AdminViewSmall />
          </div>
          <div id="medDisplay" className="ms-Grid-col ms-sm12 mediumScreen ms-hiddenXlDown">
            <AdminViewMedium />
          </div>
          <div id="bigDisplay" className="ms-Grid-col ms-sm12 ms-hiddenXxlDown">
            <AdminViewBig />
          </div>
        </div>

        <Panel
          isOpen={this.state.showPanel}
          isLightDismiss={true}
          onDismiss={this.setShowPanel(false)}
          type={PanelType.medium}
          headerText="Admin Panel"
        >
          <AdminActions />
        </Panel>
      </section>
    );
  }

  private setShowPanel = (showPanel: boolean): (() => void) => {
    return (): void => {
      this.setState({ showPanel });
      this.props.resetAgentDataState();
    };
  };
}

const mapStateToDispatch = (dispatch: Dispatch) => ({
  resetAgentDataState: () => dispatch(resetAgentDataState())
});

export default connect(null, mapStateToDispatch)(AdminView);
