import * as React from "react";
import KeyValueText from '../renderDocuments/KeyValueText';
import { formatCityRegion } from '../util/Utilities';

interface ISearchProps {
    item: any
}

const SearchResult: React.FunctionComponent<ISearchProps> = ({ item }) => (
    <div className="sectionDetails">
        <div className="ms-Grid-row" id="rowData">
            <div className="ms-Grid-col ms-sm12 ms-md3">
                <KeyValueText keyText="Status" valueText={item.status ? item.status : "Unknown"} boldText={true} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4">
                <KeyValueText keyText="Effective Date" valueText={item.effectiveDateTime ? new Date(item.effectiveDateTime).toDateString() : ""} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md5">
                <KeyValueText keyText="Nonprofit Id" valueText={item.nonprofitId} />
            </div>
        </div>
        <div className="ms-Grid-row" id="rowData">
            <div className="ms-Grid-col ms-sm12 ms-md3">
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4">
                <KeyValueText keyText="Agent Email" valueText={item.agentEmail} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md5">
                <KeyValueText keyText="Tenant Id(s)" fieldName="tenantId" listText={item.officeProfiles.length > 0 ? item.officeProfiles : ""} />
            </div>
        </div>
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md3">
                <p>
                    {formatCityRegion(item)} <br />{item.nonprofitAddressCountryCode}
                </p>
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4">
                <KeyValueText keyText="Domain(s)" fieldName="domain" listText={item.officeProfiles.length > 0 ? item.officeProfiles : ""} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md5">
                <KeyValueText keyText="Transaction Id(s)" fieldName="transactionId" listText={item.transactions.length > 0 ? item.transactions : ""} />
            </div>
        </div>
    </div>
);

export default SearchResult;