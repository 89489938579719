import { Dialog, DialogType, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../state/index';
import { IAdminState, IAzureRegistrationLinkingFormSubmission } from '../../../state/admin';
import { azureRegistrationLinkRequest } from '../../../state/admin/actions';
import { SuccessAction } from '../SuccessAction';
import AzureRegistrationLinkForm from './AzureRegistrationLinkForm';

interface IPropsFromDispatch {
  AzureRegistrationLinking: (val: IAzureRegistrationLinkingFormSubmission) => void;
}

export interface IAzureProps {
  hideDialog?: boolean;
  adminState: IAdminState;
  nonprofitId: string;
}

export type IAzureRegistrationProps = IPropsFromDispatch & IAzureProps;

class AzureRegistrationLinking extends React.Component<
  IAzureRegistrationProps,
  {
    hideDialog: boolean;
  }
> {
  constructor(props: IAzureRegistrationProps) {
    super(props);
    this.state = {
      hideDialog: true
    };
  }

  public render() {
    return (
      <div>
        <section id="link-azure">
          {this.isLinkingInProgress() ? (
            <Spinner id="searchSpinner" size={SpinnerSize.medium} />
          ) : this.isLinkingSuccessful() ? (
            <SuccessAction />
          ) : this.isLinkingFailed() ? (
            <MessageBar
              actions={
                <div>
                  <PrimaryButton secondaryText="Link Azure" onClick={this.showDialog} text="Link Azure" />
                </div>
              }
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              {this.props.adminState.azureRegistrationLinkingState &&
                this.props.adminState.azureRegistrationLinkingState.failureMessage}{' '}
              Please try again.
            </MessageBar>
          ) : (
            <PrimaryButton secondaryText="Link Azure" onClick={this.showDialog} text="Link Azure" />
          )}
          <Dialog
            minWidth={400}
            hidden={this.state.hideDialog}
            onDismiss={this.closeDialog}
            dialogContentProps={{
              title: 'Link Azure Registration',
              type: DialogType.largeHeader
            }}
            modalProps={{
              containerClassName: 'ms-dialogMainOverride',
              isBlocking: false
            }}
          >
            <AzureRegistrationLinkForm
              initialValues={{ nonprofitId: this.props.nonprofitId }}
              onSubmit={(values: IAzureRegistrationLinkingFormSubmission) => this.submitForm(values)}
            />
          </Dialog>
        </section>
      </div>
    );
  }

  private isLinkingFailed() {
    return (
      this.props.adminState.azureRegistrationLinkingState && !this.props.adminState.azureRegistrationLinkingState.result
    );
  }
  private isLinkingSuccessful() {
    return (
      this.props.adminState.azureRegistrationLinkingState && this.props.adminState.azureRegistrationLinkingState.result
    );
  }

  private isLinkingInProgress() {
    return (
      this.props.adminState.azureRegistrationLinkingState &&
      this.props.adminState.azureRegistrationLinkingState.isLinkingInProgress
    );
  }

  private showDialog = (): void => {
    this.setState({ hideDialog: false });
  };

  private closeDialog = (): void => {
    this.setState({ hideDialog: true });
  };

  private submitForm = (values: IAzureRegistrationLinkingFormSubmission): void => {
    this.props.AzureRegistrationLinking(values);
    this.closeDialog();
  };
}

const mapStateToProps = ({ admin, nonprofit }: IApplicationState) => ({
  adminState: admin,
  nonprofitId: nonprofit.nonprofitId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  AzureRegistrationLinking: (value: IAzureRegistrationLinkingFormSubmission) =>
    dispatch(azureRegistrationLinkRequest(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(AzureRegistrationLinking);
