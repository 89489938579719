import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../state/index';
import { IAdditionalData, INonProfitSearchResult, INonprofitState, NonprofitDocumentEnum } from '../../state/nonprofit';
import { NormalizeData } from '../util/NormalizeData';
import { RenderDocuments } from './RenderDocuments';
import UpdateNonprofitData from './UpdateNonprofitData';

interface IRenderSortedDocumentsProps {
  documentEnum: NonprofitDocumentEnum;
  searchResults?: INonprofitState;
  nonprofitSearchResults?: INonProfitSearchResult;
  supportView?: boolean;
}

let sortedData: string[] = [];

export class RenderSortedDocuments extends React.Component<IRenderSortedDocumentsProps> {
  public items!: IAdditionalData;
  constructor(props: Readonly<IRenderSortedDocumentsProps>) {
    super(props);
    this.sortData(props.documentEnum);
  }

  public render() {
    this.sortData(this.props.documentEnum);

    return (
      <section id="render-documents">
        {this.items ? <UpdateNonprofitData item={this.items} /> : <></>}
        {this.props.supportView ? null : sortedData.length > 0 ? (
          <RenderDocuments documents={sortedData} documentEnum={this.props.documentEnum} />
        ) : (
          <p>No {this.props.documentEnum.valueOf()} found</p>
        )}
      </section>
    );
  }

  private sortData(documentEnum: NonprofitDocumentEnum): any {
    const tenants: { Id: any }[] = [];
    const domains: { Id: any }[] = [];
    const transactionIds: { Id: any; Date: any }[] = [];
    let agentEmail: string = '';
    let techSoupOrgId: string = '';
    let masterNonprofitId: string = '';
    let nonprofitName: string = '';
    let effectiveDateTime: string = '';
    let status: string = '';
    let activityCode: string = '';

    if (documentEnum === NonprofitDocumentEnum.HISTORY) {
      if (this.props.searchResults && this.props.searchResults.historyResults) {
        sortedData = NormalizeData.normalize(
          this.props.searchResults.historyResults,
          'description,lastUpdatedDate,lastUpdatedBy,',
          null,
          null
        );
      }
    } else if (documentEnum === NonprofitDocumentEnum.DOCUMENTS) {
      if (this.props.nonprofitSearchResults) {
        sortedData = NormalizeData.normalize(
          this.props.nonprofitSearchResults,
          'documentType,missionStatement,artifactUri,',
          null,
          'AdminActionDocument',
          (doc) => {
            if (doc.documentType === 'OfficeProfileDocument') {
              if (doc.tenantId) {
                tenants.push({ Id: doc.tenantId });
              }
              if (doc.domain) {
                domains.push({ Id: doc.domain });
              }
            } else if (doc.documentType === 'TechSoupTransactionDocument') {
              transactionIds.push({ Id: doc.transactionId, Date: doc.createdDate });
            } else if (doc.documentType === 'NonprofitDocument') {
              agentEmail = doc.agentEmail;
              masterNonprofitId = doc.masterNonprofitId;
              nonprofitName = doc.name;
              status = doc.status;
              effectiveDateTime = doc.effectiveDateTime;
              activityCode = doc.activityCode;
            } else if (doc.documentType === 'TechSoupProfileDocument') {
              techSoupOrgId = doc.techSoupOrgId;
            }
          }
        );
      }

      this.items = {
        domains,
        tenants,
        transactionIds,
        agentEmail,
        techSoupOrgId,
        masterNonprofitId,
        nonprofitName,
        status,
        effectiveDateTime,
        activityCode
      };
    } else {
      if (this.props.nonprofitSearchResults) {
        sortedData = NormalizeData.normalize(
          this.props.nonprofitSearchResults,
          'reason,documentType,message,lastUpdatedDate,lastUpdatedBy,',
          'AdminActionDocument',
          null
        );
      }
    }
  }
}

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  nonprofitSearchResults: nonprofit.nonprofitSearchResults,
  searchResults: nonprofit
});

export default connect(mapStateToProps)(RenderSortedDocuments);
