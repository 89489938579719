import { IApiResponse } from '../common/model/ApiResponse';
import { httpRequest } from '../common/utilities/HttpRequest';
import authenticationService from './AuthenticationService';

export async function callApi<T>(url: string, body?: string, method?: string): Promise<IApiResponse<T>> {
  const token = await authenticationService.getToken();
  const response = await httpRequest(url, token ?? undefined, body, method);
  return response.ok
    ? { status: response.status, value: await extractData<T>(response) }
    : { status: response.status, error: await extractError(response) };
}

export async function extractData<T>(response: Response): Promise<T | undefined> {
  try {
    return await response.json();
  } catch (e) {
    console.error('Error extracting response data to JSON.', e);
    return undefined;
  }
}

export async function extractError(response: Response): Promise<{ [key: string]: string } | string | undefined> {
  try {
    return await response.json();
  } catch (e) {
    try {
      console.error('Error extracting response error to JSON, falling back to text.', e);
      return await response.text();
    } catch (e) {
      console.error('Error extracting response error to text, falling back to statusText.', e);
      return await response.statusText;
    }
  }
}

export function customJsonStringify(values: any) {
  const UNSAFE_CHARS_REGEX = /[<>/]/g;
  return JSON.stringify(values).replace(UNSAFE_CHARS_REGEX, escapeUnsafeChars);
}

function escapeUnsafeChars(unsafeChar: string): string {
  const ESCAPED_CHARS = {
    '/': '\\x2f',
    '<': '\\x3c',
    '>': '\\x3e'
  };

  return ESCAPED_CHARS[unsafeChar as '/' | '<' | '>'];
}
