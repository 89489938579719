import { AccountInfo } from '@azure/msal-browser';
import { CommandButton, IContextualMenuProps } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import localeService from '../../dataServices/LocaleService';
import { RoleById } from 'src/common/utilities/evaluateUserAuthorization';
import { IApplicationState } from '../../state/index';
import Text from '../text/Text';
import UserPanel from '../user/UserPanel';
import './Header.scss';
import ReprocessModal from './menuActions/ReprocessModal';
import SignInCTA from './SignInCTA';

interface IPropsFromState {
  user: AccountInfo | null;
  isAuthenticatedBusinessAdmin: boolean;
}

interface IHeaderState {
  reprocessModalIsOpen: boolean;
}

export type IHeaderProps = IPropsFromState & RouteComponentProps;

export class Header extends React.Component<IHeaderProps, IHeaderState> {
  public constructor(props: IHeaderProps) {
    super(props);
    this.state = { reprocessModalIsOpen: false };
  }

  public render() {
    const { user, isAuthenticatedBusinessAdmin, location,  } = this.props;
    const userPanelHiddenForUrls: string[] = new Array<string>('/signin');
    const userPanelEnabled = location.pathname
      ? user && !userPanelHiddenForUrls.some((u) => location.pathname.indexOf(u) >= 0)
      : true;
    const adminMenuProps: IContextualMenuProps = {
      items: [
        {
          key: 'eligibilityReprocess',
          text: localeService.getText('Header', 'ReprocessAction') ?? undefined,
          onClick: () => this.setState({ reprocessModalIsOpen: true })
        }
      ]
    };

    return (
      <>
        <header>
          <h1>
            <Link to="/">
              <Text category="Header" id="NpSupportApp" />
            </Link>
          </h1>
          {
            isAuthenticatedBusinessAdmin && <CommandButton
              className="navigation-item-menu"
              text={localeService.getText("Header", "BulkActions") ?? undefined}
              menuProps={adminMenuProps}
            />
          }
          {userPanelEnabled ? <UserPanel /> : <SignInCTA />}
        </header>
        <ReprocessModal
          isOpen={this.state.reprocessModalIsOpen}
          onDismiss={() => this.setState({ reprocessModalIsOpen: false })}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth }: IApplicationState) => ({
  user: auth.user,
  isAuthenticatedBusinessAdmin: auth.isAuthenticated
    && auth.authorizationStatus !== null
    && auth.authorizationStatus !== undefined
    && (auth.authorizationStatus.whoIs === RoleById._3 || auth.authorizationStatus.whoIs === RoleById._2)
});

export default withRouter(connect(mapStateToProps)(Header));
