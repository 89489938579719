import * as React from "react";
import BusinessAdminBig from './BusinessAdminBig';
import BusinessAdminSmall from './BusinessAdminSmall';

const BusinessAdminView: React.FunctionComponent = () => (
    <section id="businessAdminView">
        <div id="smallDisplay" className="ms-hiddenXxlUp">
            <BusinessAdminSmall />
        </div>
        <div id="bigDisplay" className="ms-hiddenXlDown">
            <BusinessAdminBig />
        </div>
    </section >
);

export default BusinessAdminView;