import {
  ColumnActionsMode,
  CommandBarButton,
  ContextualMenu,
  ContextualMenuItemType,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  DirectionalHint,
  getTheme,
  IColumn,
  IContextualMenuProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IGroup,
  Panel,
  PanelType,
  SelectionMode
} from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { resetState } from '../../state/admin/actions';
import { AgentData, resetSearchResultData, setAgentVerificationId } from '../../state/agentVerification';
import { IAdditionalData } from '../../state/nonprofit';
import { setAdditionalNonprofitInfo, setNonProfitId } from '../../state/nonprofit/actions';
import { ISearchResult, ISearchResultAccumulator } from '../../state/search';
import AdminActions from '../admin/actions/AdminActions';
import CopyToClipboard from '../util/CopyToClipboard';
import StatusIcon from '../util/StatusIcon';
import { formatCityRegion } from '../util/Utilities';
import './RenderResultGrid.scss';

const theme = getTheme();

interface ISearchProps {
  searchResults?: ISearchResult[];
  columnsHeaders: IColumn[];
  newSearchResultsDataAdded?: boolean;
  verifyAgentSuccess?: boolean;
}

interface IGridProps {
  contextualMenuProps?: IContextualMenuProps;
  groups?: IGroup[];
  currentGroupedColumn?: string;
  showPanel?: boolean;
}

interface IPropsFromDispatch {
  setNonProfitId: typeof setNonProfitId;
  setAdditionalNonprofitInfo: typeof setAdditionalNonprofitInfo;
  setAgentVerificationId: typeof setAgentVerificationId;
  resetState?: typeof resetState;
  resetSearchResultData?: typeof resetSearchResultData;
}

export type SearchListProps = IPropsFromDispatch & ISearchProps & IGridProps;

enum keys {
  name = 'name',
  status = 'status',
  email = 'agentEmail',
  agentVerificationStatus = 'agentVerificationStatus',
  country = 'country',
  nonprofitId = 'nonprofitId',
  tenantId = 'tenantId',
  date = 'date',
  address = 'address',
  domain = 'domain',
  transactionId = 'transactionId',
  action = 'action',
  website = 'website',
  outreachUrl = 'outreachUrl'
}

let sortedItems: ISearchResult[];
let tableColumns: IColumn[];

export class RenderResultGrid extends React.Component<SearchListProps, IGridProps> {
  public static sort(currColumn: IColumn, items: ISearchResult[], isSortedDescending?: boolean) {
    // This will put all nulls at the end of the list depending on sorting
    const replacement = isSortedDescending ? '' : '~';
    // This is getting the property from the object itself.
    // This means that for fields like address or tenantId that do NOT
    // have a direct map to a particular field, they are not able to be sorted
    // This was the easiest way to sort the most columns in a clean fashion
    // However, in order for the currently unsortable columns to be sorted,
    // there must be a change to this.
    const key = currColumn.fieldName! as keyof ISearchResult;

    if (currColumn.data.isSortable) {
      // Sort the items
      items.sort((a, b) =>
        // Don't allow nulls or empty strings, Convert to same case for sorting, use replacement character for null
        (a[key] != null && (a[key] || '').toString().length > 0
          ? (a[key] || '').toString().toLowerCase()
          : replacement) <
        // descending sort
        (b[key] != null && (b[key] || '').toString().length > 0 ? (b[key] || '').toString().toLowerCase() : replacement)
          ? 1
          : -1
      );

      // if the list is currently ascending, reverse it
      if (!isSortedDescending) {
        items.reverse();
      }
    }
  }

  constructor(props: SearchListProps) {
    super(props);
    const { searchResults, columnsHeaders } = this.props;
    sortedItems = searchResults ?? [];
    this.state = {
      contextualMenuProps: undefined,
      groups: undefined,
      currentGroupedColumn: undefined,
      showPanel: false
    };
    tableColumns = columnsHeaders;
    this.renderItemColumn = this.renderItemColumn.bind(this);
  }

  componentDidUpdate() {
    if (this.props.newSearchResultsDataAdded) {
      sortedItems = this.props.searchResults ?? [];
      if (this.props.resetSearchResultData) {
        this.props.resetSearchResultData();
      }
      if (this.props.verifyAgentSuccess) setTimeout(() => this.setState({ showPanel: false }), 2000);
    }
  }
  public render() {
    const { contextualMenuProps, groups, showPanel } = this.state;

    return (
      <section id="details-list-grid">
        <DetailsList
          items={sortedItems}
          setKey="set"
          columns={tableColumns}
          onRenderItemColumn={this.renderItemColumn}
          onColumnHeaderClick={this.onColumnClick}
          onRenderRow={this.onRenderRow}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          compact={true}
          groups={groups}
        />

        {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}

        <Panel
          isOpen={showPanel}
          isLightDismiss={true}
          onDismiss={this.setShowPanel(false)}
          type={PanelType.medium}
          headerText="Admin Panel"
        >
          <AdminActions />
        </Panel>
      </section>
    );
  }

  private onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element => {
    if (props === undefined) {
      return <></>;
    }

    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props.itemIndex % 2 !== 0) {
      // Every other row renders with a different background color
      customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
    }

    return <DetailsRow {...props} styles={customStyles} />;
  };

  private renderItemColumn(
    item: ISearchResult,
    index: number | undefined,
    column: IColumn | undefined
  ): React.ReactNode {
    if (column === undefined) {
      return <></>;
    }

    switch (column.key) {
      case keys.name:
        return (
          <section id="status">
            <StatusIcon status={item.status} />
            <p className="value-content">
              <Link to={`/search/nonprofit/${item.nonprofitId}`}>{item.nonprofitName || item.techSoupName} </Link>
              <CopyToClipboard textToCopy={item.nonprofitName || item.techSoupName} />
            </p>
          </section>
        );

      case keys.email:
        return item.agentEmail ? (
          <p className="value-content">
            {item.agentEmail} <CopyToClipboard textToCopy={item.agentEmail} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.agentVerificationStatus:
        return item.agentVerificationStatus ? (
          <p className="value-content">{item.agentVerificationStatus}</p>
        ) : (
          <p>Unknown</p>
        );

      case keys.status:
        return item.status ? (
          <p className="value-content">
            {item.status} <CopyToClipboard textToCopy={item.status} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.date:
        return item.effectiveDateTime ? (
          <p className="value-content">
            {new Date(item.effectiveDateTime).toLocaleString()}{' '}
            <CopyToClipboard textToCopy={new Date(item.effectiveDateTime).toLocaleString()} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.address:
        return formatCityRegion(item).length > 0 ? (
          <p className="value-content">
            {formatCityRegion(item)} <CopyToClipboard textToCopy={formatCityRegion(item)} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.country:
        return item.nonprofitAddressCountryCode ? (
          <p className="value-content">
            {item.nonprofitAddressCountryCode} <CopyToClipboard textToCopy={item.nonprofitAddressCountryCode} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.action:
        return item.agentVerificationStatus ? (
          <CommandBarButton
            className="command-button"
            iconProps={{ iconName: 'Admin' }}
            secondaryText="Opens the Admin Panel"
            onClick={this.setShowPanel(true, item)}
            text="Open Admin Panel"
          />
        ) : (
          <p>Unknown</p>
        );

      case keys.website:
        return item.website ? (
          <p className="value-content">
            {item.website} <CopyToClipboard textToCopy={item.website} />
          </p>
        ) : (
          <p>Unknown</p>
        );

      case keys.outreachUrl:
        return item.outreachData && item.outreachData.length > 0 ? (
          <span>
            {item.outreachData.map((item, index) => (
              <p key={index} className="value-content">
                <a href={item.outreachUrl} target="_blank" rel="noopener noreferrer">
                  {item.outreachId}
                </a>
              </p>
            ))}
          </span>
        ) : (
          <p></p>
        );

      default:
        return null;
    }
  }

  private setShowPanel = (showPanel: boolean, item?: ISearchResult): (() => void) => {
    return (): void => {
      if (showPanel && item !== undefined) {
        const val = {
          agentVerificationId: item.agentVerificationId,
          agentEmail: item.agentEmail,
          agentStatus: item.agentVerificationStatus
        } as AgentData;
        this.props.setAgentVerificationId(val);
        this.props.setNonProfitId(item.nonprofitId);
        const additionalData = {} as IAdditionalData;
        additionalData.status = item.status;
        this.props.setAdditionalNonprofitInfo(additionalData);
      }
      this.setState({ showPanel: showPanel });
      if (this.props.resetState) {
        this.props.resetState();
      }
    };
  };

  private onColumnClick = (event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void => {
    if (event === undefined || column === undefined) {
      return;
    }

    if (column.columnActionsMode !== ColumnActionsMode.disabled && column.data.isSortable) {
      this.setState({
        contextualMenuProps: this.getContextualMenuProps(event, column)
      });
    }
  };

  private sortAndForceUpdate(column: IColumn, isSortedDescending: boolean) {
    // Ungroup and sort
    this.clearGroups();

    const newColumns: IColumn[] = tableColumns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

    // don't sort the unsortable columns
    if (column.data.isSortable) {
      newColumns.forEach((newCol: IColumn) => {
        if (newCol.key === currColumn.key) {
          currColumn.isSortedDescending = isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = false;
        }
      });
      // update the columns
      tableColumns = newColumns;
    }

    RenderResultGrid.sort(column, sortedItems, isSortedDescending);
    this.forceUpdate();
  }

  private getContextualMenuProps(ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps {
    const items = [
      {
        key: 'sort-header',
        itemType: ContextualMenuItemType.Header,
        text: 'Sort',
        itemProps: {
          lang: 'en-us'
        }
      },
      {
        key: 'aToZ',
        name: column.key === keys.date ? 'Oldest to New' : 'A to Z',
        iconProps: { iconName: 'SortUp' },
        canCheck: true,
        checked: column.isSorted && !column.isSortedDescending,
        onClick: () =>
          (column.data.isGrouped || !(column.isSorted && !column.isSortedDescending)) &&
          this.sortAndForceUpdate(column, false)
      },
      {
        key: 'zToA',
        name: column.key === keys.date ? 'Newest to oldest' : 'Z to A',
        iconProps: { iconName: 'SortDown' },
        canCheck: true,
        checked: column.isSorted && column.isSortedDescending,
        onClick: () =>
          (column.data.isGrouped || !(column.isSorted && column.isSortedDescending)) &&
          this.sortAndForceUpdate(column, true)
      }
    ];

    if (this.isGroupable(column)) {
      items.push({
        key: 'group-header',
        itemType: ContextualMenuItemType.Header,
        text: 'Group',
        itemProps: {
          lang: 'en-us'
        }
      });

      items.push({
        key: 'groupBy',
        name: 'By ' + column.name,
        iconProps: { iconName: 'GroupedDescending' },
        canCheck: true,
        checked: column.data.isGrouped,
        onClick: () => this.groupBy(column)
      });
    }

    return {
      items,
      target: ev.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 0,
      isBeakVisible: true,
      beakWidth: 10,
      onDismiss: this.onContextualMenuDismissed
    };
  }

  private isGroupable(column: IColumn) {
    return column.data.isGroupable;
  }

  private onContextualMenuDismissed = (): void => {
    this.setState({
      contextualMenuProps: undefined
    });
  };

  private clearGroups() {
    const currentGroupedColumn = this.state.currentGroupedColumn;
    if (currentGroupedColumn !== undefined) {
      const currentCol = tableColumns.filter((currCol) => currentGroupedColumn === currCol.key)[0];
      currentCol.data.isGrouped = false;
    }

    this.setState({ currentGroupedColumn: undefined, groups: undefined });
  }

  private groupBy(column: IColumn) {
    if (column.data.isGrouped) {
      this.clearGroups();
      return;
    }

    // sort(which also ungroups - if it is grouped), and then group
    this.sortAndForceUpdate(column, false);

    const groups = sortedItems.reduce((currentGroups: ISearchResultAccumulator[], currentItem, index) => {
      const previousGroup = currentGroups[currentGroups.length - 1];
      const fieldValue = column.fieldName ? currentItem[column.fieldName as keyof ISearchResult] : null;

      if (!previousGroup || previousGroup.value !== fieldValue) {
        currentGroups.push({
          key: 'group' + fieldValue + index,
          name: `${fieldValue === null ? 'Unknown' : fieldValue}`,
          value: fieldValue,
          startIndex: index,
          level: 0,
          count: 0
        });
      }

      if (previousGroup) {
        previousGroup.count = index - previousGroup.startIndex;
      }

      return currentGroups;
    }, []);

    // Update last group count
    const lastGroupItem = groups[groups.length - 1];

    if (lastGroupItem) {
      lastGroupItem.count = sortedItems.length - lastGroupItem.startIndex;
    }

    this.setState({
      groups,
      currentGroupedColumn: column.key
    });

    column.data.isGrouped = true;
  }
}

const mapStateToDispatch = (dispatch: Dispatch) => ({
  setNonProfitId: (val: string) => dispatch(setNonProfitId(val)),
  setAdditionalNonprofitInfo: (val: IAdditionalData) => dispatch(setAdditionalNonprofitInfo(val)),
  setAgentVerificationId: (val: AgentData) => dispatch(setAgentVerificationId(val)),
  resetState: () => dispatch(resetState()),
  resetSearchResultData: () => dispatch(resetSearchResultData())
});

export default connect(null, mapStateToDispatch)(RenderResultGrid);
