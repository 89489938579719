import { Icon } from '@fluentui/react';
import moment from 'moment';
import * as React from 'react';
import { NonprofitDocumentEnum } from '../../state/nonprofit';
import { uuidv4 } from '../util/uuidv4';
import KeyValueText from './KeyValueText';
import './RenderDocuments.scss';

class RenderDocument extends React.Component<any> {
  render(): any {
    const docId = uuidv4();
    return (
      <section id="documentDisplay" key={uuidv4()}>
        <div id="documentDescription" className="ms-Grid-row" onClick={() => this.toggleVisibility(docId)}>
          <div className="ms-Grid-col ms-sm12 ms-md5 ms-lg8">
            <p className="docTitle">
              <Icon
                iconName={
                  this.props.documentEnum === NonprofitDocumentEnum.DOCUMENTS
                    ? 'Document'
                    : this.props.documentEnum === NonprofitDocumentEnum.HISTORY
                    ? 'LocationDot'
                    : 'FabricOpenFolderHorizontal'
                }
              />{' '}
              {this.props.doc.statusDescription || this.props.doc.reason || this.props.doc.documentType}
            </p>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg4">
            <p>{moment(this.props.doc.lastUpdatedDate || this.props.doc.createdDate).fromNow()}</p>
          </div>
        </div>
        <div className="documentRegion" id={docId}>
          {Object.keys(this.props.doc).map((key) =>
            !key.startsWith('_') && key !== 'Fields' ? (
              <KeyValueText key={uuidv4()} keyText={key} valueText={this.getValueField(this.props.doc[key])} />
            ) : null
          )}
        </div>
      </section>
    );
  }

  private getValueField = (value: any): string | undefined => {
    if (value === null || value === undefined) {
      return undefined;
    }

    return typeof value === 'object'
      ? JSON.stringify(value)
      : value.toString();
  };

  private toggleVisibility = (id: string) => {
    const e = document.getElementById(id);
    if (!e) {
      return;
    }

    e.style.display = e.style.display === 'block' ? 'none' : 'block';
  };
}

export default React.memo(RenderDocument, (prevProps, nextProps) => {
  return prevProps.doc === nextProps.doc;
});
