import { all, apply, fork, put, takeEvery } from 'redux-saga/effects';
import authenticationService from '../../dataServices/AuthenticationService';
import authorizationService from '../../dataServices/AuthorizationService';
import { evaluateUserAuthorization } from '../../common/utilities/evaluateUserAuthorization';
import { setAuthStatus } from './actions';
import { AuthActionTypes } from './types';

export function* handleInitiateLogin() {
  const isAuthenticated: boolean = yield apply(authenticationService, authenticationService.isAuthenticated, []);
  if (isAuthenticated) {
    yield put(
      setAuthStatus({ isAuthenticated: true, user: authenticationService.accountInfo, authorizationStatus: undefined })
    );
  } else {
    yield put(setAuthStatus({ isAuthenticated: false, user: null, authorizationStatus: undefined }));
    yield apply(authenticationService, authenticationService.login, []);
  }
}

export function* handleInitiateLogout() {
  yield apply(authenticationService, authenticationService.logout, []);
}

function* handleProcessLoginPromise() {
  const handleCallbackResponse: boolean = yield apply(
    authenticationService,
    authenticationService.handleLoginPromise,
    []
  );
  if (handleCallbackResponse) {
    const adminResponse: boolean = yield apply(authorizationService, authorizationService.isAdmin, []);
    const businessAdminResponse: boolean = yield apply(authorizationService, authorizationService.isBusinessAdmin, []);
    const supportResponse: boolean = yield apply(authorizationService, authorizationService.checkAuthorization, []);

    const { isSupportResponse, isAdminResponse, isBusinessAdminResponse } = yield all({
      isAdminResponse: adminResponse,
      isBusinessAdminResponse: businessAdminResponse,
      isSupportResponse: supportResponse
    });
    const authorizationValue = evaluateUserAuthorization(isAdminResponse, isBusinessAdminResponse, isSupportResponse);
    yield put(
      setAuthStatus({
        isAuthenticated: true,
        user: authenticationService.accountInfo,
        authorizationStatus: { whoIs: authorizationValue }
      })
    );
  } else {
    yield put(setAuthStatus({ isAuthenticated: false, user: null, authorizationStatus: undefined }));
  }
}

function* watchInitiateLogin() {
  yield takeEvery(AuthActionTypes.INITIATE_LOGIN, handleInitiateLogin);
}

function* watchInitiateLogout() {
  yield takeEvery(AuthActionTypes.INITIATE_LOGOUT, handleInitiateLogout);
}

function* watchProcessLoginPromise() {
  yield takeEvery(AuthActionTypes.PROCESS_LOGIN_CALLBACK, handleProcessLoginPromise);
}

function* authSaga() {
  yield all([fork(watchInitiateLogin), fork(watchInitiateLogout), fork(watchProcessLoginPromise)]);
}

export default authSaga;
