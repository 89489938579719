import { all, apply, fork, put, takeLatest } from 'redux-saga/effects';
import { IApiResponse } from '../../common/model/ApiResponse';
import featureFlagsService from '../../dataServices/FeatureFlagsService';
import { setFlags, setIsLoading } from './actions';
import { FeatureFlagsActionTypes, FeatureFlagsState } from './types';

export function* handleFetchFlags() {
  try {
    yield put(setIsLoading(true));
    const response: IApiResponse<FeatureFlagsState> = yield apply(
      featureFlagsService,
      featureFlagsService.GetFeatureFlags,
      []
    );
    yield put(setFlags(response.value));
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsLoading(false));
  }
}

function* watchFetchFlags() {
  yield takeLatest(FeatureFlagsActionTypes.FETCH_FLAGS, handleFetchFlags);
}

function* featureFlagsSaga() {
  yield all([fork(watchFetchFlags)]);
}

export default featureFlagsSaga;
