import * as React from "react";
import Documents from '../details/documents/Documents';
import History from '../details/history/History';
import NonprofitDetail from '../details/nonprofit/NonprofitDetail';

const SupportViewSmall: React.FunctionComponent = () => (
    <section id="supportViewBig">
        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
            <NonprofitDetail />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
            <div className="tile-content"><History /></div>
        </div>
        <Documents supportView={true} />
    </section >
);

export default SupportViewSmall;