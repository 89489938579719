import { Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../state/index';
import { IAdminState } from '../../../state/admin';
import '../../details/Details.scss';
import Result from './Result';

export interface IFraudProps {
  adminState: IAdminState;
}
class Summary extends React.Component<IFraudProps> {
  public render() {
    return (
      <section id="success-action">
        {this.props.adminState.setFraudNonprofitLoader === true ? (
          <Spinner id="searchSpinner" size={SpinnerSize.medium} />
        ) : (
          this.props.adminState.fraudByNonprofit &&
          this.props.adminState.fraudByNonprofit.setNonprofitFraudSuccess === true &&
          (this.props.adminState.successValues !== undefined || this.props.adminState.failedValues !== undefined) && (
            <Result />
          )
        )}
      </section>
    );
  }
}
const mapStateToProps = ({ admin }: IApplicationState) => ({
  adminState: admin
});
export default connect(mapStateToProps)(Summary);
