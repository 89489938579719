export function getFields(item: {
    [x: string]: any;
}, exclude: string) {
    const result = [];
    if (item) {
        for (const key in item) {
            if (key !== exclude && exclude.indexOf(key + ",") === -1 && !key.startsWith("_")) {
                const val = item[key];
                result.push({ Name: key, Value: val });
            }
        }
    }
    return result;
}
