import { Reducer } from 'redux';
import authenticationService from '../../dataServices/AuthenticationService';
import { AuthActionTypes, IAuthState } from './types';

// Type-safe initialState!
const initialState: IAuthState = {
  authorizationStatus: null,
  isAuthenticated: authenticationService.cachedToken !== null ? true : false,
  user: authenticationService.accountInfo
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<IAuthState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.SET_AUTH_STATUS: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        authorizationStatus: action.payload.authorizationStatus
      };
    }
    case AuthActionTypes.SET_AUTHORIZATION_DETAIL: {
      return { ...state, authorizationStatus: action.payload };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.ts`
export { reducer as authReducer };
