import * as React from 'react';
import { getFields } from './getFields';

export class NormalizeData extends React.Component {
  public static normalize(
    oldDocs: { [x: string]: any },
    exclude: string,
    docTypeInclusive: string | null,
    docTypeExclusive: string | null,
    fn?: { (doc: any): void; (arg0: any): void }
  ) {
    const docs: any[] = [];

    for (const docType in oldDocs) {
      if ((!docTypeInclusive || docTypeInclusive === docType) && (!docTypeExclusive || docTypeExclusive !== docType)) {
        const list = oldDocs[docType];

        if (Array.isArray(list)) {
          list.forEach((doc) => {
            doc.Fields = getFields(doc, exclude);
            docs.push(doc);
            if (fn) {
              fn(doc);
            }
          });
        }
      }
    }

    docs.sort((a, b) => {
      const aDate = a.escalationDateTime || a.lastUpdatedDate || a.createdDate;
      const bDate = b.escalationDateTime || b.lastUpdatedDate || b.createdDate;

      if (aDate === bDate) {
        return 0;
      }

      return aDate < bDate ? 1 : -1;
    });

    return docs;
  }

  public render() {
    return null;
  }
}

export default NormalizeData;
