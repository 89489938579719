import { IApiResponse } from '../common/model/ApiResponse';
import { httpRequest } from '../common/utilities/HttpRequest';
import { AgentVerificationResponse, IFilterFormSubmission } from '../state/agentVerification';
import authenticationService from './AuthenticationService';

function generateQuery(data: string[]) {
  const query = [];
  for (let d in data) query.push(encodeURIComponent('AgentStatuses') + '=' + encodeURIComponent(data[d]));
  return query.join('&');
}

export class AgentVerificationService {
  private API_URL = process.env.REACT_APP_AGENT_VERIFICATION_API_BASEURL;
  public async getAgentVerificationResults(
    agentStatuses: string[]
  ): Promise<IApiResponse<AgentVerificationResponse[]>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(
      `${this.API_URL}/agentverificationrequests?${generateQuery(agentStatuses)}`,
      token ?? undefined
    );
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }

  public async getAgentVerificationRequestsByFilter(
    input: IFilterFormSubmission
  ): Promise<IApiResponse<AgentVerificationResponse[]>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(
      `${this.API_URL}/agentverificationrequests/filter`,
      token ?? undefined,
      JSON.stringify({
        AgentStatuses: input.AgentStatuses,
        Countries: input.Countries,
        NonprofitName: input.NonprofitName,
        NonprofitStatuses: input.NonprofitStatuses
      }),
      'POST'
    );
    if (response.ok) {
      return { status: response.status, value: await response.json() };
    } else {
      throw new Error(response.statusText);
    }
  }
}

const agentVerificationService = new AgentVerificationService();
export default agentVerificationService;
