import { FocusZone, FocusZoneDirection, IColumn, mergeStyleSets, MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { resetState } from '../../state/admin/actions';
import { AgentVerificationState, resetSearchResultData, setAgentVerificationId } from '../../state/agentVerification';
import { setAdditionalNonprofitInfo, setNonProfitId } from '../../state/nonprofit/actions';
import { ISearchResult } from '../../state/search';
import { RenderResultGrid } from '../search/RenderResultGrid';
import '../search/SearchSummary.scss';
import AgentVerificationUtil from '../util/AgentVerificationUtil';

interface IPropsFromDispatch {
  setNonProfitId: typeof setNonProfitId;
  setAdditionalNonprofitInfo: typeof setAdditionalNonprofitInfo;
  setAgentVerificationId: typeof setAgentVerificationId;
  resetState?: typeof resetState;
  resetSearchResultData?: typeof resetSearchResultData;
}

interface IAgentVerificationProps {
  agentVerificationResults?: AgentVerificationState;
  verifyAgentSuccess?: boolean;
}

export type AgentVerificationListProps = IPropsFromDispatch & IAgentVerificationProps;

const headerStyle = mergeStyleSets({
  boldHeader: {
    fontWeight: 'bold'
  }
});

enum keys {
  name = 'name',
  status = 'status',
  email = 'agentEmail',
  agentVerificationStatus = 'agentVerificationStatus',
  country = 'country',
  nonprofitId = 'nonprofitId',
  tenantId = 'tenantId',
  date = 'date',
  address = 'address',
  domain = 'domain',
  transactionId = 'transactionId',
  action = 'action',
  website = 'website',
  outreachUrl = 'outreachUrl'
}

const generatedColumns: IColumn[] = [
  {
    key: keys.name,
    name: 'Name',
    fieldName: 'nonprofitName',
    minWidth: 200,
    maxWidth: 440,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true }
  },
  {
    key: keys.status,
    name: 'Status',
    fieldName: 'status',
    minWidth: 70,
    maxWidth: 100,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true, isGroupable: true, isGrouped: false }
  },
  {
    key: keys.email,
    name: 'Agent Email',
    fieldName: 'agentEmail',
    minWidth: 180,
    maxWidth: 230,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true }
  },
  {
    key: keys.website,
    name: 'Website',
    fieldName: 'website',
    minWidth: 180,
    maxWidth: 230,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true }
  },
  {
    key: keys.outreachUrl,
    name: 'Outreach Url',
    fieldName: 'OutreachUrl',
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: false },
    isMultiline: true
  },
  {
    key: keys.agentVerificationStatus,
    name: 'Agent Verified',
    fieldName: 'agentVerificationStatus',
    minWidth: 70,
    maxWidth: 100,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: false }
  },
  {
    key: keys.address,
    name: 'Address',
    fieldName: 'nonprofitAddressStateRegion',
    minWidth: 200,
    maxWidth: 250,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: false }
  },
  {
    key: keys.country,
    name: 'Country',
    fieldName: 'nonprofitAddressCountryCode',
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true, isGroupable: true, isGrouped: false }
  },
  {
    key: keys.date,
    name: 'Effective Date',
    fieldName: 'effectiveDateTime',
    minWidth: 110,
    maxWidth: 175,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: true }
  },
  {
    key: keys.action,
    name: 'Action',
    fieldName: '',
    minWidth: 70,
    maxWidth: 100,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
    headerClassName: headerStyle.boldHeader,
    data: { isSortable: false }
  }
];

export class AgentSummary extends React.Component<AgentVerificationListProps> {
  public render() {
    const { agentVerificationResults } = this.props;
    var resultList: ISearchResult[];
    resultList = [];
    agentVerificationResults &&
      agentVerificationResults.results !== undefined &&
      agentVerificationResults.results.forEach((element) => {
        if (
          AgentVerificationUtil.isUnverifiedAgentStatus(element.agentData.agentStatus, element.nonprofitData.status)
        ) {
          resultList.push({
            id: null,
            agentEmail: element.agentData.agentEmail,
            effectiveDateTime: element.nonprofitData.effectiveDate,
            nonprofitAddressCity: element.nonprofitData.city,
            nonprofitAddressCountryCode: element.nonprofitData.country,
            nonprofitAddressStateRegion: null,
            nonprofitId: element.nonprofitData.nonprofitId,
            masterNonprofitId: null,
            nonprofitName: element.nonprofitData.nonprofitName,
            status: element.nonprofitData.status,
            techSoupName: null,
            techSoupOrgId: null,
            phone: null,
            email: null,
            website: element.nonprofitData.website,
            officeProfiles: null,
            transactions: null,
            agentVerificationStatus: element.agentData.agentStatus,
            agentVerificationId: element.agentData.agentVerificationId,
            address: element.nonprofitData.address,
            outreachData: element.outreachData
          } as unknown as ISearchResult);
        }
      });

    return (
      <section id="searchResults">
        {agentVerificationResults && agentVerificationResults.results !== undefined ? (
          agentVerificationResults.results.length > 0 ? (
            <FocusZone direction={FocusZoneDirection.vertical}>
              <div className="ms-Grid-row" id="summary-line">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <p>{resultList.length} records.</p>
                </div>
              </div>
              <RenderResultGrid
                searchResults={resultList}
                columnsHeaders={generatedColumns}
                setNonProfitId={this.props.setNonProfitId}
                setAdditionalNonprofitInfo={this.props.setAdditionalNonprofitInfo}
                setAgentVerificationId={this.props.setAgentVerificationId}
                resetState={this.props.resetState}
                resetSearchResultData={this.props.resetSearchResultData}
                newSearchResultsDataAdded={agentVerificationResults.newSearchResultsDataAdded}
                verifyAgentSuccess={this.props.verifyAgentSuccess}
              />
            </FocusZone>
          ) : (
            <p>There are no records found.</p>
          )
        ) : (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true} dismissButtonAriaLabel="Close">
            Issue fetching data. Please try again later.
          </MessageBar>
        )}
      </section>
    );
  }
}

export default AgentSummary;
