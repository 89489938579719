import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../state/index';
import { INonprofitState } from '../../state/nonprofit';
import '../details/Details.scss';

interface ISuccessActionProps {
  searchResults?: INonprofitState;
  ignoreHeader?: boolean;
}

export class SuccessAction extends React.Component<ISuccessActionProps> {
  public render() {
    return (
      <section id="success-action">
        {
          <MessageBar
            actions={
              <div>
                <MessageBarButton onClick={this.refreshPage}>Refresh</MessageBarButton>
              </div>
            }
            messageBarType={MessageBarType.success}
            isMultiline={false}
          >
            Success! Please refresh page.
          </MessageBar>
        }
      </section>
    );
  }

  private refreshPage = (): void => {
    window.location.reload();
  };
}

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  searchResults: nonprofit
});

export default connect(mapStateToProps)(SuccessAction);
