import { IStackTokens, Stack } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { AgentVerificationState } from '../../../state/agentVerification';
import { FeatureFlagsState } from '../../../state/feature-flags';
import { IApplicationState } from '../../../state/index';
import '../../details/Details.scss';
import StatusIcon from '../../util/StatusIcon';
import '../Admin.scss';
import AddComments from '../Comments/AddComments';
import AzureRegistrationLinking from '../azure-linking/AzureRegistrationLinking';
import SetFraud from '../fraud/SetFraud';
import Outreach from '../outreach/Outreach';
import Reprocess from '../reprocess/Reprocess';
import VerifyAgent from '../verify/VerifyAgent';
import './AdminActions.scss';

interface IPropsFromState {
  featureFlags: FeatureFlagsState;
  agentVerification: AgentVerificationState;
}

const stackTokens: IStackTokens = { childrenGap: 40 };
export class AdminActions extends React.Component<IPropsFromState> {
  public render() {
    return (
      <section id="admin-actions">
        {' '}
        <br />
        <div className="tile-content">
          <div className="detailsSectionHeader">
            <section id="status">
              <StatusIcon status="AdminAction" />
              <span className="boldText">Admin Actions</span>
            </section>
          </div>
          <div className="sectionDetails">
            <div id="admin-action-panel">
              <div className="ms-Grid">
                <p>
                  To set the nonprofit to fraud, click the <b>Set Fraud</b> button below.
                </p>
                <SetFraud />
              </div>
              <br />
              {this.props.agentVerification.agentVerificationInfo !== null &&
                this.props.agentVerification.agentVerificationInfo !== undefined && (
                  <>
                    <div className="ms-Grid">
                      <Stack horizontal tokens={stackTokens}>
                        <VerifyAgent></VerifyAgent>
                        <Outreach />
                      </Stack>
                    </div>
                    <br />
                  </>
                )}
              <div className="ms-Grid">
                <p>
                  To reprocess this transaction, click the <b>Reprocess</b> button below.
                </p>
                <Reprocess />
              </div>
              <br />
              <div className="ms-Grid">
                <p>To link an Azure Registration, click the button below.</p>
                <AzureRegistrationLinking />
              </div>
              <br />
              <div className="ms-Grid">
                <AddComments />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ flags, agentVerification }: IApplicationState): IPropsFromState => ({
  featureFlags: flags,
  agentVerification: agentVerification
});

export default connect(mapStateToProps)(AdminActions);
