import { Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../state/index';
import { INonprofitState, NonprofitDocumentEnum } from '../../../state/nonprofit';
import { RenderSortedDocuments } from '../../renderDocuments/RenderSortedDocuments';
import StatusIcon from '../../util/StatusIcon';
import '../Details.scss';
import { NonRenderedDocuments } from './NonRenderedDocuments';

interface IDocumentsProps {
  searchResults?: INonprofitState;
  ignoreHeader?: boolean;
  supportView?: boolean;
}

export class Documents extends React.Component<IDocumentsProps> {
  public render() {
    return (
      <section id="documents">
        {this.props.supportView ? (
          <NonRenderedDocuments searchResults={this.props.searchResults} supportView={true} />
        ) : this.props.searchResults === undefined ||
          this.props.searchResults.isNonprofitSearchResultsLoading === undefined ||
          this.props.searchResults.isNonprofitSearchResultsLoading === true ? (
          <Spinner id="searchSpinner" size={SpinnerSize.large} label="Loading Documents..." ariaLive="assertive" />
        ) : (
          <section id="detailsSection">
            {this.props.ignoreHeader ? (
              <div></div>
            ) : (
              <div className="detailsSectionHeader">
                <section id="status">
                  <StatusIcon status="Document" />
                  <span className="boldText">Documents</span>
                </section>
              </div>
            )}

            <div className="sectionDetails">
              {this.props.searchResults.isNonprofitSearchResultsLoading === false ? (
                <RenderSortedDocuments
                  documentEnum={NonprofitDocumentEnum.DOCUMENTS}
                  nonprofitSearchResults={this.props.searchResults.nonprofitSearchResults}
                />
              ) : (
                <p></p>
              )}
            </div>
          </section>
        )}
      </section>
    );
  }
}

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  searchResults: nonprofit
});

export default connect(mapStateToProps)(Documents);
