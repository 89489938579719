import { DefaultButton } from '@fluentui/react';
import * as React from 'react';
import { Form } from 'react-final-form';
import AzureRegistrationFields from './AzureRegistrationFields';

interface IAzureProps {
  initialValues: {
    nonprofitId: string;
  };
  onSubmit(event: any): void;
}

type IAzureRegistrationFormProps = IAzureProps;

class AzureRegistrationLinkForm extends React.Component<IAzureRegistrationFormProps> {
  public render() {
    const { initialValues, onSubmit } = this.props;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form
            id="azure-registration-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <AzureRegistrationFields />
            <div id="submitButton">
              <DefaultButton type="submit">Submit</DefaultButton>
            </div>
          </form>
        )}
      />
    );
  }
}

export default AzureRegistrationLinkForm;
