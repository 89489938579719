import { FocusZone, FocusZoneDirection } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ISearchState } from '../../../state/search';
import * as searchActions from '../../../state/search/actions';
import { setSelectedNonprofitIds } from '../../../state/search/actions';
import { RenderResultGrid } from './RenderResultGrid';
import './SearchSummary.scss';

interface ISearchProps {
  searchResults?: ISearchState;
  queryText: string;
}
interface IPropsFromDispatch {
  setNonprofitIdsToState?: typeof searchActions.setSelectedNonprofitIds;
}
export type SearchListProps = IPropsFromDispatch & ISearchProps;

export class SearchSummary extends React.Component<SearchListProps, {}> {
  public render() {
    const { searchResults } = this.props;

    return (
      <section id="searchResults">
        {searchResults && searchResults.results !== undefined && searchResults.results.length > 0 ? (
          <FocusZone direction={FocusZoneDirection.vertical}>
            <div className="ms-Grid-row" id="admin-summary-line">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <p>{searchResults.results.length} search results retrieved.</p>
                <RenderResultGrid
                  searchResults={searchResults.results}
                  setNonprofitIdsToState={this.props.setNonprofitIdsToState}
                />
              </div>
            </div>
          </FocusZone>
        ) : !searchResults || searchResults.isLoading === false ? (
          <p>There are no records that match your search criteria.</p>
        ) : (
          <p />
        )}
      </section>
    );
  }
}

const mapStateToDispatch = (dispatch: Dispatch) => ({
  setNonprofitIdsToState: (val: any) => dispatch(setSelectedNonprofitIds(val))
});

export default connect(null, mapStateToDispatch)(SearchSummary);
