import { action } from "typesafe-actions";
import { ISearchFormSubmission, ISearchResult, SearchActionTypes } from "./types";

export const searchRequest = (values: ISearchFormSubmission) => action(SearchActionTypes.SEARCH_REQUEST, values);
export const searchSuccess = (data: ISearchResult[]) => action(SearchActionTypes.SEARCH_SUCCESS, data);

export const setCurrentNonprofit = (data: ISearchResult) => action(SearchActionTypes.SET_CURRENT_NONPROFIT, data);
export const setSelectedNonprofitIds = (data: any) => action(SearchActionTypes.SET_SELECTED_NONPROFIT, data);

export const fetchError = (message: string) => action(SearchActionTypes.FETCH_ERROR, message);
