import { OutReachData } from '../agentVerification';

export const enum SearchActionTypes {
  SEARCH_REQUEST = '@@search/SEARCH_REQUEST',
  SEARCH_SUCCESS = '@@search/SEARCH_SUCCESS',
  SET_CURRENT_NONPROFIT = '@@search/SET_CURRENT_NONPROFIT',
  FETCH_ERROR = '@@search/FETCH_ERROR',
  SET_SELECTED_NONPROFIT = '@@search/SET_SELECTED_NONPROFIT'
}

export interface ISearchResult {
  id: string;
  agentEmail: string;
  effectiveDateTime: string;
  nonprofitAddressCity: string;
  nonprofitAddressCountryCode: string;
  nonprofitAddressStateRegion: string;
  nonprofitId: string;
  masterNonprofitId: string;
  nonprofitName: string;
  status: string;
  techSoupName: string;
  techSoupOrgId: string;
  phone: string;
  email: string;
  website: string;
  officeProfiles: IOfficeProfiles[];
  transactions: ITransactions[];
  agentVerificationStatus: string;
  agentVerificationId?: string;
  address?: string;
  outreachData?: OutReachData[];
}

export interface ISearchResultAccumulator {
  key: string;
  name: string;
  value: string | IOfficeProfiles[] | ITransactions[] | OutReachData[] | null | undefined;
  startIndex: number;
  level: number;
  count: number;
}

export interface IOfficeProfiles {
  domain: string;
  tagValue: string;
  tenantId: string;
}

export interface ITransactions {
  correlationId: string;
  transactionId: string;
}

export interface ISearchState {
  results: ISearchResult[] | undefined;
  currentNonProfit: ISearchResult | undefined;
  isLoading?: boolean;
  selectedNonProfitIds: any;
}

export interface ISearchFormSubmission {
  query: string;
}
