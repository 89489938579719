import { action } from 'typesafe-actions';
import { AuthActionTypes, IAuthorizationStatus, IAuthState } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
export const setAuthStatus = (state: IAuthState) => action(AuthActionTypes.SET_AUTH_STATUS, state)
export const initiateLogin = () => action(AuthActionTypes.INITIATE_LOGIN)
export const processLoginPromise = () => action(AuthActionTypes.PROCESS_LOGIN_CALLBACK)
export const initiateLogout = () => action(AuthActionTypes.INITIATE_LOGOUT)
export const setAuthorizationDetail = (value: IAuthorizationStatus) => action(AuthActionTypes.SET_AUTHORIZATION_DETAIL, value)
