import { Reducer } from 'redux';
import { saveToSessionStorage } from '../utils';
import { SharedActionTypes, SharedState } from './types';

const initialState: SharedState = {
  sessionId: undefined,
  countryDataList: undefined
};

const reducer: Reducer<SharedState> = (state = initialState, action) => {
  switch (action.type) {
    case SharedActionTypes.GETCOUNTRIES_REQUEST: {
      return { ...state };
    }
    case SharedActionTypes.GETCOUNTRIES_SUCCESS: {
      return { ...state, countryDataList: action.payload };
    }
    case SharedActionTypes.GETCOUNTRIES_ERROR: {
      return { ...state };
    }
    case SharedActionTypes.SET_SESSION_ID: {
      saveToSessionStorage('sessionId', action.payload);
      return { ...state, sessionId: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as sharedReducer };
