import { Fabric } from '@fluentui/react';
import { History } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import Wrapper from './pages/Wrapper';
import Routes from './common/routing/Routes';
import * as authActions from './state/auth/actions';
import { processLoginPromise } from './state/auth/actions';
import { fetchFlags } from './state/feature-flags';
import { getCountriesRequest, setSessionId } from './state/shared/actions';
import './App.scss';
import AuthAutoRenew from './common/authentication/AuthAutoRenew';
import Header from './components/header/Header';
import { uuidv4 } from './components/util/uuidv4';

interface IPropsFromDispatch {
  processLoginPromise: typeof authActions.processLoginPromise;
  fetchFlags: typeof fetchFlags;
  setSessionId: typeof setSessionId;
  getCountriesRequest: typeof getCountriesRequest;
}

// Any additional component props go here.
interface IOwnProps {
  history: History;
}

// Create an intersection type of the component props and our Redux props.
type AllProps = IPropsFromDispatch & IOwnProps;

class App extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
    this.props.setSessionId(uuidv4());
  }
  public componentDidMount() {
    this.props.fetchFlags();
    this.props.getCountriesRequest();
  }

  public render() {
    this.props.processLoginPromise();

    return (
      <Wrapper>
        <BrowserRouter>
          <Fabric>
            <div id="main">
              <Header />
              <main>
                <Routes />
              </main>
              <AuthAutoRenew />
            </div>
          </Fabric>
        </BrowserRouter>
      </Wrapper>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  processLoginPromise: () => dispatch(processLoginPromise()),
  fetchFlags: () => dispatch(fetchFlags()),
  setSessionId: (val: string) => dispatch(setSessionId(val)),
  getCountriesRequest: () => dispatch(getCountriesRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
