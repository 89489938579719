import React, { ChangeEvent } from "react";

interface IFileInputProps {
    onFileUpload: (file: File) => void
}

function FileInput(props: IFileInputProps) {
    const onFileChanged = (event: ChangeEvent<HTMLInputElement>): void => {
        const files = event.target.files;

        if (files !== null && files !== undefined && files.length > 0) {
            const targetFile = files[0];
            props.onFileUpload(targetFile);
        }
    }
    
    return (
        <>
            <input
                title="choose file"
                type="file"
                onChange={onFileChanged}
            />
        </>
    );
}

export default FileInput;