import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../state/index';
import '../../details/Details.scss';

interface IReprocessResultProps {
  reprocessAction: string;
  reprocessActionDescription: string;
}

export class ReprocessResult extends React.Component<IReprocessResultProps> {
  public render() {
    let messageBarType = MessageBarType.success;
    if (this.props.reprocessAction === 'Unknown') {
      messageBarType = MessageBarType.error;
    }
    if (this.props.reprocessAction === 'None') {
      messageBarType = MessageBarType.warning;
    }

    return (
      <section id="success-action">
        {
          <MessageBar
            actions={
              <div>
                <MessageBarButton onClick={this.refreshPage}>Refresh</MessageBarButton>
              </div>
            }
            messageBarType={messageBarType}
            isMultiline={true}
          >
            <div>
              <b>Action taken: </b>
              {this.props.reprocessAction}
            </div>
            <div>
              <b>Action description: </b>
              {this.props.reprocessActionDescription}
            </div>
            <div>
              <b>Please refresh page.</b>
            </div>
          </MessageBar>
        }
      </section>
    );
  }

  private refreshPage = (): void => {
    window.location.reload();
  };
}

const mapStateToProps = ({ admin }: IApplicationState) => ({
  reprocessAction: admin.reprocessState ? admin.reprocessState.reprocessAction : '',
  reprocessActionDescription: admin.reprocessState ? admin.reprocessState.reprocessActionDescription : ''
});

export default connect(mapStateToProps)(ReprocessResult);
