export function formatCityRegion(np: any) {
  if (!np) {
    return '';
  }

  let cityRegion = '';
  const city = (np.city || np.nonprofitAddressCity || '').trim();
  const region = (np.region || np.nonprofitAddressStateRegion || np.stateRegion || '').trim();
  let postalCode = (np.postalCode || '').trim();

  if (city && region) {
    cityRegion += city + ', ' + region;
  } else if (city) {
    cityRegion += city;
  } else if (region) {
    cityRegion += region;
  }
  if (postalCode && postalCode.replace('0', '')) {
    cityRegion += ' ' + postalCode;
  } else {
    postalCode = '';
  }

  return cityRegion.trim();
}

export function IsValidGUID(stringValue: string): boolean {
  const guidRegex = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/;
  if (stringValue.length > 0) {
    if (guidRegex.test(stringValue)) {
      return true;
    }
  }

  return false;
}
