import * as React from "react";
import "./ErrorPage.scss";

export class ErrorPage extends React.Component {
  public render() {

    return (
      <section id="error-page">
        <p>You are not authorized</p>
      </section>
    );
  }
}

export default ErrorPage;
