import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../state/index';
import { IAdminState, IReprocessFormSubmission } from '../../../state/admin';
import { reprocessRequest } from '../../../state/admin/actions';
import ReprocessResult from './ReprocessResult';

interface IPropsFromDispatch {
  reprocessRequest: (val: IReprocessFormSubmission) => void;
}

export interface IReprocessStateProps {
  hideDialog?: boolean;
  hideFraudDialog?: boolean;
  adminState: IAdminState;
  nonprofitStatus: string;
  nonprofitId: string;
}

export type IReprocessProps = IPropsFromDispatch & IReprocessStateProps;

class Reprocess extends React.Component<
  IReprocessProps,
  {
    hideDialog: boolean;
    comments: string;
    hideFraudDialog: boolean;
  }
> {
  constructor(props: IReprocessProps) {
    super(props);
    this.state = {
      hideDialog: true,
      comments: '',
      hideFraudDialog: true
    };
  }
  handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ comments: event.target.value });
  }
  public render() {
    return (
      <div>
        {this.props.adminState.reprocessLoading === true ? (
          <Spinner id="searchSpinner" size={SpinnerSize.medium} />
        ) : this.props.adminState.reprocessState &&
          this.props.adminState.reprocessState.isReprocessSuccessful === true ? (
          <section id="reprocess-success">
            <div className="tile-content">
              <ReprocessResult />
            </div>
          </section>
        ) : this.props.adminState.reprocessState &&
          this.props.adminState.reprocessState.isReprocessSuccessful === false ? (
          <MessageBar
            actions={
              <div>
                {this.props.nonprofitStatus !== 'Fraud' ? (
                  <PrimaryButton secondaryText="Reprocess" onClick={this.showDialog} text="Reprocess" />
                ) : (
                  <PrimaryButton secondaryText="Reprocess" onClick={this.showFraudDialog} text="Reprocess" />
                )}
              </div>
            }
            messageBarType={MessageBarType.error}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
          >
            {this.props.adminState.reprocessState.failureMessage}
          </MessageBar>
        ) : null}
        <section id="reprocess-transaction">
          {this.props.adminState.reprocessLoading === true ||
          (this.props.adminState.reprocessState &&
            this.props.adminState.reprocessState.isReprocessSuccessful !== undefined) ? null : this.props
              .nonprofitStatus !== 'Fraud' ? (
            <PrimaryButton secondaryText="Reprocess" onClick={this.showDialog} text="Reprocess" />
          ) : (
            <PrimaryButton secondaryText="Reprocess" onClick={this.showFraudDialog} text="Reprocess" />
          )}
          <Dialog
            hidden={this.state.hideFraudDialog}
            onDismiss={this.closeFraudDialog}
            dialogContentProps={{
              subText: 'This nonprofit is marked as Fraud. Do you really want to continue?',
              title: 'Reprocess fraud',
              type: DialogType.largeHeader
            }}
            modalProps={{
              containerClassName: 'ms-dialogMainOverride',
              isBlocking: false
            }}
          >
            <DialogFooter>
              <PrimaryButton onClick={this.showDialog} text="Yes" />
              <DefaultButton onClick={this.closeFraudDialog} text="No" />
            </DialogFooter>
          </Dialog>
          <Dialog
            hidden={this.state.hideDialog}
            onDismiss={this.closeDialog}
            dialogContentProps={{
              subText: 'You are about to reprocess the following nonprofit id. Do you really want to continue? *Check Trade screen status = Allowed prior reprocessing',
              title: 'Reprocess',
              type: DialogType.largeHeader
            }}
            modalProps={{
              containerClassName: 'ms-dialogMainOverride',
              isBlocking: false
            }}
          >
            <p>{this.props.nonprofitId}</p>
            <br></br>
            <textarea
              id="comments"
              onBlur={this.handleChange.bind(this)}
              placeholder="Comments"
              maxLength={500}
              style={{ width: '100%', height: '80px', outlineColor: 'rgb(0, 120, 212)' }}
            ></textarea>
            <DialogFooter>
              <PrimaryButton onClick={this.reprocessTransaction} text="Yes" />
              <DefaultButton onClick={this.closeDialog} text="No" />
            </DialogFooter>
          </Dialog>
        </section>
      </div>
    );
  }

  private showDialog = (): void => {
    this.setState({ hideFraudDialog: true, hideDialog: false });
  };

  private showFraudDialog = (): void => {
    this.setState({ hideFraudDialog: false });
  };

  private closeDialog = (): void => {
    this.setState({ hideFraudDialog: true, hideDialog: true });
  };

  private closeFraudDialog = (): void => {
    this.setState({ hideFraudDialog: true });
  };

  private reprocessTransaction = (): void => {
    const formvalues = {
      nonprofitId: this.props.nonprofitId,
      comments: this.state.comments
    } as IReprocessFormSubmission;
    this.props.reprocessRequest(formvalues);
    this.closeDialog();
  };
}

const mapStateToProps = ({ admin, nonprofit }: IApplicationState) => ({
  adminState: admin,
  nonprofitStatus: nonprofit.additionalNonprofitInfo ? nonprofit.additionalNonprofitInfo.status : '',
  nonprofitId: nonprofit.nonprofitId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reprocessRequest: (value: IReprocessFormSubmission) => dispatch(reprocessRequest(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Reprocess);
