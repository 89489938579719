import { appSettings } from '../common';
import { httpRequest } from '../common/utilities/HttpRequest';

export interface IMeoLocale {
  /**
   * The culture code for the locale. Ex: 'de-de'.
   */
  cultureCode: string;

  /**
   * The english name of the locale. Ex: 'German (Germany)'.
   */
  language: string;

  /**
   * The localized display name of the locale. Ex: 'Deutsch'.
   */
  displayName: string;

  /**
   * The optional fallback culture code for the locale. Ex: 'en-us'.
   *
   * This is only used in exceptional cases where the locale is not supported directly
   * by MEO Localization and is instead rendered in a similar locale.
   */
  fallbackCulture?: string;
}

export class MeoLocaleService {
  /**
   * The local cache of MEO Locales. This will be undefined until it's initialized, then it will either be
   * populated with the locales or will be an empty list if the request failed and they are not available.
   */
  private meoLocales?: IMeoLocale[] = undefined;

  /**
   * The default locale to use in the case of a null or undefined culture code.
   */
  public readonly DefaultLocale: string = 'en-us';

  /**
   * Lazy loads the meo locale collection from the CDN. The results are cached locally for future requests.
   * @returns The meo locales or undefined if they failed to load.
   */
  public async getMeoLocales(): Promise<IMeoLocale[] | undefined> {
    if (this.meoLocales === undefined) {
      await this.loadMeoLocalesFromCdn();
    }

    return this.meoLocales;
  }

  /**
   * Looks up the matching locale from a lazy loaded local cache.
   * @param cultureCode The culture code to match the locale by. Null or undefined values revert to using the DefaultLocale.
   * @returns The matching locale or undefined if they failed to load.
   */
  public async getMeoLocale(cultureCode: string | undefined): Promise<IMeoLocale | undefined> {
    // avoid accessing the private meoLocales object. Instead use the public method
    // so we lazy load the locales if this method is called before getMeoLocales
    const locales = await this.getMeoLocales() || [];

    // treat any null values as the default locale
    const lookupCode = cultureCode ?? this.DefaultLocale;

    return locales.find(x => x.cultureCode === lookupCode);
  }

  private async loadMeoLocalesFromCdn(): Promise<void> {
    const response = await httpRequest(`${appSettings.cdnBaseUrl}/email/meo-locales.json`);

    try {
      if (!response.ok) {
        throw new Error('Response did not indicate success');
      }

      this.meoLocales = await response.json();
    } catch(ex) {
      console.error('Failed to load meo-locales.json. Culture codes will be shown as a fallback.', { response, ex });

      // set the locales list to empty so we don't try to load them on every attempt to access them
      this.meoLocales = [];
    }
  }
}

const meoLocaleService = new MeoLocaleService();
export default meoLocaleService;
