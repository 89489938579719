import { action } from "typesafe-actions";
import { IOutreachState } from "../admin";
import { AgentData, AgentVerificationActionTypes, AgentVerificationResponse, IFilterFormSubmission } from "./types";

export const agentVerificationRequest = (data: string[]) => action(AgentVerificationActionTypes.AGENTVERIFICATION_REQUEST, data);
export const agentVerificationSuccess = (data: AgentVerificationResponse[]) => action(AgentVerificationActionTypes.AGENTVERIFICATION_SUCCESS, data);
export const agentVerificationError = () => action(AgentVerificationActionTypes.AGENTVERIFICATION_ERROR);
export const setAgentVerificationId = (data: AgentData) => action(AgentVerificationActionTypes.SET_AGENTVERIFICATION_INFO, data);
export const setOutreachStatus = (data: IOutreachState) => action(AgentVerificationActionTypes.SET_OUTREACH_STATUS, data);
export const setAgentVerificationStatus = (data: string) => action(AgentVerificationActionTypes.SET_VERIFYAGENT_STATUS, data);
export const resetSearchResultData = () => action(AgentVerificationActionTypes.RESET_SEARCH_RESULT_DATA);
export const resetAgentDataState = () => action(AgentVerificationActionTypes.RESET_AGENT_DATA);
export const agentFilterRequest = (values: IFilterFormSubmission) => action(AgentVerificationActionTypes.AGENTFILTER_REQUEST, values);
export const agentFilterSuccess = (data: AgentVerificationResponse[]) => action(AgentVerificationActionTypes.AGENTFILTER_SUCCESS, data);
export const fetchagentFilterError = () => action(AgentVerificationActionTypes.FETCH_AGENTFILTER_ERROR);