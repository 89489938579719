import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IAdditionalData } from '../../state/nonprofit';
import { setAdditionalNonprofitInfo, setNonProfitId } from '../../state/nonprofit/actions';

interface IUpdateProps {
  item: IAdditionalData;
}

interface IPropsFromDispatch {
  updateCurrentNonprofit: (val: IAdditionalData) => void;
}

export type IUpdateNonprofitDataProps = IPropsFromDispatch & IUpdateProps;

export class UpdateNonprofitData extends React.Component<IUpdateNonprofitDataProps> {
  constructor(props: IUpdateNonprofitDataProps) {
    super(props);
    this.props.updateCurrentNonprofit(this.props.item);
  }

  public render() {
    return null;
  }
}

const mapStateToDispatch = (dispatch: Dispatch) => ({
  setNonProfitId: (val: string) => dispatch(setNonProfitId(val)),
  updateCurrentNonprofit: (val: IAdditionalData) => dispatch(setAdditionalNonprofitInfo(val))
});

export default connect(null, mapStateToDispatch)(UpdateNonprofitData);
