import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../state/index';
import { INonprofitState, NonprofitDocumentEnum } from '../../../state/nonprofit';
import { RenderSortedDocuments } from '../../renderDocuments/RenderSortedDocuments';

interface IDocumentsProps {
  searchResults?: INonprofitState;
  supportView?: boolean;
}

export class NonRenderedDocuments extends React.Component<IDocumentsProps> {
  public render() {
    return this.props.searchResults === undefined ||
      this.props.searchResults.isNonprofitSearchResultsLoading === undefined ||
      this.props.searchResults.isNonprofitSearchResultsLoading === true ? null : this.props.searchResults
        .isNonprofitSearchResultsLoading === false ? (
      <RenderSortedDocuments
        supportView={true}
        documentEnum={NonprofitDocumentEnum.DOCUMENTS}
        nonprofitSearchResults={this.props.searchResults.nonprofitSearchResults}
      />
    ) : null;
  }
}

const mapStateToProps = ({ nonprofit }: IApplicationState) => ({
  searchResults: nonprofit
});

export default connect(mapStateToProps)(NonRenderedDocuments);
