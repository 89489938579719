import { all, apply, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { IApiResponse } from '../../common/model/ApiResponse';
import configService from '../../dataServices/ConfigService';
import { getCountriesError, getCountriesRequest, getCountriesSuccess } from './actions';
import { CountryData, SharedActionTypes } from './types';

export function* handleGetCountriesRequest(action: ActionType<typeof getCountriesRequest>) {
  try {
    const response: IApiResponse<CountryData[]> = yield apply(configService, configService.getCountryResults, []);
    const countriesList = [];
    if (response.status === 200) {
      if (response.value) {
        for (let i = 0; i < response.value.length; i++) {
          countriesList.push({
            key: response.value[i]['code'],
            text: response.value[i]['name']
          });
        }
      }
      yield put(getCountriesSuccess(countriesList));
    } else {
      yield put(getCountriesError());
    }
  } catch (err) {
    yield put(getCountriesError());
  }
}

function* watchGetCountriesRequest() {
  yield takeEvery(SharedActionTypes.GETCOUNTRIES_REQUEST, handleGetCountriesRequest);
}

function* sharedSaga() {
  yield all([fork(watchGetCountriesRequest)]);
}

export default sharedSaga;
