import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../state/index';
import { IAdminState, IVerifyAgentFormSubmission, verifyAgentRequest } from '../../../state/admin';
import './VerifyAgent.scss';

interface IPropsFromDispatch {
  verifyAgentRequest: (val: IVerifyAgentFormSubmission) => void;
}

export interface IVerifyAgentProps {
  adminState: IAdminState;
  nonprofitStatus: string;
  nonprofitId: string;
  agentVerificationId: string;
}

export type IVerifyAgentPopupProps = IVerifyAgentProps & IPropsFromDispatch;
export const VerifyAgent: React.FunctionComponent<IVerifyAgentPopupProps> = (props: IVerifyAgentPopupProps) => {
  const [hideDialog, setHideDialog] = React.useState(true);

  const showDialog = () => setHideDialog(false);
  const closeDialog = () => setHideDialog(true);
  let comments = '';
  const verifyAgent = () => {
    const formvalues = {
      agentVerificationId: props.agentVerificationId,
      nonprofitId: props.nonprofitId,
      comments: comments,
      processName: 'Support tool'
    } as IVerifyAgentFormSubmission;
    props.verifyAgentRequest(formvalues);
    closeDialog();
  };

  const cancelAction = () => closeDialog();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    comments = event.target.value;
  };
  const dialogBox = (
    <Dialog
      hidden={hideDialog}
      onDismiss={closeDialog}
      dialogContentProps={{
        title: 'Verify Agent',
        type: DialogType.largeHeader
      }}
      modalProps={{
        containerClassName: 'ms-dialogMainOverride verifyDialog',
        isBlocking: false
      }}
    >
      <DialogContent className="dialogContent">
        <p>Comment(Optional)</p>
        <textarea
          placeholder="Comments"
          onBlur={handleChange}
          maxLength={500}
          style={{ width: '100%', height: '80px', outlineColor: 'rgb(0, 120, 212)' }}
        ></textarea>
      </DialogContent>
      <DialogFooter>
        <PrimaryButton id="verifyAgentSubmit" onClick={verifyAgent} text="Submit" />
        <DefaultButton id="verifyAgentCancel" data-testid="verifyAgentCancel" onClick={cancelAction} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
  const primaryButton = (
    <PrimaryButton
      data-testid="verifyAgentButton"
      id="verifyAgentButton"
      secondaryText="Verify Agent"
      onClick={showDialog}
      text="Verify Agent"
    />
  );
  const verifyAgentSection = (
    <section id="verifyAgentPopup">
      {props.adminState.VerifyAgentLoading ? (
        <Spinner id="searchSpinner" size={SpinnerSize.medium} />
      ) : props.adminState.verifyAgentNonprofitState &&
        !props.adminState.VerifyAgentLoading &&
        props.adminState.verifyAgentNonprofitState.setVerifyAgentSuccess ? (
        <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
          Success!
        </MessageBar>
      ) : props.adminState.verifyAgentNonprofitState &&
        !props.adminState.verifyAgentNonprofitState.setVerifyAgentSuccess ? (
        <MessageBar
          actions={<div>{primaryButton}</div>}
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel="Close"
        >
          {props.adminState.verifyAgentNonprofitState.failureMessage} Please try again.
        </MessageBar>
      ) : (
        primaryButton
      )}
      {dialogBox}
    </section>
  );
  return verifyAgentSection;
};

const mapStateToProps = ({ admin, nonprofit, agentVerification }: IApplicationState) => ({
  adminState: admin,
  nonprofitStatus: nonprofit.additionalNonprofitInfo ? nonprofit.additionalNonprofitInfo.status : '',
  nonprofitId: nonprofit.nonprofitId,
  agentVerificationId: agentVerification.agentVerificationInfo
    ? agentVerification.agentVerificationInfo.agentVerificationId
    : ''
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  verifyAgentRequest: (val: IVerifyAgentFormSubmission) => dispatch(verifyAgentRequest(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAgent);
